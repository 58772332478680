import React, { useState, useMemo, useEffect } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import {
  Flex,
  Select,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Button,
  Box
} from '@chakra-ui/react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import Card from 'components/card/Card';
import { Link } from "react-router-dom";
import axios from "axios";
import { constants } from "constants/constants";

export default function ItemTable() {
  const [searchQuery, setSearchQuery] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const textColor = 'gray.900';
  const borderColor = 'gray.200';
  const columnsData = [
    {
      Header: "ID",
      accessor: "ID",
     
    },
    {
      Header: "Part No",
      accessor: "PartNo",
      
    },
    {
      Header: "Site",
      accessor: "Site",
      // textAlign: "right"
    },
    {
      Header: "Status",
      accessor: "Status",
    },
    {
      Header: "Date Created",
      accessor: "DateCreated",

    },
    {
      Header: "Date Updated",
      accessor: "DateUpdated",
    },
    {
      Header: "Retries",
      accessor: "Retries",
      // textAlign: "right"
    },
  ];

  const [tableData, setTableData] = useState([]);
  const fetchDataForPage = async (page, pageSize, searchQuery) => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/get-image-status`,
        {
          headers,
          withCredentials: true,
        }
      );

      const data = response.data.records;
      setTableData(data);
     
      // setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching image sync status data:", error);
      // Handle error if necessary
    }finally{
      setIsDataLoading(false);
    }
  };

   // Fetch data for the initial page

  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    // Access global filter state and functions
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

    
  useEffect(() => {
   
    // Reset the search when searchQuery is empty
    fetchDataForPage(pageSize);
  
}, [pageSize]);


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    // Update the global filter with the new search query
    setGlobalFilter(value);
  };

  const pageCount = page.length === 0 ? 0 : Math.ceil(data.length / pageSize);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      borderRadius="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            maxWidth="80px"
            variant="outline"
            color={textColor}
          >
            {[5, 10, 25, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>

          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Image Update Status
          </Text>

          <Input
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchQuery}
            maxWidth="200px"
          />
        </Flex>
        <Box overflowX="auto" maxWidth="100%">
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          mb="24px"
          style={{ overflowX: 'auto' }}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                    minWidth={column.width || "150px"} // Use column.width instead of column.minWidth
                    whiteSpace="normal"
                    wordBreak="break-word"
                    style={{ textAlign: column.textAlign }}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                      display={'contents'}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon as={FiArrowDown} />
                          ) : (
                            <Icon as={FiArrowUp} />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
              {page.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={rowIndex}>
                    {row.cells.map((cell, cellIndex) => {
                      let cellContent;

                        cellContent = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={cellIndex}
                          fontSize={{ sm: "14px" }}
                          maxH="30px !important"
                          py="8px"
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                          textAlign={cell.column.textAlign} 
                        >
                          {cellContent}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
        </Table>
            </Box>
        <Flex justify="space-between" align="center" px="25px">
          <Button
            onClick={() => gotoPage(0)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {'<<'}
          </Button>
          <Button
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {'<'}
          </Button>
          <Text color={textColor} fontSize="16px">
            Page {pageIndex + 1} of {pageCount}
          </Text>
          <Button
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {'>'}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {'>>'}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
