import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Heading, Text, useColorModeValue, VStack } from '@chakra-ui/react';

const NotFoundPage = () => {
  const history = useHistory();
  
  const handleGoBack = () => {
    history.goBack(); // Navigate back to the previous page
  };

  return (
    <VStack
      height="100vh"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      p={6}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Box textAlign="center">
        <Heading as="h1" size="2xl" mb={4}>
          404
        </Heading>
        <Text fontSize="lg" mb={4}>
          Oops! The page you're looking for doesn't exist.
        </Text>
        <Button colorScheme="teal" onClick={handleGoBack}>
          Go Back
        </Button>
      </Box>
    </VStack>
  );
};

export default NotFoundPage;
