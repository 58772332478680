import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Flex, Spinner } from '@chakra-ui/react';
import GenericLayout from "layouts/generic";
import axios from 'axios';
import { constants } from 'constants/constants';
import ForbiddenPage from 'views/auth/forbidden';

const PrivateRoute = ({ component: Component, permissions, isAuthenticated, ...rest }) => {
  const [isAuthenticatedCheck, setIsAuthenticatedCheck] = useState(false);
  const [userPermissionsCheck, setUserPermissionsCheck] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) {
      // Only run if the user is not authenticated
      const token = localStorage.getItem('accessToken');
      axios
        .post(`${constants.apiUrl}/validate-token`, { token }, { withCredentials: true })
        .then((response) => {
          if (response.status === 200) {
            const permissions = JSON.parse(response.data.permissions); // Assuming permissions are returned as an array of strings
            setUserPermissionsCheck(permissions.permissions);
            setIsAuthenticatedCheck(true);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            localStorage.removeItem('accessToken');
            setIsAuthenticatedCheck(false);
          }
          setLoading(false);
        });
    } else {
      setIsAuthenticatedCheck(true);
      setLoading(false);
    }
  }, [isAuthenticated]);

  if (loading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" thickness="4px" color="green.500" />
      </Flex>
    );
  }

  return (
    <Route
  {...rest}
  render={(props) => {
    if (!isAuthenticated && !isAuthenticatedCheck) {
      // If the user is not authenticated and the token validation hasn't confirmed authentication
      return <Redirect to="/auth/sign-in" />;
    }

    if (
      (isAuthenticated || isAuthenticatedCheck) &&
      (permissions.includes(props.location.pathname) || userPermissionsCheck.includes(props.location.pathname))
    ) {
      return (
        <GenericLayout>
          <Component {...props} />
        </GenericLayout>
      );
    } else {
      return <ForbiddenPage />;
    }
  }}
/>

  );
};

export default PrivateRoute;
