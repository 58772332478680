import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Flex,
  Input,
  Text,
  Button,
  List,
  Divider,
  ListItem,
  VStack,
  Textarea,
  SimpleGrid,
  StackDivider,// Import Divider to add separators between suggestions
  useToast,
} from "@chakra-ui/react";

import { constants } from "constants/constants";
import Card from "components/card/Card";
import CreatableSelect from "react-select/creatable";
import ComplexTable from "views/admin/updateSpecialsBatch/components/ComplexTable";
import Select from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function SpecialsBatchUpdate() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
 
  const [searchText, setSearchText] = useState("");
    
  const [specialsName, setSpecialsName] = useState("");
  const [startEndDate, setStartEndDate] = useState("");
  const [saleType, setSaleType] = useState("");
  const [customers, setCustomers] = useState("");
  const [specialsStatus, setSpecialsStatus] = useState("");
  const toast = useToast();
  const history = useHistory();
  const textColor = "gray.900";
  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }

    const paramsArray = queryString.split("&");
    const params = {};

    paramsArray.forEach((param) => {
      const [key, value] = param.split("=");
      params[key] = decodeURIComponent(value);
    });

    return params;
  };

  const windowUrl = window.location.href;
  const queryParams = getQueryParams(windowUrl);
  const id = queryParams["id"];

  const [suggestions, setSuggestions] = useState([]);
  const [productData, setProductData] = useState({
    partNo: "",
    description: "",
    barcode: "",
    binLocation: "",
    sellPrice: "",
    rrp: ""
  });

  const fetchDataForPage = async () => {
    try {
 
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/view-specials-batch`,
        {
          headers,
          withCredentials: true,
          params: {
           
            id: id
          },
        }
      );

  
         setSpecialsName(response.data.batchName);
         setStartEndDate(response.data.startEndDate);
         setSaleType(response.data.saleType);
         setCustomers(response.data.customers);
         setSpecialsStatus(response.data.batchStatus);

    } catch (error) {
      console.error("Error fetching customer data:", error);
      // Handle error if necessary
    }finally{
      
    }
  };

 
   // Fetch data for the initial page
  
  useEffect(() => {
      // Reset the search when searchQuery is empty
      fetchDataForPage();
    
  }, []);


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length >= 3) {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${constants.apiUrl}/fetch-product-details?details=all&searchText=${value}`, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          setSuggestions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);

          setSuggestions([]);
        });
    } else {
      setSuggestions([]);
    }
  };

  const insertionToDB = async (product) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      product.tempBatchId = id;
      const response = await axios.post(
        `${constants.apiUrl}/insert-specials-batch-update`,
        product,
        {
          headers,
          withCredentials: true,
        }
      );

    
    } catch (error) {

      toast({
        position: "bottom-right",
        title: "There was an error creating specials",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      
      console.error("Error fetching reports data:", error);
      // Handle error if necessary
      return [];
    }
  };

   const handleReset = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Call the API with the provided data
    axios
      .get(`${constants.apiUrl}/reset-davka-specials-products-batch`, {
        headers,
        withCredentials: true,
        params: {
          batchId: id,
        },
      })
      .then((response) => {
        // Set the isResetClicked flag to true
        window.location.reload();

      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
      });
  };

  const handleGoBack = () => {
    // Use history to navigate back
    // You can use react-router-dom's useHistory hook here
     history.goBack();
  };

  const handleSuggestionSelect = async (suggestion) => {
    setProductData(suggestion);
    setSuggestions([]);
    setSearchText(suggestion.partNo);
    // setResponseData({});

    await insertionToDB(suggestion);
    // Reload the page
    window.location.reload();
  };


  return (
    <Card  p={4} backgroundColor="white">
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        
      <Flex justify="space-between" mb={6}>
        <Box>
            <Text fontWeight="bold">Specials Name:</Text>
            <Text>{specialsName}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Specials Start/End Date</Text>
            <Text>{startEndDate}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Sale Type:</Text>
            <Text>{saleType}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Customers:</Text>
            <Text>{customers}</Text>
          </Box>
          <Button onClick={handleReset} colorScheme="teal" ml="2">
              Reset
            </Button>
            <Button onClick={handleGoBack} colorScheme="teal" ml="2">
              Go Back
            </Button>
        </Flex>
        <Box>
          <List spacing={2}>
            <ListItem>
              <Flex justify="center" alignItems="center">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleSearchChange}
                  mr={2}
                />
              </Flex>
            </ListItem>
          </List>
          <Box mb={6}>
            <List spacing={0}>
              {suggestions.length > 0 && (
                <VStack spacing={0} borderRadius={10} align="start" width={"92%"} border="1px solid #e2e8f0" divider={<StackDivider borderColor='gray.200' />}>
                  {suggestions.map((suggestion, index) => (
                    <Box key={suggestion.partNo}>
                      <ListItem
                        onClick={() => handleSuggestionSelect(suggestion)}
                        cursor="pointer"
                        // _hover={{ backgroundColor: "gray.200" }}
                        ml={2}
                        py={2} // Add padding to each suggestion
                      >
                        {suggestion.sku} - {" "} {suggestion.partNo} - {suggestion.productName} -{" "}
                        {suggestion.barcode}
                      </ListItem>
                      {index < suggestions.length - 1 && (
                        <StackDivider borderColor="gray.200" />
                      )}
                    </Box>
                  ))}
                </VStack>
              )}
            </List>
          </Box>
        </Box>
      </Box>
      <Box pt={{ base: "5px" }}>
            <SimpleGrid>
                <ComplexTable batchStatus={specialsStatus} />
            </SimpleGrid>
        </Box>
    </Card>
  );
}
