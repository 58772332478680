import React, { useState, useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import {
  Flex,
  Select,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Button,
  Box
} from "@chakra-ui/react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import Card from "components/card/Card";
import { Link } from "react-router-dom";
export default function ItemTable({ tableData, tableTitle }) {
  const [searchQuery, setSearchQuery] = useState("");
  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columnsData = [
    {
      Header: "Order Date",
      accessor: "InvoiceDate",
      Cell: ({ value }) => {
        const parts = value.split('/');
        const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`; // Reformat to MM/DD/YYYY
        return formattedDate;
      },
      sortType: (rowA, rowB, columnId) => {
        // Custom sort function using Date objects
        const datePartsA = rowA.original[columnId].split('/');
        const datePartsB = rowB.original[columnId].split('/');
        const dateA = new Date(`${datePartsA[1]}/${datePartsA[0]}/${datePartsA[2]}`);
        const dateB = new Date(`${datePartsB[1]}/${datePartsB[0]}/${datePartsB[2]}`);
        return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
      }
    },
    {
      Header: "Invoice No.",
      accessor: "JiwaInvoiceNo",
    },
    {
      Header: "Web Order No.",
      accessor: "WebOrderID",
      textAlign: "right"
    },
    {
      Header: "Ordered By",
      accessor: "StaffName",
    },
    {
      Header: "Account Code",
      accessor: "AccountCode",
      textAlign: "left"
    },
    {
      Header: "Customer Name",
      accessor: "CustomerName",
    },
    {
      Header: "Order Status",
      accessor: "Status",
      width: 180
    },
    {
      Header: "Invoice Total",
      accessor: "JiwaInvoiceTotal",
      textAlign: "right"
    },
    {
      Header: "Web Total",
      accessor: "WebInvoiceTotal",
      textAlign: "right"
    },
    {
      Header: "Difference",
      accessor: "difference",
      textAlign: "right"
    },
  ];

  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    // Access global filter state and functions
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 5,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    // Update the global filter with the new search query
    setGlobalFilter(value);
  };

  const pageCount = page.length === 0 ? 0 : Math.ceil(data.length / pageSize);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      borderRadius="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            maxWidth="80px"
            variant="outline"
            color={textColor}
          >
            {[5, 10, 25, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>

          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            {tableTitle}
          </Text>

          <Input
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchQuery}
            maxWidth="200px"
          />
        </Flex>
        <Box overflowX="auto" maxWidth="100%">
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            style={{ overflowX: "auto" }}
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                      minWidth={column.width || "150px"} // Use column.width instead of column.minWidth
                      whiteSpace="normal"
                      wordBreak="break-word"
                      style={{ textAlign: column.textAlign }}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={rowIndex}>
                    {row.cells.map((cell, cellIndex) => {
                      let cellContent;

                      if (columns[cellIndex].Header === "Difference") {
                        cellContent = (
                          <Text
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {row.original.WebOrderID ? (cell.value === "NA" ? cell.value : <span style={{ color: cell.value === "0.00" ? "green" : "red" }}>{parseFloat(cell.value).toFixed(2)}</span>) : "NA"}
                          </Text>
                        );
                      }else if (columns[cellIndex].Header === "Web Total") {
                        cellContent = (
                          <Text
                            style={{
                              textAlign: "right",
                            
                            }}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {row.original.WebOrderID ? parseFloat(cell.value).toFixed(2) : "NA"}
                          </Text>
                        );
                      }else if (columns[cellIndex].Header === "Invoice Total") {
                        cellContent = (
                          <Text
                            style={{
                              textAlign: "right",
                            
                            }}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {parseFloat(cell.value).toFixed(2)}
                          </Text>
                        );
                      } else if (columns[cellIndex].Header === "Invoice No.") {
                        cellContent = (
                          <Link
                          to={`/view-order?JiwaInvoiceNo=${cell.value}`}
                         
                        >
                            {cell.value}
                          </Link>
                        );
                      } else {
                        cellContent = (
                          <Text
                            color={textColor}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {cell.value}
                          </Text>
                        );
                      }

                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={cellIndex}
                          fontSize={{ sm: "14px" }}
                          maxH="30px !important"
                          py="8px"
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                          textAlign={cell.column.textAlign} // Set textAlign based on tableFormat
                        >
                          {cellContent}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <Flex justify="space-between" align="center" px="25px">
          <Button
            onClick={() => gotoPage(0)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {"<<"}
          </Button>
          <Button
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {"<"}
          </Button>
          <Text color={textColor} fontSize="16px">
            Page {pageIndex + 1} of {pageCount}
          </Text>
          <Button
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {">"}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {">>"}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
