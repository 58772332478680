import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Spinner,
  Flex,
  SimpleGrid,
  Input,
  Text,
  Button,
  List,
  ListItem,
  VStack,
  StackDivider, // Import Divider to add separators between suggestions

} from "@chakra-ui/react";
import ComplexTable from "./components/ItemTable";
import { constants } from "constants/constants";
import Card from "components/card/Card";

export default function CustomerOrder() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [customerAccountNo, setCustomerAccountNo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length >= 3) {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${constants.apiUrl}/fetch-customer-details?searchText=${value}`, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          setSuggestions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);

          setSuggestions([]);
        });
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setCustomerAccountNo(suggestion.accountNo);
    setCustomerName(suggestion.customerName);
    setCompanyName(suggestion.companyName);

    setSuggestions([]);
    setSearchText(suggestion.customerName);
  handleSearch(suggestion)
  };

  const handleSearch = (suggestion) => {
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");

    if (!token) {

      setIsLoading(false);
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${constants.apiUrl}/customer-orders`, {
        headers,
        withCredentials: true,
        params: {
          accountNo: suggestion.accountNo,
        },
      })
      .then((response) => {
        const responseData = response.data.records;
        setResponseData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);

        setIsLoading(false);
        setResponseData([]);
      });
  };

  return (
    <Card mt={4} p={4} backgroundColor="white">
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Box>
          <List spacing={2}>
            <ListItem>
              <Flex justify="center" alignItems="center">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleSearchChange}
                  mr={2}
                />
               
              </Flex>
            </ListItem>
          </List>
          <Box mb={6}>
            <List spacing={0}>
              {suggestions.length > 0 && (
                <VStack spacing={0} borderRadius={10} align="start" width={"92%"} border="1px solid #e2e8f0" divider={<StackDivider borderColor='gray.200' />}>
                  {suggestions.map((suggestion, index) => (
                    <Box key={suggestion.accountNo}>
                      <ListItem
                        onClick={() => handleSuggestionSelect(suggestion)}
                        cursor="pointer"
                        // _hover={{ backgroundColor: "gray.200" }}
                        ml={2}
                        py={2} // Add padding to each suggestion
                      >
                        {suggestion.accountNo} - {suggestion.customerName} -{" "}
                        {suggestion.companyName}
                      </ListItem>
                      {index < suggestions.length - 1 && (
                        <StackDivider borderColor="gray.200" />
                      )}
                    </Box>
                  ))}
                </VStack>
              )}
            </List>
          </Box>
        </Box>

        <Flex justify="space-between">
          <Box>
            <Text fontWeight="bold">Customer Account No:</Text>
            <Text>{customerAccountNo}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Customer Name:</Text>
            <Text>{customerName}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Company Name:</Text>
            <Text>{companyName}</Text>
          </Box>
        </Flex>

        {isLoading ? (
          <Flex mt={8} alignItems="center" justifyContent="center">
            <Spinner size="xl" color="teal.500" />
          </Flex>
        ) : (
          <SimpleGrid gap={4} mt={4}>

          {responseData && responseData.length > 0 && ( // Check if responseData is not empty
              <ComplexTable tableData={responseData} tableTitle="Orders" />
            )}
           
          </SimpleGrid>
        )}
      </Box>
    </Card>
  );
}
