import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Spinner,
  Flex,
  SimpleGrid,
  Input,
  Text,
  Button,
  List,
  Divider,
  ListItem,
  VStack,
  StackDivider, // Import Divider to add separators between suggestions

} from "@chakra-ui/react";
import ComplexTable from "./components/ItemTable";
import StockOnHandTable from "./components/StockOnHand";
import { constants } from "constants/constants";
import Card from "components/card/Card";

export default function InventoryEnquiry() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [productData, setProductData] = useState({
    partNo: "",
    description: "",
    barcode: "",
    binLocation: "",
    sellPrice: "",
    rrp: ""
  });


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length >= 3) {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${constants.apiUrl}/fetch-product-details?searchText=${value}`, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          setSuggestions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);

          setSuggestions([]);
        });
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setProductData(suggestion);
    setSuggestions([]);
    setSearchText(suggestion.partNo);
  
    
      handleSearch(suggestion);

  };
  

  const handleSearch = (suggestion) => {
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");

    if (!token) {

      setIsLoading(false);
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${constants.apiUrl}/inventory-enquiry`, {
        headers,
        withCredentials: true,
        params: {
          partNo: suggestion.partNo,
        },
      })
      .then((response) => {
        const responseData = response.data.records;
       
        setResponseData(responseData[0]);
        setProductData(responseData[0].productData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);

        setIsLoading(false);
        setResponseData([]);
      });
  };

  return (
    <Card mt={4} p={4} backgroundColor="white">
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Box>
          <List spacing={2}>
            <ListItem>
              <Flex justify="center" alignItems="center">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleSearchChange}
                  mr={2}
                />
             
              </Flex>
            </ListItem>
          </List>
          <Box mb={6}>
            <List spacing={0}>
              {suggestions.length > 0 && (
                <VStack spacing={0} borderRadius={10} align="start" width={"92%"} border="1px solid #e2e8f0" divider={<StackDivider borderColor='gray.200' />}>
                  {suggestions.map((suggestion, index) => (
                    <Box key={suggestion.partNo}>
                      <ListItem
                        onClick={() => handleSuggestionSelect(suggestion)}
                        cursor="pointer"
                        // _hover={{ backgroundColor: "gray.200" }}
                        ml={2}
                        py={2} // Add padding to each suggestion
                      >
                        {suggestion.partNo} - {suggestion.description} -{" "}
                        {suggestion.barcode}
                      </ListItem>
                      {index < suggestions.length - 1 && (
                        <StackDivider borderColor="gray.200" />
                      )}
                    </Box>
                  ))}
                </VStack>
              )}
            </List>
          </Box>
        </Box>

        <Flex justify="space-between">
          <Box>
            <Text fontWeight="bold">Part No:</Text>
            <Text>{productData.partNo}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Description:</Text>
            <Text>{productData.description}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">UPC/Barcode:</Text>
            <Text>{productData.barcode}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Bin Location:</Text>
            <Text>{productData.binLocation}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Sell Price:</Text>
            <Text>{productData.sellPrice}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">RRP:</Text>
            <Text>{productData.rrp}</Text>
          </Box>
        </Flex>

        {isLoading ? (
  <Flex mt={8} alignItems="center" justifyContent="center">
    <Spinner size="xl" color="teal.500" />
  </Flex>
) : responseData ? (
  <>
    <SimpleGrid gap={4} mt={4}>
      <StockOnHandTable
        tableData={responseData?.stockOnHand || []}
        tableTitle="Stock On Hand"
      />
    </SimpleGrid>
    <Divider my={4} />
    <SimpleGrid gap={4} mt={4}>
      <ComplexTable
        tableData={responseData?.purchaseOrders.purchaseOrders || []}
        tableTitle="Purchase Orders"
      />
    </SimpleGrid>
    <Divider my={4} />
    <SimpleGrid gap={4} mt={4}>
      <ComplexTable
        tableData={responseData?.salesOrders.salesOrders || []}
        tableTitle="Unprocessed Sales Orders"
      />
    </SimpleGrid>
  </>
) : null}

      </Box>
    </Card>
  );
}
