// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
import axios from 'axios';
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import { constants } from 'constants/constants';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { set } from "date-fns";
// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  const [permissions, setPermissions] = useState([]);
  
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    axios
      .post(`${constants.apiUrl}/validate-token`, { token }, { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          const permissions = JSON.parse(response.data.permissions); // Assuming permissions are returned as an array of strings
          setPermissions(permissions.permissions);
        }
   
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('accessToken');
   
        }

      });
  }, []);

  
  const filteredRoutes = routes.map(route => {
    if (route.hideInMenu) {
      return null; // Skip routes that should be hidden
    }
  
    if (route.category) {
      // Filter subitems that are permitted
      const permittedItems = route.items.filter(item => permissions.includes(item.path));
  
      if (permittedItems.length > 0) {
        // Include the category route if any subitems are permitted
        return {
          ...route,
          items: permittedItems, // Only include permitted items
        };
      }
  
      return null; // Exclude the category route if no subitems are permitted
    } else {
      // Include non-category routes if permitted
      return permissions.includes(route.path) ? route : null;
    }
  }).filter(route => route !== null); // Remove null values from the final array
  
  
  
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={filteredRoutes} />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
