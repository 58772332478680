import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Spinner,
  Flex,
  SimpleGrid,
} from "@chakra-ui/react";
import ComplexTable from "./components/ItemTable";
import OrderQueueTable from "./components/OrderQueueTable";
import { constants } from "constants/constants";

export default function OrderQueue() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");

    if (!token) {
      // Handle the case where the access token is missing
      setError("Access token not found.");
      setIsLoading(false);
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Call the API with the provided headers and credentials
    axios
      .get(`${constants.apiUrl}/order-queue`, {
        headers,
        withCredentials: true,
      })
      .then((response) => {
        const responseData = response.data.records;
        setResponseData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("An error occurred while fetching data.");
        setIsLoading(false);
        setResponseData(null);
      });
  }, []);

  if (error) {
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <p>Error: {error}</p>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {isLoading ? (
        <Flex mt={8} alignItems="center" justifyContent="center">
        <Spinner size="xl" color="green.500" />
      </Flex>
      ) : (
        <SimpleGrid gap="20px" mb="20px">
          <OrderQueueTable reportData={responseData?.arrayOne || []} tableTitle={'Sales Order Pending Actions'} />
          <ComplexTable reportData={responseData?.arrayTwo || []} tableTitle={'Picking Gun BackLog'} />
          <ComplexTable reportData={responseData?.arrayThree || []} tableTitle={'Order on Picking Gun'} />
          <ComplexTable reportData={responseData?.arrayFour || []} tableTitle={'Completed Orders'} />
        </SimpleGrid>
      )}
    </Box>
  );
}
