import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { constants } from "constants/constants";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const windowUrl = window.location.href;
  console.log(windowUrl);
  
  const getWindowUrl = () => {
    const url = window.location.href;
    const startIndex = url.indexOf("token=");
    if (startIndex === -1) {
      return null;
    }
    const tokenStartIndex = startIndex + 6; // Length of "token="
    const tokenEndIndex = url.indexOf("&", tokenStartIndex);
    const token = tokenEndIndex === -1 ? url.slice(tokenStartIndex) : url.slice(tokenStartIndex, tokenEndIndex);
    return token;
  };
  
  const token = getWindowUrl();
  
  const toast = useToast();

  const handleResetPassword = (e) => {
    e.preventDefault(); // Prevent form submission and page refresh

    if (password !== confirmPassword) {
      toast({
        position: "bottom-right",
        title: "Passwords do not match",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true); // Set loading state to true

   
    const userData = {
      password: password,
      token: token,
    };

    axios
      .post(`${constants.apiUrl}/reset-password`, userData)
      .then((response) => {
        if (response.status === 200) {
          console.log("Password reset successful");
          toast({
            position: "bottom-right",
            title: "Password reset successful",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          console.error("Error resetting password");
          toast({
            position: "bottom-right",
            title: "Error resetting password",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        let errorMessage = "Error resetting password";
        if (error.response && error.response.data) {
          errorMessage = error.response.data;
        }
        toast({
          position: "bottom-right",
          title: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsLoading(false); // Set loading state to false after API request completes
      });
  };

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");

  return (
    <DefaultAuth
      illustrationBackground={illustration}
      image={illustration}
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Reset Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your new password below.
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              New Password
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="password"
              placeholder="Enter new password"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Confirm Password
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="password"
              placeholder="Confirm new password"
              mb="24px"
              fontWeight="500"
              size="lg"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleResetPassword}
              isLoading={isLoading} // Loading state for the button
              loadingText="Resetting..."
              disabled={isLoading} // Disable button while loading
            >
              Reset Password
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorBrand} fontWeight="500" fontSize="14px">
              <NavLink to="/auth/sign-in">Back to Sign In</NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ResetPassword;
