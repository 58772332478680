import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  SimpleGrid,
  Button,
  Switch,
  Input,
} from "@chakra-ui/react";
import ReactSelect from "react-select";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { constants } from "constants/constants";

const fields = [
  { name: "Report ID", key: "reportId", editable: true, type: "text" },
  { name: "Report Name", key: "reportName", editable: true, type: "text" },
  { name: "Role Required", key: "roleRequired", editable: true, type: "select" },
  { name: "Web Enabled", key: "webEnabled", editable: true, type: "switch" },
  { name: "Email Enabled", key: "emailEnabled", editable: true, type: "switch" },
];

function ViewReport() {
  const [recordDetails, setRecordDetails] = useState({}); // State to hold record details
  const [editableFields, setEditableFields] = useState({
    reportId:"",
    reportName: "", // Initialize with an empty string
    roleRequired: [], // Initialize as an empty array
    webEnabled: "off", // Initialize with a default value ("off" or "on")
    emailEnabled: "off", // Initialize with a default value ("off" or "on")
  });

  const toast = useToast();

  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }

    const paramsArray = queryString.split("&");
    const params = {};

    paramsArray.forEach((param) => {
      const [key, value] = param.split("=");
      params[key] = decodeURIComponent(value);
    });

    return params;
  };

  const windowUrl = window.location.href;
  const queryParams = getQueryParams(windowUrl);
  const id = queryParams["id"];

  useEffect(() => {
    const fetchRecordDetails = async () => {
      try {
        const response = await axios.get(
          `${constants.apiUrl}/reportById`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            withCredentials: true,
            params: {
              id,
            },
          }
        );
        setRecordDetails(response.data); // Set the fetched record details
        setEditableFields({ // Initialize editableFields based on fetched data
          reportId: response.data.reportId || "",
          reportName: response.data.reportName || "", // Replace with the correct key
          roleRequired: (response.data.roleRequired || "").split(','), // Split by comma
          webEnabled: response.data.webEnabled || "off", // Replace with the correct key
          emailEnabled: response.data.emailEnabled || "off", // Replace with the correct key
        });
      } catch (error) {
        console.error("Error fetching record details:", error);
      }
    };

    fetchRecordDetails();
  }, [id]);

  const handleEdit = (key, value) => {
    setEditableFields((prevEditableFields) => ({
      ...prevEditableFields,
      [key]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      const updatedFields = {};

      fields.forEach((field) => {
        if (field.type === "switch") {
          updatedFields[field.key] =
            editableFields[field.key] === "on" ? "on" : "off";
        } else if (field.type === "text" || field.type === "number") {
          updatedFields[field.key] =
            editableFields[field.key] || recordDetails[field.key];
        } else if (field.type === "select") {
          // Handle multi-select (comma-delimited values)
          if (Array.isArray(editableFields[field.key])) {
            updatedFields[field.key] = editableFields[field.key].join(",");
          } else {
            updatedFields[field.key] =
              editableFields[field.key] || recordDetails[field.key];
          }
        }
      });

      const response = await axios.put(
        `${constants.apiUrl}/add-report`, // Update API endpoint
        {
          id,
          fields: updatedFields,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200 || response.status == 201) {
        toast({
          position: "bottom-right",
          title: "Report updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          position: "bottom-right",
          title: "Error updating Report",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating record:", error);
      toast({
        position: "bottom-right",
        title: "Error updating record",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box mt={24} p={4} borderWidth="1px" borderRadius="lg" shadow="md" backgroundColor={"white"}>
      <SimpleGrid columns={1} spacing={4}>
        {/* Editable fields */}
        {fields
          .filter((field) => field.editable)
          .map((field) => (
            <Box key={field.key}>
              <Text fontWeight="bold">{field.name}:</Text>
              {field.type === "switch" ? (
                <Switch
                  isChecked={editableFields[field.key] === "on" || (recordDetails[field.key] === "on" && editableFields[field.key] === undefined)}
                  onChange={() => {
                    const newEditableFields = { ...editableFields };
                    newEditableFields[field.key] = editableFields[field.key] === "on" ? "off" : "on";
                    setEditableFields(newEditableFields);
                  }}
                  size="lg"
                  ml={2}
                  transform="scale(1.2)"
                />
              ) : field.type === "select" ? (
                <ReactSelect
                isMulti
                options={[
                  { value: "GlobalAdmin", label: "Global Admin" },
                  { value: "SalesAdmin", label: "Sales Admin" },
                  { value: "SalesRep", label: "Sales Rep" },
                  { value: "SalesMarketing", label: "Sales Marketing" },
                  { value: "SpecialsAdmin", label: "Specials Admin" },
                  { value: "WarehouseSupport", label: "Warehouse Support" },
                  { value: "Owner", label: "Owner" },
                  { value: "Accounts", label: "Accounts" },
                ]}
                // Set the selected option based on editableFields.roleRequired or recordDetails.roleRequired
                value={(editableFields.roleRequired || []).map((value) => ({ value, label: value }))}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map((option) => option.value); // Store values
                  handleEdit("roleRequired", selectedValues);
                }}
              />
              

              ) : (
                <Input
                  type={field.type}
                  value={editableFields[field.key] !== undefined ? editableFields[field.key] : recordDetails[field.key]}
                  onChange={(e) => handleEdit(field.key, e.target.value)}
                />
              )}
            </Box>
          ))}
      </SimpleGrid>
      <Button mt={4} colorScheme="green" onClick={handleUpdate}>
        Update
      </Button>
    </Box>
  );
}

export default ViewReport;
