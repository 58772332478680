import React, { useState } from "react";
import {
    Box,
    Text,
    Input,
    Select,
    Button,
    Flex,
    SimpleGrid,
    Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { constants } from "constants/constants";
import ComplexTable from "./components/ComplexTable";

const PUAccessLogs = () => {
    const [formData, setFormData] = useState({
        RecordCount: 200, // Default value
    });

    const handleChange = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    // Call the API with the provided data
    axios
      .post(`${constants.apiUrl}/davka-pu-access-logs`, formData, {
        headers,
        withCredentials: true,
      })
      .then((response) => {
        const responseData = response.data.records;
        setResponseData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
        setIsLoading(false);
        setResponseData(null);
      });
  };
  

    return (
        <>

            <form onSubmit={handleFormSubmit}>
                <Flex direction="row" justifyContent="space-between" mt={20}>
                    <Box>
                        <Text fontWeight="bold">Record Count:</Text>
                        <Input
                            type="number"
                            value={formData.RecordCount}
                            onChange={(e) => handleChange("RecordCount", e.target.value)}
                        />
                    </Box>

                    <Box>
                        <Button type="submit" colorScheme="teal" mt={6}>
                            Check Logs
                        </Button>
                    </Box>
                </Flex>
            </form>


            {isLoading ? (
        <Flex mt={8} alignItems="center" justifyContent="center">
          <Spinner size="xl" color="green.500" />
        </Flex>
      ) : null}

      {responseData && (
        <Box mt={8}>
          <Box borderWidth="1px" borderRadius="md" p={4}>
          <ComplexTable reportData={responseData}/>
          </Box>
        </Box>
      )}
        </>
    );
};

export default PUAccessLogs;
