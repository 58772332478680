import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  useToast,
  Heading
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import axios from "axios";
import Select from "react-select";

export default function EditProfile() {
  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }

    const paramsArray = queryString.split("&");
    const params = {};

    paramsArray.forEach(param => {
      const [key, value] = param.split("=");
      params[key] = decodeURIComponent(value);
    });

    return params;
  };

  const windowUrl = window.location.href;
  const [generatedPassword, setGeneratedPassword] = useState("");

  const queryParams = getQueryParams(windowUrl);
  const userId = queryParams["id"];
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    // email: "",
    password: "",
  });
  const [warehouseData, setWarehouseData] = useState([]);
  const toast = useToast();

  // Fetch user data based on the provided userId
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Fetch user data
    axios
      .get(`${constants.apiUrl}/fetch-profile`, {
        headers,
        withCredentials: true,
      })
      .then((response) => {
        const { data } = response;


        // Update user data state with the roles array
        setUserData(data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        // Handle errors (e.g., show an error message)
      });

   

  }, [userId]);

  const handleUpdate = () => {
    if (!userData.firstName || !userData.lastName) {
      // Display a toast or any other error handling
      toast({
        position: "bottom-right",
        title: "Please fill all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return; // Stop the update process
    }
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    // Convert the role string with delimiters into an array
    const roleArray = Array.isArray(userData.role)
      ? userData.role.join(',') // Use ',' as the delimiter
      : userData.role;
    const warehouseArray = Array.isArray(userData.warehouse)
      ? userData.warehouse.join(',') // Use ',' as the delimiter
      : userData.warehouse;
    // Make an API call to update the user data
    axios
      .put(`${constants.apiUrl}/profile-update`, { ...userData, password: generatedPassword }, { headers, withCredentials: true })
      .then((response) => {
        // Handle success (e.g., show a success message)
        console.log("User data updated successfully");
        toast({
          position: "bottom-right",
          title: "User data updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error("Error updating user data:", error);
        // Handle errors (e.g., show an error message)
        toast({
          position: "bottom-right",
          title: "Error updating user data.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const handleRoleChange = (selectedOptions) => {
    const selectedRoles = selectedOptions.map((option) => option.value);
    setUserData({ ...userData, role: selectedRoles });
  };

  const handleWarehouseChange = (selectedOptions) => {
    const selectedWarehouses = selectedOptions.map((option) => option.value);
    setUserData({ ...userData, warehouse: selectedWarehouses });
  };

  const generateRandomPassword = () => {
    const randomPassword = Math.random().toString(36).substring(2, 10);
    setGeneratedPassword(randomPassword);
  };
  return (
    <Box
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      backgroundColor="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      
      <SimpleGrid columns={1} gap="20px" mb="20px" w="50%">
      <Heading>Update Profile</Heading>
        {/* First Name */}
        <FormControl>
          <FormLabel>First Name</FormLabel>
          <Input
            placeholder="First Name"
            value={userData.firstName}
            onChange={(e) =>
              setUserData({ ...userData, firstName: e.target.value })
            }
            required
          />
        </FormControl>

        {/* Last Name */}
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input
            placeholder="Last Name"
            value={userData.lastName}
            onChange={(e) =>
              setUserData({ ...userData, lastName: e.target.value })
            }
            required
          />
        </FormControl>

        {/* Email */}
        {/* <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Email"
            value={userData.email}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
            required
          />
        </FormControl> */}

        <FormControl>
                    <FormLabel>Change Password</FormLabel>
                    <Flex align="center">
                        <Input
                            type="text"
                            value={generatedPassword}
                            onChange={(e) => setGeneratedPassword(e.target.value)}
                            required
                            width="50%"
                        />
                        <Button
                            colorScheme="green"
                            variant="brand"
                            onClick={generateRandomPassword}
                            ml="2"
                        >
                            Generate New Password
                        </Button>
                    </Flex>
                </FormControl>

        {/* Submit Button */}
        <Button
          variant="brand"
          size="md"
          onClick={handleUpdate}
        >
          Update
        </Button>
      </SimpleGrid>
    </Box>
  );
}
