import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
} from "@chakra-ui/react";
import Select from "react-select"; // Import react-select
import { constants } from "constants/constants";
import ReportTable from "./components/ItemTable";

function GenerateReport() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null); // State for selected report
  const [reportOptions, setReportOptions] = useState([]); // State for report options
  const [fieldType, setFieldType] = useState(null);
  const [tableFormat, setTableFormat] = useState(null);
  const [reportName, setReportName] = useState(null); // State for report name

  // Function to fetch the list of available reports
  const fetchReports = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${constants.apiUrl}/fetch-reports`, {
        headers,
        withCredentials: true,
      });

      // Format the report data for react-select
      const formattedReports = response.data.map((report) => ({
        value: report.reportId,
        label: report.reportName,
      }));

      setReportOptions(formattedReports);
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  useEffect(() => {
    fetchReports(); // Fetch reports when the component mounts
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Call the API with the provided email and selected report
    axios
      .post(
        `${constants.apiUrl}/generate-report`,
        {
          email: email,
          reportId: selectedReport ? selectedReport.value : null,
        },
        {
          headers,
          withCredentials: true,
        }
      )
      .then((response) => {
        const responseData = response.data.records.Data;
        const TableFormat = response.data.records.TableFormat;
        setResponseData(responseData);
        setFieldType(response.data.records.fieldType);
        setTableFormat(TableFormat);
        setReportName(selectedReport ? selectedReport.label : null); // Set report name
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
        setIsLoading(false);
        setResponseData(null);
      });
  };

  return (
    <Box p={6}>
      <Heading mb={4} size="lg">
        Generate Report
      </Heading>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems="center" // Center items vertically
        justifyContent={{ base: "center", md: "flex-start" }} // Center items horizontally on small screens, align to the left on medium screens
        gridGap="10px"
      >
        <FormControl mt={4} textAlign={"end"}>
          <FormLabel textAlign={"end"} mb={0}>
            Enter Email
          </FormLabel>
        </FormControl>
        <FormControl mt={4}>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            variant="brand"
          />
        </FormControl>
        <FormControl mt={4}>
          <Select
            options={reportOptions}
            onChange={setSelectedReport}
            placeholder="Select a Report"
          />
        </FormControl>
        <FormControl mt={4}>
          <Button colorScheme="green" size="md" onClick={handleFormSubmit}>
            Generate
          </Button>
        </FormControl>
      </Flex>

      {isLoading ? (
        <Flex mt={8} alignItems="center" justifyContent="center">
          <Spinner size="xl" color="green.500" />
        </Flex>
      ) : null}

      {/* Render the ReportTable only if responseData, fieldType, and tableFormat are available */}
      {responseData && fieldType && tableFormat && (
        <Box mt={8}>
          <Box borderWidth="1px" borderRadius="md" p={4}>
            <ReportTable
              reportData={responseData}
              fieldType={fieldType}
              tableFormat={tableFormat}
              reportName={reportName} // Pass the reportName as prop
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default GenerateReport;
