import React, { useState, useMemo, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import {
  Flex,
  Select,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Button,
  Switch,
  Box,
  useToast
} from "@chakra-ui/react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import Card from "components/card/Card";
import axios from "axios";
import { constants } from "constants/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ItemTable() {
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [customerCriteria, setCustomerCriteria] = useState([]);
  const [specialsName, setSpecialsName] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [minQuantity, setMinQuantity] = useState("");
  const [debtorClassifications, setDebtorClassifications] = useState([]);
  const toast = useToast();
  const textColor = "gray.900";
  const borderColor = "gray.200";
  const history = useHistory();
  const columnsData = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "PART NO",
      accessor: "partno",
    },
    {
      Header: "LANDING COST",
      accessor: "landing_cost",
      textAlign: "right"
    },
    {
      Header: "REGULAR PRICE",
      accessor: "regular_price",
      textAlign: "right"
    },
    {
      Header: "SPECIAL PRICE ExGST",
      accessor: "sale_price", // Assuming the accessor contains unique identifiers
      textAlign: "right"
    },
    {
      Header: "PERCENT REDUCTION",
      accessor: "percent_reduction", // Assuming the accessor contains unique identifiers
      textAlign: "right"
    },
    {
      Header: "STOCK QUANTITY",
      accessor: "stockQuantity", // Assuming the accessor contains unique identifiers
      textAlign: "right"
    },
    {
      Header: "SELECTED",
      accessor: "offer",
    },
  ];

  const fetchDataForPage = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/fetch-specials-review`,
        {
          headers,
          withCredentials: true,
          params: {
            criteria: "davkabeauty",
          },
        }
      );

      return response.data.records;
    } catch (error) {
      console.error("Error fetching reports data:", error);
      // Handle error if necessary
      return [];
    }
  };

  const fetchDebtorForPage = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/debtor-classifications`,
        {
          headers,
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching debtor data:", error);
      // Handle error if necessary
      return [];
    }
  };

  const insertionToDB = async (product) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      product.criteria = "davkabeauty";
      const response = await axios.post(
        `${constants.apiUrl}/save-specials-review`,
        product,
        {
          headers,
          withCredentials: true,
        }
      );

    
    } catch (error) {

      toast({
        position: "bottom-right",
        title: "There was an error creating specials",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      
      console.error("Error fetching reports data:", error);
      // Handle error if necessary
      return [];
    }
  };

  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => tableData, [tableData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    // Access global filter state and functions
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageSize: 25,
      },
      autoResetPage: false,
      autoResetGlobalFilter: false,
      // autoResetSelectedRows: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDataForPage();
      setTableData(data);
      const debtorData = await fetchDebtorForPage();
      setDebtorClassifications(debtorData);
    };

    fetchData();
  }, []);

  const handleSwitchToggle = async (productId) => {
    setTableData((prevData) =>
      prevData.map((product) =>
        product.id === productId
          ? { ...product, offer: !product.offer }
          : product
      )
    );

    // Find the product with the updated selected state
    let updatedProduct = tableData.find(
      (product) => product.product_id === productId
    );
    updatedProduct.offer = !updatedProduct.offer;

    // Call insertionToDB with the updated product data
    await insertionToDB(updatedProduct);

    // Fetch updated data after insertion
    const updatedData = await fetchDataForPage();
    setTableData(updatedData);
  };

  const handleSpecialPriceEdit = (productId, newSpecialPrice) => {
    setTableData((prevData) =>
      prevData.map((product) =>
        product.product_id === productId
          ? {
              ...product,
              sale_price: newSpecialPrice,
              percent_reduction: (
                ((product.regular_price - newSpecialPrice) * 100) /
                product.regular_price
              ).toFixed(2),
            }
          : product
      )
    );

    // Find the product with the updated special price
    let updatedProduct = tableData.find(
      (product) => product.product_id === productId
    );
    updatedProduct.sale_price = newSpecialPrice;
    updatedProduct.percent_reduction = (
      ((updatedProduct.regular_price - newSpecialPrice) * 100) /
      updatedProduct.regular_price
    ).toFixed(2);
    // Call insertionToDB with the updated product data
    insertionToDB(updatedProduct);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    // Update the global filter with the new search query
    setGlobalFilter(value);
  };

  const formatDate = (date) => {
    // Helper function to format date as dd/mm/yyyy
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSpecialsCommit = async () => {
    // Call your API with the necessary data (specialsName, dateRange, minQuantity)
    try {
        // Check if specialsName or dateRange is missing
    if (!specialsName || !dateRange || dateRange.length !== 2 || !minQuantity) {
      toast({
          position: "bottom-right",
          title: "Please provide both Specials Name, Date Range & Minimum Quantity",
          status: "error",
          duration: 3000,
          isClosable: true,
      });
      return;
  }

  // Check if any sale_price in tableData is 0 or 0.00
  if (tableData.some(item => item.sale_price === '0' || item.sale_price === '0.00')) {
    toast({
        position: "bottom-right",
        title: "Special price cannot be 0",
        status: "error",
        duration: 3000,
        isClosable: true,
    });
    return;
}
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      // Format date range as dd/mm/yyyy
    const formattedDateRange = dateRange
    .map(date => formatDate(new Date(date)))
    .join(" - ");
      const response = await axios.post(
        `${constants.apiUrl}/commit-davka-specials`,
        {
          batch_name: specialsName,
          commit_daterange: formattedDateRange,
          customer_criteria: customerCriteria.map((value) => value.value).join(","),
          spl_qty: minQuantity,
          criteria: "davkabeauty",
        },
        {
          headers,
          withCredentials: true,
        }
      );

      if(response.status === 200){
        toast({
          position: "bottom-right",
          title: "Specials created successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push('/specials-history');
      }else{
        toast({
          position: "bottom-right",
          title: "There was an error creating specials",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      console.log("Commit Specials Response:", response.data);
    } catch (error) {
      console.error("Error committing specials:", error);
      toast({
        position: "bottom-right",
        title: "There was an error creating specials",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      // Handle error if necessary
    }
  };

  const handleReset = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Call the API with the provided data
    axios
      .get(`${constants.apiUrl}/reset-davka-specials-products`, {
        headers,
        withCredentials: true,
        params: {
          criteria: "davkabeauty",
        },
      })
      .then((response) => {
        // Set the isResetClicked flag to true
        window.location.reload();

      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
      });
  };

  const handleGoBack = () => {
    // Use history to navigate back
    // You can use react-router-dom's useHistory hook here
     history.goBack();
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  const handleDebtorChange = (selectedOptions) => {
    // Update the state with an array of selected values
    setCustomerCriteria(
      selectedOptions.map((option) => ({
        value: option.value,
        label: option.label,
      }))
    );
  };

  

  const pageCount =
    page.length === 0 ? 0 : Math.ceil(tableData.length / pageSize);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      borderRadius="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          {/* Specials Name */}
          <Box flex="1" mr="2">
            <ReactSelect
              value={
                customerCriteria.map((value) => ({
                  value: value.value,
                  label: value.label,
                })) || []
              }
              onChange={handleDebtorChange}
              variant="brand"
              isMulti
              options={debtorClassifications.map((client) => ({
                value: client.debtorClassificationID,
                label: client.debtorClassificationName,
              }))}
            />
          </Box>
          <Box flex="1" mr="2">
            <Input
              type="text"
              placeholder="Specials Name"
              value={specialsName}
              onChange={(e) => setSpecialsName(e.target.value)}
            />
          </Box>

          {/* Date Range Picker */}

          <Box flex="1" display="flex" justifyContent="space-between" mr={8}>
            <DatePicker
              selectsRange
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              onChange={handleDateChange}
              placeholderText="Select Date Range"
               dateFormat="dd/MM/yyyy"
              onKeyDown={(e) => {
                e.preventDefault();
             }}
              customInput={
                <Input
                  w="130%"
                  variant="filled"
                  size="md"
                  borderColor="gray.300"
                  
                />
              }
            />
          </Box>

          {/* Minimum Quantity */}
          <Box flex="1" mr={2}  ml={6}>
            <Input
             
              type="number"
              placeholder="Minimum Quantity"
              value={minQuantity}
              onChange={(e) => setMinQuantity(e.target.value)}
            />
          </Box>

          {/* Action Buttons */}
          <Box flex="1" display="flex" justifyContent="flex-end">
            <Button onClick={handleSpecialsCommit} colorScheme="teal" ml="2">
              Commit Specials
            </Button>
            <Button onClick={handleReset} colorScheme="teal" ml="2">
              Reset
            </Button>
            <Button onClick={handleGoBack} colorScheme="teal" ml="2">
              Go Back
            </Button>
          </Box>
        </Flex>

        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            maxWidth="80px"
            variant="outline"
            color={textColor}
          >
            {[5, 10, 25, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>

          <Input
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchQuery}
            maxWidth="200px"
          />
        </Flex>

        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                    style={{ textAlign: column.textAlign }}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon as={FiArrowDown} />
                          ) : (
                            <Icon as={FiArrowUp} />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell, index) => {
                    let cellContent = cell.value;
                    if (columns[index].Header === "SELECTED") {
                      cellContent = (
                        <Switch
                          colorScheme="teal"
                          size="md"
                          isChecked={cell.row.original.offer === "Yes"}
                          onChange={() =>
                            handleSwitchToggle(cell.row.original.product_id)
                          }
                        />
                      );
                    } else if (columns[index].Header === "SPECIAL PRICE ExGST") {
                      cellContent = (
                        <Input
                          type="number"
                          borderColor="1px solid gray"
                          value={cell.value}
                          onChange={(e) =>
                            handleSpecialPriceEdit(
                              cell.row.original.product_id,
                              e.target.value
                            )
                          }
                        />
                      );
                    }else if (columns[index].Header === "PERCENT REDUCTION") {
                      // Check if the cell value is "0.00"
                      const textColor = cell.value === "0.00" ? "red" : "inherit";
                    
                      cellContent = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else {
                      cellContent = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        maxH="30px !important"
                        py="8px"
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                        textAlign={cell.column.textAlign} // Set textAlign based on tableFormat
                      >
                        {cellContent}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Flex justify="space-between" align="center" px="25px">
          <Button
            onClick={() => gotoPage(0)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {"<<"}
          </Button>
          <Button
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {"<"}
          </Button>
          <Text color={textColor} fontSize="16px">
            Page {pageIndex + 1} of {pageCount}
          </Text>
          <Button
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {">"}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {">>"}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
