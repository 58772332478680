import React, { useState, useEffect } from "react";
import {
    Box,
    Text,
    Input,
    Select,
    Button,
    Flex,
    SimpleGrid,
    Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { constants } from "constants/constants";
import ComplexTable from "./components/ComplexTable";

const EngineLogs = () => {
    const [formData, setFormData] = useState({
        LogType: "CustomerSync",
        Type: "",
        Site: "",
        InventoryID: "",
        LoginID: "",
        OrderID: "",
        PartNo: "",
        DebtorID: "",
        ContactNameID: "",
        DebtorAccountNo: "",
        RecordCount: 200, // Default value
      });
    const handleChange = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const logTypeArr = {
        'CustomerSync': 'CustomerSync',
        'InventorySync': 'InventorySync',
        'GetProductData': 'GetProductData',
        'OrderSync': 'OrderSync',
        'PL-ckstores': 'PL-ckstores',
        'Specials': 'Specials',
        'Sync-Product': 'Sync-Product',
      };
      

      

    const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const handleFormSubmit = (e) => {
    // e.preventDefault(); // Prevent the default form submission behavior
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    // Call the API with the provided data
    axios
      .post(`${constants.apiUrl}/davka-engine-logs`, formData, {
        headers,
        withCredentials: true,
      })
      .then((response) => {
        const responseData = response.data.records;
        setResponseData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
        setIsLoading(false);
        setResponseData(null);
      });
  };
  
  useEffect(() =>{
    handleFormSubmit()
  },[formData]);

  return (
    <>
      <form onSubmit={handleFormSubmit}>
      <Flex direction="row" justifyContent="space-between" mt={20}>
          <Box mr={2}>
            <Text fontWeight="bold">Log Type:</Text>
            <Select
              value={formData.LogType}
              onChange={(e) => setFormData({ ...formData, LogType: e.target.value })}
            >
              {Object.entries(logTypeArr).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
          </Box>
  
          <Box mr={2}>
            <Text fontWeight="bold">Type:</Text>
            <Input
              type="text"
              value={formData.Type}
              onChange={(e) => setFormData({ ...formData, Type: e.target.value })}
            />
          </Box>
  
          <Box mr={2}>
            <Text fontWeight="bold">Site:</Text>
            <Input
              type="text"
              value={formData.Site}
              onChange={(e) => setFormData({ ...formData, Site: e.target.value })}
            />
          </Box>
  
          <Box mr={2}>
            <Text fontWeight="bold">Inventory ID:</Text>
            <Input
              type="text"
              value={formData.InventoryID}
              onChange={(e) => setFormData({ ...formData, InventoryID: e.target.value })}
            />
          </Box>
  
          <Box mr={2}>
            <Text fontWeight="bold">Login ID:</Text>
            <Input
              type="text"
              value={formData.LoginID}
              onChange={(e) => setFormData({ ...formData, LoginID: e.target.value })}
            />
          </Box>

          <Box mr={2}>
            <Text fontWeight="bold">Order ID:</Text>
            <Input
              type="text"
              value={formData.OrderID}
              onChange={(e) => setFormData({ ...formData, OrderID: e.target.value })}
            />
          </Box>
          </Flex>
          <Flex direction="row" justifyContent="space-between" mt={4}>
          
  
          <Box>
            <Text fontWeight="bold">Part No:</Text>
            <Input
              type="text"
              value={formData.PartNo}
              onChange={(e) => setFormData({ ...formData, PartNo: e.target.value })}
            />
          </Box>
  
          <Box>
            <Text fontWeight="bold">Debtor ID:</Text>
            <Input
              type="text"
              value={formData.DebtorID}
              onChange={(e) => setFormData({ ...formData, DebtorID: e.target.value })}
            />
          </Box>
  
          <Box>
            <Text fontWeight="bold">ContactName ID:</Text>
            <Input
              type="text"
              value={formData.ContactNameID}
              onChange={(e) => setFormData({ ...formData, ContactNameID: e.target.value })}
            />
          </Box>
  
          <Box>
            <Text fontWeight="bold">DebtorAccount No:</Text>
            <Input
              type="text"
              value={formData.DebtorAccountNo}
              onChange={(e) => setFormData({ ...formData, DebtorAccountNo: e.target.value })}
            />
          </Box>
  
          <Box>
            <Text fontWeight="bold">Record Count:</Text>
            <Input
              type="number"
              value={formData.RecordCount}
              onChange={(e) => setFormData({ ...formData, RecordCount: e.target.value })}
            />
          </Box>
  
          <Box>
            <Button type="submit" colorScheme="teal" mt={6}>
              Check Logs
            </Button>
          </Box>
        </Flex>
      </form>
  
      {isLoading ? (
        <Flex mt={8} alignItems="center" justifyContent="center">
          <Spinner size="xl" color="green.500" />
        </Flex>
      ) : null}
  
      {responseData && (
        <Box mt={8}>
          <Box borderWidth="1px" borderRadius="md" p={4}>
            <ComplexTable reportData={responseData} setFormData={setFormData} />
          </Box>
        </Box>
      )}
    </>
  );
  
  
};

export default EngineLogs;
