import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, Icon, useColorModeValue } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";

export function SidebarLinks({ routes }) {
  const location = useLocation();
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
  const activeIcon = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const [openCategories, setOpenCategories] = useState([]);

  useEffect(() => {
    // Find the active sub-item and category
    const findActiveCategory = () => {
      for (const route of routes) {
        if (route.items) {
          const activeItem = route.items.find(item => location.pathname.includes(item.path));
          if (activeItem) {
            return route.name;
          }
        }
      }
      return "";
    };

    const activeCategory = findActiveCategory();
    setOpenCategories(prev => {
      if (activeCategory && !prev.includes(activeCategory)) {
        return [...prev, activeCategory];
      }
      return prev;
    });
  }, [location.pathname, routes]);

  const handleCategoryClick = (categoryName) => {
    setOpenCategories(prev => {
      if (prev.includes(categoryName)) {
        return prev.filter(name => name !== categoryName);
      } else {
        return [...prev, categoryName];
      }
    });
  };

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        return (
          <Box key={index} _hover={{ cursor: "pointer" }}>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center" onClick={() => handleCategoryClick(route.name)}>
                <Box
                  color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                  me='18px'
                >
                  {route.icon}
                </Box>
                <Text
                  fontSize="md"
                  color={openCategories.includes(route.name) ? activeColor : textColor}
                  fontWeight={openCategories.includes(route.name) ? "bold" : "normal"}
                  ps={{ sm: "10px", xl: "16px" }}
                  py="18px"
                >
                  {route.name}
                </Text>
              </Flex>
              <Icon as={openCategories.includes(route.name) ? ChevronDownIcon : ChevronRightIcon} color={activeColor} onClick={() => handleCategoryClick(route.name)} />
            </Flex>
            {openCategories.includes(route.name) && createLinks(route.items)}
          </Box>
        );
      } else if (route.hideInMenu !== true) {
        return (
          <NavLink key={index} to={route.path}>
            {route.icon ? (
              <Box>
                <HStack
                  spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
                  py='5px'
                  ps='10px'>
                  <Flex w='100%' alignItems='center' justifyContent='center'>
                    <Box
                      color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                      me='18px'>
                      {route.icon}
                    </Box>
                    <Text
                      me='auto'
                      color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
                      fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}
                    >
                      {route.name}
                    </Text>
                  </Flex>
                  <Box
                    h='36px'
                    w='4px'
                    bg={activeRoute(route.path.toLowerCase()) ? brandColor : "transparent"}
                    borderRadius='5px'
                  />
                </HStack>
              </Box>
            ) : (
              <Box>
                <HStack
                  spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
                  py='5px'
                  ps='10px'>
                  <Text
                    me='auto'
                    color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
                    fontWeight={activeRoute(route.path.toLowerCase()) ? "bold" : "normal"}
                  >
                    {route.name}
                  </Text>
                  <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                </HStack>
              </Box>
            )}
          </NavLink>
        );
      }
    });
  };

  return createLinks(routes);
}

export default SidebarLinks;
