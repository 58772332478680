import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  Flex,
  SimpleGrid,
  Spinner,
  IconButton,
  Icon,
  useToast
} from "@chakra-ui/react";
import { FiShoppingCart, FiUpload } from "react-icons/fi";
import axios from "axios";
import { constants } from "constants/constants";
import ComplexTable from "./components/ComplexTable";
import Select from "react-select";
import { Link } from "react-router-dom";
const BeautyManiaSpecials = () => {
  const [formData, setFormData] = useState({
    category: "",
    brand: "",
    qtyFrom: "",
    qtyTo: "",
  });
  const toast = useToast();
  const [isResetClicked, setIsResetClicked] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const [brandsData, setBrandsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [toggledCount, setToggledCount] = useState(0);
  const [selectedSpecial, setSelectedSpecial] = useState(''); // Default to BeautyManiaPrice

  const options = [];
  for (let i = 15; i <= 100; i += 5) {
    options.push({ value: i, label: `${i}%` });
  }

  useEffect(() => {
    const storedSelectedSpecial = localStorage.getItem("selectedSpecial");
    if (storedSelectedSpecial) {
      setSelectedSpecial(storedSelectedSpecial);
    }
  }, []); // Load selected special from localStorage on component mount

  const fetchCategoriesBrands = () => {
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");

    if (!token) {

      setIsLoading(false);
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let criteria = '';
    if(selectedSpecial === 'BeautyManiaPrice'){
      criteria = 'beautymania';
    }else if(selectedSpecial === 'KoganPrice'){
      criteria = 'kogan';
    }else if(selectedSpecial === 'MyDealPrice'){
    criteria = 'mydeal';
    }
    axios
      .get(`${constants.apiUrl}/fetch-davka-categories-brands`, {
        headers,
        withCredentials: true,
        params: {
          criteria
        }
      })
      .then((response) => {
        const productData = response.data.productData;
        setCategoryData(response.data.categories);
        setBrandsData(response.data.brands);
        setProductData(productData);

        // Count the number of items with selected set to true
        const selectedCount = productData.filter((item) => item.selected).length;

        // Set the toggledCount state
        setToggledCount(response.data.cartCount);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);

        setIsLoading(false);

      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let criteria = '';
    if(selectedSpecial === 'BeautyManiaPrice'){
      criteria = 'beautymania';
    }else if(selectedSpecial === 'KoganPrice'){
      criteria = 'kogan';
    }else if(selectedSpecial === 'MyDealPrice'){
    criteria = 'mydeal';
    }
    // Call the API with the provided data
    axios
      .get(`${constants.apiUrl}/fetch-davka-specials-products`, {
        headers,
        withCredentials: true,
        params: {
          ...formData,
          criteria
        }
      })
      .then((response) => {
        const responseData = response.data.productData;
        setProductData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
        setIsLoading(false);

      });
  };

  const handleSelectSpecial = (option) => {
    setSelectedSpecial(option.value);
    localStorage.setItem("selectedSpecial", option.value); // Store selected special in localStorage
  };

  const handleResetSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    let criteria = '';
    if(selectedSpecial === 'BeautyManiaPrice'){
      criteria = 'beautymania';
    }else if(selectedSpecial === 'KoganPrice'){
      criteria = 'kogan';
    }else if(selectedSpecial === 'MyDealPrice'){
    criteria = 'mydeal';
    }
    
    // Call the API with the provided data
    axios
      .get(`${constants.apiUrl}/reset-davka-specials-products`, {
        headers,
        withCredentials: true,
        params: {
          criteria
        }
      })
      .then((response) => {
        // Set the isResetClicked flag to true
        setFormData({
          category: "",
          brand: "",
          qtyFrom: "",
          qtyTo: "",
        });
        setIsResetClicked(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
        setIsLoading(false);

      });
  };

  const handleUploadCSV = (event) => {
    const file = event.target.files[0];

    const token = localStorage.getItem("accessToken");

    const formData = new FormData();
    formData.append("file", file);

    let criteria = '';
    if(selectedSpecial === 'BeautyManiaPrice'){
      criteria = 'beautymania';
    }else if(selectedSpecial === 'KoganPrice'){
      criteria = 'kogan';
    }else if(selectedSpecial === 'MyDealPrice'){
    criteria = 'mydeal';
    }
    setIsUploading(true);
    
    axios
      .post(
        `${constants.apiUrl}/upload-specials-csv?criteria=${criteria}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setIsUploading(false);
       
        if (response.status === 200) {
          const invalidRows = response.data.invalidRows;
          const description = invalidRows.length
          ? `PartNo(s) not found: ${invalidRows.join(', ')}`
          : "";
        
          toast({
            position: "top",
            title: "CSV Uploaded Successfully",
            description: description,
            status: "success",
            duration: null,
            isClosable: true,
          });

        } else {
          toast({
            position: "top",
            title: "Error Uploading CSV, Please try again!",
            description: response.data.error,  // Use the error message from the backend
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setIsUploading(false);
        const errorMessage =
          error.response.data.message ||
          "Error Uploading CSV, Please try again!";
        toast({
          position: "top",
          title: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error uploading CSV:", error);
      })
      .finally(() => {
        setIsUploading(false);
       
        // window.location.reload();
        // fetchData();
        fetchCategoriesBrands();
      });
  };

  useEffect(() => {
    fetchCategoriesBrands();
  }, [selectedSpecial]);

  const [tableData, setTableData] = useState([]); // Move tableData state to the parent component

  const handleTableDataChange = (newTableData) => {
    setTableData(newTableData);
  };

  return (
    <>
      {/* {!selectedSpecial && ( */}
        <Flex direction="row" justifyContent="center" mt={16}>
           <Box width={"20%"} mr={6}>
            <Text fontWeight="bold">Select Specials For:</Text>
          <Select
          placeholder="Select Specials For"
            value={{ value: selectedSpecial, label: selectedSpecial }}
            onChange={handleSelectSpecial}
            options={[
              { value: "BeautyManiaPrice", label: "Beauty Mania" },
              { value: "KoganPrice", label: "Kogan" },
              { value: "MyDealPrice", label: "My Deal" }
            ]}
            maxWidth="200px"
            variant="outline"
          />
          </Box>
          <Box width={"15%"} >

<Button type="button" onClick={(e) => handleResetSubmit(e)} colorScheme="teal" mt={6} mr={2}>
  Reset
</Button>


</Box>
        </Flex>
      {/* )} */}

{selectedSpecial && (
      <form onSubmit={handleFormSubmit}>
        <Flex direction="row" justifyContent="space-between" mt={6}>
          <Box width={"20%"}>
            <Text fontWeight="bold">Categories:</Text>
            <Select
              value={formData.category ? { value: formData.category, label: formData.category } : null}
              onChange={(e) => handleChange("category", e.value)}

              options={[
                { value: "", label: "All" }, // Default option
                ...categoryData.map((category) => ({ value: category, label: category }))
              ]}


            />
          </Box>


          <Box width={"20%"}>
            <Text fontWeight="bold">Brands:</Text>
            <Select
              value={formData.brand ? { value: formData.brand, label: formData.brand } : null}
              onChange={(e) => handleChange("brand", e.value)}

              options={[
                { value: "", label: "All" }, // Default option
                ...brandsData.map((brand) => ({ value: brand, label: brand }))
              ]}

            />
          </Box>

          <Box width={"7%"}>
            <Text fontWeight="bold">Qty From:</Text>
            <Input
              type="text"
              value={formData.qtyFrom}
              onChange={(e) => handleChange("qtyFrom", e.target.value)}
            />
          </Box>

          <Box width={"5%"}>
            <Text fontWeight="bold">Qty To:</Text>
            <Input
              type="text"
              value={formData.qtyTo}
              onChange={(e) => handleChange("qtyTo", e.target.value)}
            />
          </Box>

          <Box width={"10%"}>
            <Link
              to={`/special-review?selectedSpecial=${selectedSpecial}`}

            >
              <Button
                type="button"
                colorScheme="teal"
                mt={6}
                borderRadius="16px" // Adjust the border radius values as needed
                display="flex"
                alignItems="center"

              >
                <Icon as={FiShoppingCart} />
                <Text ml={2}>{toggledCount}</Text>
              </Button>
            </Link>
            </Box>
                <Box width={"11%"}>
            <Button
              leftIcon={<FiUpload />}
              colorScheme="blue"
              isLoading={isUploading}
             mt={6}
            >
              <input
                type="file"
                accept=".csv"
                onChange={(event) => handleUploadCSV(event)}
                style={{
                  position: "absolute",
                  opacity: 0,
                  width: "130px"
                  // cursor: "pointer",
                }}
              />
              Upload CSV
            </Button>
          </Box>
        </Flex>
      </form>
      )}


      {isLoading ? (
        <Flex mt={8} alignItems="center" justifyContent="center">
          <Spinner size="xl" color="green.500" />
        </Flex>
      ) : null}

{selectedSpecial && (
      <Box mt={8}>
        <Box borderWidth="1px" borderRadius="md" p={4}>
          <ComplexTable
            formData={formData}
            setToggledCount={setToggledCount}
            toggledCount={toggledCount}
            isResetClicked={isResetClicked} // Pass the flag
            selectedSpecial={selectedSpecial} // Pass selectedSpecial to ComplexTable
          />
        </Box>
      </Box>
  )}
    </>
  );
};

export default BeautyManiaSpecials;
