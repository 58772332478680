import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Input,
  Button,
  Flex,
  SimpleGrid,
  Spinner,
  IconButton,
  Icon,
  useToast
} from "@chakra-ui/react";
import { FiShoppingCart, FiUpload } from "react-icons/fi";
import axios from "axios";
import { constants } from "constants/constants";
import ComplexTable from "./components/ComplexTable";
import Select from "react-select";
import { Link } from "react-router-dom";
const DavkaBeautySpecials = () => {
  const [formData, setFormData] = useState({
    category: "",
    brand: "",
    qtyFrom: "",
    qtyTo: "",
    minPercent: "",
  });
  const toast = useToast();
  const [isResetClicked, setIsResetClicked] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const [brandsData, setBrandsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [toggledCount, setToggledCount] = useState(0);
  const options = [];
  for (let i = 15; i <= 100; i += 5) {
    options.push({ value: i, label: `${i}%` });
  }

  const fetchCategoriesBrands = () => {
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");

    if (!token) {

      setIsLoading(false);
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${constants.apiUrl}/fetch-davka-categories-brands`, {
        headers,
        withCredentials: true,
        params: {
          criteria: 'davkabeauty'
        }
      })
      .then((response) => {
        const productData = response.data.productData;
        setCategoryData(response.data.categories);
        setBrandsData(response.data.brands);
        setProductData(productData);

        // Count the number of items with selected set to true
        const selectedCount = productData.filter((item) => item.selected).length;

        // Set the toggledCount state
        setToggledCount(response.data.cartCount);

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);

        setIsLoading(false);

      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Call the API with the provided data
    axios
      .get(`${constants.apiUrl}/fetch-davka-specials-products`, {
        headers,
        withCredentials: true,
        params: {
          ...formData,
          criteria: 'davkabeauty'
        }
      })
      .then((response) => {
        const responseData = response.data.productData;
        setProductData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
        setIsLoading(false);

      });
  };

  const handleResetSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // Call the API with the provided data
    axios
      .get(`${constants.apiUrl}/reset-davka-specials-products`, {
        headers,
        withCredentials: true,
        params: {
          criteria: 'davkabeauty'
        }
      })
      .then((response) => {
        // Set the isResetClicked flag to true
        setFormData({
          category: "",
          brand: "",
          qtyFrom: "",
          qtyTo: "",
          minPercent: "",
        });
        setIsResetClicked(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
        setIsLoading(false);

      });
  };

  const handleUploadCSV = (event) => {
    const file = event.target.files[0];

    const token = localStorage.getItem("accessToken");

    const formData = new FormData();
    formData.append("file", file);

    setIsUploading(true);
    
    axios
      .post(
        `${constants.apiUrl}/upload-specials-csv?criteria=davkabeauty`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        setIsUploading(false);
       
        if (response.status === 200) {
          const invalidRows = response.data.invalidRows;
          const description = invalidRows.length
          ? `PartNo(s) not found: ${invalidRows.join(', ')}`
          : "";
        
          toast({
            position: "top",
            title: "CSV Uploaded Successfully",
            description: description,
            status: "success",
            duration: null,
            isClosable: true,
          });

        } else {
          toast({
            position: "top",
            title: "Error Uploading CSV, Please try again!",
            description: response.data.error,  // Use the error message from the backend
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        setIsUploading(false);
        const errorMessage =
          error.response.data.message ||
          "Error Uploading CSV, Please try again!";
        toast({
          position: "top",
          title: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.error("Error uploading CSV:", error);
      })
      .finally(() => {
        setIsUploading(false);
       
        // window.location.reload();
        // fetchData();
        fetchCategoriesBrands();
      });
  };
  useEffect(() => {
    fetchCategoriesBrands();
  }, []);

  const [tableData, setTableData] = useState([]); // Move tableData state to the parent component

  const handleTableDataChange = (newTableData) => {
    setTableData(newTableData);
  };

  return (
    <>

      <form onSubmit={handleFormSubmit}>
        <Flex direction="row" justifyContent="space-between" mt={20}>
          <Box width={"20%"}>
            <Text fontWeight="bold">Categories:</Text>
            <Select
              value={formData.category ? { value: formData.category, label: formData.category } : null}
              onChange={(e) => handleChange("category", e.value)}

              options={[
                { value: "", label: "All" }, // Default option
                ...categoryData.map((category) => ({ value: category, label: category }))
              ]}

            />
          </Box>

          <Box width={"20%"}>
            <Text fontWeight="bold">Brands:</Text>
            <Select
              value={formData.brand ? { value: formData.brand, label: formData.brand } : null}
              onChange={(e) => handleChange("brand", e.value)}
              options={[
                { value: "", label: "All" }, // Default option
                ...brandsData.map((brand) => ({ value: brand, label: brand }))
              ]}
            />
          </Box>

          <Box width={"7%"}>
            <Text fontWeight="bold">Qty From:</Text>
            <Input
              type="text"
              value={formData.qtyFrom}
              onChange={(e) => handleChange("qtyFrom", e.target.value)}
            />
          </Box>

          <Box width={"5%"}>
            <Text fontWeight="bold">Qty To:</Text>
            <Input
              type="text"
              value={formData.qtyTo}
              onChange={(e) => handleChange("qtyTo", e.target.value)}
            />
          </Box>

          <Box width={"10%"}>
            <Text fontWeight="bold">Minimum %:</Text>
            <Select
              value={formData.minPercent ? { value: formData.minPercent, label: formData.minPercent } : null}
              onChange={(e) => handleChange("minPercent", e.value)}

              options={options}

            />
          </Box>

          <Box width={"15%"}>

            <Button type="button" onClick={(e) => handleResetSubmit(e)} colorScheme="teal" mt={6} mr={2}>
              Reset
            </Button>


          </Box>
          <Box width={"10%"}>
          <Link
          to={`/davkaBeauty-special-review`}
                         
                        >
            <Button
              type="button"
              colorScheme="teal"
              mt={6}
              borderRadius="16px" // Adjust the border radius values as needed
              display="flex"
              alignItems="center"

            >
              <Icon as={FiShoppingCart} />
              <Text ml={2}>{toggledCount}</Text>
            </Button>
            </Link>
                </Box>
                <Box width={"11%"}>
            <Button
              leftIcon={<FiUpload />}
              colorScheme="blue"
              isLoading={isUploading}
             mt={6}
            >
              <input
                type="file"
                accept=".csv"
                onChange={(event) => handleUploadCSV(event)}
                style={{
                  position: "absolute",
                  opacity: 0,
                  width: "130px"
                  // cursor: "pointer",
                }}
              />
              Upload CSV
            </Button>
          </Box>
        </Flex>
      </form>


      {isLoading ? (
        <Flex mt={8} alignItems="center" justifyContent="center">
          <Spinner size="xl" color="green.500" />
        </Flex>
      ) : null}


      <Box mt={8}>
        <Box borderWidth="1px" borderRadius="md" p={4}>
          <ComplexTable
            formData={formData}
            setToggledCount={setToggledCount}
            toggledCount={toggledCount}
            isResetClicked={isResetClicked} // Pass the flag
          />
        </Box>
      </Box>

    </>
  );
};

export default DavkaBeautySpecials;
