import React, { useState, useEffect } from 'react';
import routes from 'routes';
import {
  Flex,
  Box,
  Heading,
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  useToast
} from '@chakra-ui/react'; // Replace with your UI library components
import CreatableSelect from 'react-select/creatable'; // Import CreatableSelect from react-select/creatable
import axios from 'axios';
import { constants } from 'constants/constants';

const RolePermissionsPage = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState({});
  const toast = useToast();

  // Fetch roles and permissions on component mount
  useEffect(() => {
    const fetchRolesAndPermissions = async () => {
      try {
        const response = await axios.get(`${constants.apiUrl}/roles-permissions`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        });

        if (response.status === 200) {
          const { roles, permissions } = response.data;
          setRoles(roles);
          setPermissions(permissions);
        } else {
          throw new Error("Failed to fetch roles and permissions");
        }
      } catch (error) {
        console.error("Error fetching roles and permissions:", error);
        // Handle error (e.g., show error toast)
        toast({
          position: "bottom-right",
          title: "Error fetching roles and permissions",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchRolesAndPermissions();
  }, [toast]);

  // Function to handle role selection or creation
  const handleRoleChange = (selectedOption, { action }) => {
    if (action === 'create-option') {
      const newRole = { value: selectedOption.value, label: selectedOption.label };
      setRoles([...roles, newRole]);
      setSelectedRole(newRole);
    } else {
      setSelectedRole(selectedOption);
    }
    setSelectedPermissions({});
  };

  // Function to handle permission selection
  const handlePermissionChange = (event) => {
    const { value, checked } = event.target;
  
    // Update the selectedPermissions state
    setSelectedPermissions(prevPermissions => ({
      ...prevPermissions,
      [value]: checked,
    }));
  
    // Check if a role is selected
    if (selectedRole) {
      // Get the current role's permissions
      const rolePermissionsStr = permissions[selectedRole.value];
      const rolePermissions = rolePermissionsStr ? JSON.parse(rolePermissionsStr).permissions : [];
  
      // Remove or add permissions based on the checkbox state
      if (!checked) {
        // Remove the unchecked permission from rolePermissions
        const updatedRolePermissions = rolePermissions.filter(permission => permission !== value);
  
        // Update the permissions state to reflect the change
        setPermissions(prevPermissions => ({
          ...prevPermissions,
          [selectedRole.value]: JSON.stringify({ permissions: updatedRolePermissions })
        }));
      } else {
        // Optionally handle the case where a permission is checked (add it to the role's permissions)
        // If you want to support adding permissions dynamically, you can uncomment the following lines
  
        // if (!rolePermissions.includes(value)) {
        //   const updatedRolePermissions = [...rolePermissions, value];
        //   setPermissions(prevPermissions => ({
        //     ...prevPermissions,
        //     [selectedRole.value]: JSON.stringify({ permissions: updatedRolePermissions })
        //   }));
        // }
      }
    }
  };
  
  

  // Function to render checkboxes for selected role's permissions
  const renderRoutes = () => {
    if (!selectedRole) return null;
  
    const rolePermissionsStr = permissions[selectedRole.value];
    const rolePermissions = rolePermissionsStr ? JSON.parse(rolePermissionsStr).permissions : [];
  
    return routes.map((route) => {
      if (route.category) {
        return (
          <Box key={route.name}>
            <Heading as="h3" size="md" mb="2" mt="2">
              {route.name}
            </Heading>
            {route.items.map((item) => (
              <Checkbox
                ml={2}
                key={item.path}
                value={item.path}
                onChange={handlePermissionChange}
                isChecked={
                  selectedPermissions[item.path] !== undefined
                    ? selectedPermissions[item.path]
                    : rolePermissions.includes(item.path)
                }
              >
                {item.name}
              </Checkbox>
            ))}
          </Box>
        );
      }
      return null;
    });
  };
  
  


  // Generate options for the CreatableSelect dropdown
  const roleOptions = roles.map((role) => ({
    value: role.value,
    label: role.label,
  }));

  // Function to handle updating permissions
  const handleUpdatePermissions = async () => {
    try {
     
      if (!selectedRole) {
        throw new Error("Please select a role");
      }
  
      const selectedPaths = Object.entries(selectedPermissions)
        .filter(([_, checked]) => checked)
        .map(([path]) => path);
  
      const rolePermissionsStr = permissions[selectedRole.value];
      const rolePermissions = rolePermissionsStr ? JSON.parse(rolePermissionsStr).permissions : [];

  
      // Combine selectedPaths and rolePermissions into a single array
      const combinedPermissions = [...new Set([...selectedPaths, ...rolePermissions])];
  
      if (combinedPermissions.length === 0) {
        throw new Error("Please select at least one permission");
      }
  
      const response = await axios.post(
        `${constants.apiUrl}/add-update-role-permissions`,
        {
          selectedPermissions: { permissions: combinedPermissions },
          role: selectedRole.label
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 200) {
        toast({
          position: "bottom-right",
          title: "Permissions updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        throw new Error("Failed to update permissions");
      }
    } catch (error) {
      console.error("Error updating permissions:", error);
      toast({
        position: "bottom-right",
        title: "Error updating permissions",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  

  return (
    <Box
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      backgroundColor="white"
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Flex justify="center" align="center">
        <Box p="4" borderRadius="lg">
          <Heading as="h2" size="lg" mb="4">
            Manage Role Permissions
          </Heading>

          {/* Role Selection with CreatableSelect */}
          <FormControl mb="4">
            <FormLabel>Select or Add Role</FormLabel>
            <CreatableSelect
              value={selectedRole}
              onChange={handleRoleChange}
              options={roleOptions}
              isClearable
              isSearchable
              placeholder="Select or type to add role"
              onCreateOption={(inputValue) => {
                const newRole = { value: inputValue, label: inputValue };
                handleRoleChange(newRole, { action: 'create-option' });
              }}
            />
            <FormHelperText>Select an existing role or type to add a new role.</FormHelperText>
          </FormControl>

          {/* Display Permissions based on selected role */}
          {selectedRole && (
            <>
              <Heading as="h3" size="md" mt="4" mb="2">
                Permissions for {selectedRole.label}
              </Heading>
              {renderRoutes()}
            </>
          )}

          {/* Update Button */}
          {selectedRole && (
            <Button mt="4" colorScheme="blue" onClick={handleUpdatePermissions}>
              Update Permissions
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default RolePermissionsPage;
