import React, { useState, useMemo } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import {
  Flex,
  Select,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Button,
  Box
} from '@chakra-ui/react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import Card from 'components/card/Card';

export default function ItemTable({ reportData, setFormData }) {
  const [searchQuery, setSearchQuery] = useState('');
  const textColor = 'gray.900';
  const borderColor = 'gray.200';
  const columns = useMemo(() => {
    // Get the keys from the first item in the reportData array
    const keys = reportData.length > 0 ? Object.keys(reportData[0]) : [];
  
    // Create columns based on the keys
    return keys.map((key) => ({
      Header: key,
      accessor: key,
    }));
  }, [reportData]);
  
  const data = useMemo(() => reportData, [reportData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    // Access global filter state and functions
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    // Update the global filter with the new search query
    setGlobalFilter(value);
  };

   
  const handleLinkClick = (columnName, value) => {
    setFormData((prevData) => ({
      RecordCount: prevData.RecordCount, // Keep the RecordCount as it is
      [columnName]: value, // Update the specified columnName with the new value
      // Set all other fields to empty or null
      ...Object.keys(prevData).reduce((acc, key) => {
        if (key !== 'RecordCount' && key !== columnName) {
          acc[key] = ''; // or acc[key] = null; depending on your preference
        }
        return acc;
      }, {}),
    }));
    
  
   
  };

  const pageCount = page.length === 0 ? 0 : Math.ceil(data.length / pageSize);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      borderRadius="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            maxWidth="80px"
            variant="outline"
            color={textColor}
          >
            {[5, 10, 25, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>

          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Reports Data
          </Text>

          <Input
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchQuery}
            maxWidth="200px"
          />
        </Flex>
        <Box overflowX="auto" maxWidth="100%">
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          mb="24px"
          style={{ overflowX: 'auto' }}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                    minWidth={column.width || "150px"} // Use column.width instead of column.minWidth
                    whiteSpace="normal"
                    wordBreak="break-word"
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon as={FiArrowDown} />
                          ) : (
                            <Icon as={FiArrowUp} />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let cellContent = cell.value;

                    cellContent = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );

                    if (cell.column.id === "InventoryID" || cell.column.id === "PartNo") {
                      // Wrap cell content in a clickable link
                      cellContent = (
                        <Button
                          onClick={() => handleLinkClick(cell.column.id, cell.value)}
                          variant="link"
                          color="blue.500"
                          fontWeight="normal"
                          textAlign="left"
                          textDecoration="underline"
                          p="0"
                        >
                          {cell.value}
                        </Button>
                      );
                    }

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        maxH="30px !important"
                        py="8px"
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor="transparent"
                      >
                        {cellContent}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        </Box>
        <Flex justify="space-between" align="center" px="25px">
          <Button
            onClick={() => gotoPage(0)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {'<<'}
          </Button>
          <Button
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {'<'}
          </Button>
          <Text color={textColor} fontSize="16px">
            Page {pageIndex + 1} of {pageCount}
          </Text>
          <Button
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {'>'}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {'>>'}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
