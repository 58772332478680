import React, { useState, useMemo } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import {
  Flex,
  Select,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Button,
  Box
} from '@chakra-ui/react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import Card from 'components/card/Card';

export default function StockOnHandTable({ tableData,  tableTitle }) {
  const [searchQuery, setSearchQuery] = useState('');
  const textColor = 'gray.900';
  const borderColor = 'gray.200';
 
  const formatHeader = (key) => {
    // Split the camelCase key into words
    const words = key
      .replace(/([A-Z])/g, " $1")
      .trim()
      .split(" ");
    
    // Capitalize the first letter of each word and join them
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  let columnsData = [];
  if (tableData.length > 0) {
    columnsData = Object.keys(tableData[0]).map((key) => {
      return {
        Header: formatHeader(key),
        accessor: key,
        // You can customize other properties as needed
      };
    });
  }
  
  console.log(columnsData);
  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => tableData, [tableData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    // Access global filter state and functions
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 5,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    // Update the global filter with the new search query
    setGlobalFilter(value);
  };

  const pageCount = page.length === 0 ? 0 : Math.ceil(data.length / pageSize);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      borderRadius="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex px="25px" justify="space-between" mb="10px" align="center">
        <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            {tableTitle}
          </Text>

          <Input
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchQuery}
            maxWidth="200px"
          />
          
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            maxWidth="80px"
            variant="outline"
            color={textColor}
          >
            {[5, 10, 25, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>

         
        </Flex>
        <Box overflowX="auto" maxWidth="100%">
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          mb="24px"
          style={{ overflowX: 'auto' }}
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                    minWidth={column.width || "150px"} // Use column.width instead of column.minWidth
                    whiteSpace="normal"
                    wordBreak="break-word"
                    textAlign={"right"}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                      display={'contents'}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon as={FiArrowDown} />
                          ) : (
                            <Icon as={FiArrowUp} />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let cellContent = cell.value;

                    cellContent = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        maxH="30px !important"
                        py="8px"
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor="transparent"
                        textAlign={"right"}
                      >
                        {cellContent}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
            </Box>
      
      </Flex>
    </Card>
  );
}
