import React, { useState, useMemo, useEffect } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';
import {
  Flex,
  Select,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Button,
  Box,
  Switch
} from '@chakra-ui/react';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import Card from 'components/card/Card';
import axios from "axios";
import { constants } from "constants/constants";
export default function ItemTable({formData, toggleCount, setToggledCount, isResetClicked, selectedSpecial}) {
  const [tableData,setTableData] = useState([]);


  const [searchQuery, setSearchQuery] = useState('');
  // const [toggledCount, setToggledCount] = useState(0); // Count of toggled switches
  const textColor = 'gray.900';
  const borderColor = 'gray.200';
 


  const fetchDataForPage = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      let criteria = '';
      if(selectedSpecial === 'BeautyManiaPrice'){
        criteria = 'beautymania';
      }else if(selectedSpecial === 'KoganPrice'){
        criteria = 'kogan';
      }else if(selectedSpecial === 'MyDealPrice'){
      criteria = 'mydeal';
      }

      const response = await axios.get(
        `${constants.apiUrl}/fetch-davka-specials-products`,
        {
          headers,
          withCredentials: true,
          params: {
            ...formData,
            criteria
          }
        }
      );

      return response.data.productData;
    } catch (error) {
      console.error("Error fetching reports data:", error);
      // Handle error if necessary
      return [];
    }
  };

  const insertionToDB = async (product) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      if(selectedSpecial === 'BeautyManiaPrice'){
        product.criteria = 'beautymania';
      }else if(selectedSpecial === 'KoganPrice'){
        product.criteria = 'kogan';
      }else if(selectedSpecial === 'MyDealPrice'){
        product.criteria = 'mydeal';
      }
      product.sale_price = product.regular_price;
      const response = await axios.post(
        `${constants.apiUrl}/save-davka-specials-products`,product,
        {
          headers,
          withCredentials: true,
        }
      );

      
    } catch (error) {
      console.error("Error fetching reports data:", error);
      // Handle error if necessary
      return [];
    }
  };


// Function to dynamically generate columns based on the selected special
const generateColumns = (selectedSpecial) => {
  let columnsData = [
    {
      Header: "NAME",
      accessor: "productName",
    },
    {
      Header: "PART NO",
      accessor: "partNo",
    },
    {
      Header: "LANDING COST",
      accessor: "LandingCost",
      textAlign: "right"
    },
    {
      Header: selectedSpecial.toUpperCase(), // Use selected special in the header
      accessor: selectedSpecial, // Use selected special as accessor
      textAlign: "right"
    },
    {
      Header: "STOCK QUANTITY",
      accessor: "stockQuantity",
      textAlign: "right"
    },
    {
      Header: "SELECTED",
      accessor: "selected",
    },
  ];

  return columnsData;
};

// Memoize columns based on selected special
const columns = useMemo(() => generateColumns(selectedSpecial), [selectedSpecial]);
  const data = useMemo(() => tableData, [tableData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    // Access global filter state and functions
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageSize: 25,
      },
      autoResetPage: false, 
      autoResetGlobalFilter: false,
      // autoResetSelectedRows: false,
    
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
 
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDataForPage();
      setTableData(data);
    };

    fetchData();
  }, [formData]);

  useEffect(async () => {
    if (isResetClicked) {
      const data = await fetchDataForPage();
      setTableData(data);
      setToggledCount(0);
    }
  }, [isResetClicked]);

  const handleSwitchToggle = (productId) => {
    setTableData((prevData) =>
      prevData.map((product) =>
        product.id === productId
          ? { ...product, selected: !product.selected }
          : product
      )
    );
  
    // Use the updated state directly in the callback
    setToggledCount((prevCount) =>
      tableData.find((product) => product.id === productId)?.selected
        ? prevCount - 1
        : prevCount + 1
    );
  
    // Find the product with the updated selected state

    let updatedProduct = tableData.find((product) => product.id === productId);
    updatedProduct.selected = !updatedProduct.selected;
    // Call insertionToDB with the updated product data
    insertionToDB(updatedProduct);
  };
  
  
  const handleSpecialPriceEdit = (productId, newSpecialPrice) => {
    setTableData((prevData) =>
      prevData.map((product) =>
        product.id === productId
          ? { ...product, specialPrice: newSpecialPrice }
          : product
      )
    );
  
    // Find the product with the updated special price
    let updatedProduct = tableData.find((product) => product.id === productId);
    updatedProduct.specialPrice = newSpecialPrice;
    // Call insertionToDB with the updated product data
    insertionToDB(updatedProduct);
  };
  
  
  

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    // Update the global filter with the new search query
    setGlobalFilter(value);
  };

  const pageCount = page.length === 0 ? 0 : Math.ceil(tableData.length / pageSize);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      borderRadius="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
    >
      <Flex
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            maxWidth="80px"
            variant="outline"
            color={textColor}
          >
            {[5, 10, 25, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>


          <Input
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchQuery}
            maxWidth="200px"
          />
        </Flex>
    
        <Table
          {...getTableProps()}
          variant="simple"
          color="gray.500"
          mb="24px"
      
        >
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                    style={{ textAlign: column.textAlign }}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: '10px', lg: '12px' }}
                      color="gray.400"
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon as={FiArrowDown} />
                          ) : (
                            <Icon as={FiArrowUp} />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell, index) => {
                    let cellContent = cell.value;
                    if (columns[index].Header === "SELECTED") {
                      cellContent = (
                        <Switch
                          colorScheme="teal"
                          size="md"
                          isChecked={cell.row.original.selected}
                          onChange={() => handleSwitchToggle(cell.row.original.id)}
                        />
                      );
                    }else if (columns[index].Header === "LANDING COST") {
                      // Check if the cell value is "0.00"
                      const textColor = cell.value === "0.00" ? "red" : "inherit";
                    
                      cellContent = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (columns[index].Header === "SPECIAL PRICE") {
                      cellContent = (
                        <Input
                          type="number"
                          borderColor="1px solid gray"
                          value={cell.value}
                          onChange={(e) =>
                            handleSpecialPriceEdit(cell.row.original.id, e.target.value)
                          }
                        />
                      );
                    }else{
                      cellContent = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                 

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: '14px' }}
                        maxH="30px !important"
                        py="8px"
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        borderColor="transparent"
                        textAlign={cell.column.textAlign} // Set textAlign based on tableFormat
                      >
                        {cellContent}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      
        <Flex justify="space-between" align="center" px="25px">
          <Button
            onClick={() => gotoPage(0)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {'<<'}
          </Button>
          <Button
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {'<'}
          </Button>
          <Text color={textColor} fontSize="16px">
            Page {pageIndex + 1} of {pageCount}
          </Text>
          <Button
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {'>'}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {'>>'}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
