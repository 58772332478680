import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Spinner,
  Flex,
  Input,
  Text,
  Button,
  List,
  ListItem,
  VStack,
  StackDivider,
  IconButton,
  useToast,
  SimpleGrid
} from "@chakra-ui/react";
import { constants } from "constants/constants";
import Card from "components/card/Card";
import { CloseIcon } from "@chakra-ui/icons"; // Import CloseIcon for remove button
import { Link } from "react-router-dom";
import ItemTable from "views/admin/syncImages/components/ItemTable";

export default function SyncImages() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [productData, setProductData] = useState({
    partNo: "",
    description: "",
    barcode: "",
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const toast = useToast();
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length >= 3) {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${constants.apiUrl}/fetch-product-details?searchText=${value}`, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          setSuggestions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);
          setSuggestions([]);
        });
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    if (selectedItems.length < 10) {
      setSelectedItems((prevItems) => [
        ...prevItems,
        {
          partNo: suggestion.partNo,
          description: suggestion.description,
          barcode: suggestion.barcode,
        },
      ]);
      setSearchText(""); // Clear search input
      setSuggestions([]); // Clear suggestions
    } else {
      alert("You can only select up to 10 items.");
    }
  };

  const handleRemoveItem = (partNo) => {
    setSelectedItems((prevItems) => prevItems.filter(item => item.partNo !== partNo));
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");

    if (!token) {
      setIsLoading(false);
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    const imagesArr = selectedItems.map(item => item.partNo);

    axios
      .post(`${constants.apiUrl}/send-image-partno`, { imagesArr }, {
        headers,
        withCredentials: true,
      })
      .then((response) => {
        const responseData = response.data.records;

        if (response.status === 200) {
          toast({
            position: "bottom-right",
            title: "PartNo Images updated successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
        setResponseData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        setIsLoading(false);
        setResponseData([]);

        toast({
          position: "bottom-right",
          title: "Error updating PartNo Images",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }) .finally(() => {
        // Refresh the page after the request is complete
        setIsLoading(false);
        window.location.reload(); // Refresh the page
      });
  };

  return (
    <Card mt={4} p={4} backgroundColor="white">
       
      <Box pt={{ base: "130px", md: "40px", xl: "40px" }}>
    
        <Box>
          <List spacing={2}>
            <ListItem>
              <Flex justify="center" alignItems="center">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleSearchChange}
                  mr={2}
                />
              </Flex>
            </ListItem>
          </List>
          <Box mb={6}>
            <List spacing={0}>
              {suggestions.length > 0 && (
                <VStack
                  spacing={0}
                  borderRadius={10}
                  align="start"
                  width={"92%"}
                  border="1px solid #e2e8f0"
                  divider={<StackDivider borderColor="gray.200" />}
                >
                  {suggestions.map((suggestion, index) => (
                    <Box key={suggestion.partNo}>
                      <ListItem
                        onClick={() => handleSuggestionSelect(suggestion)}
                        cursor="pointer"
                        ml={2}
                        py={2}
                      >
                        {suggestion.partNo} - {suggestion.description} -{" "}
                        {suggestion.barcode}
                      </ListItem>
                      {index < suggestions.length - 1 && (
                        <StackDivider borderColor="gray.200" />
                      )}
                    </Box>
                  ))}
                </VStack>
              )}
            </List>
          </Box>
        </Box>

        <Box mb={6}>
          <Text fontWeight="bold">Selected PartNo's:</Text>
          <List spacing={2}>
            {selectedItems.map((item) => (
              <ListItem key={item.partNo} display="flex" alignItems="center">
                <Text mr={4}>{item.partNo} - {item.description} - {item.barcode}</Text>
                <IconButton
                  icon={<CloseIcon />}
                  aria-label="Remove item"
                  size="sm"
                  onClick={() => handleRemoveItem(item.partNo)}
                />
              </ListItem>
            ))}
          </List>
        </Box>

        <Flex justify="space-between">
          <Button
            onClick={handleSubmit}
            colorScheme="blue"
            isLoading={isLoading}
          >
            Submit
          </Button>
        </Flex>

        <SimpleGrid gap='20px' mb='20px'>
                <ItemTable />
            </SimpleGrid>
      </Box>
    </Card>
  );
}
