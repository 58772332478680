import React, { useState } from "react";
import { Table, Td, Th, Tbody, Thead, Tr, Text, Box, Button, VStack, HStack, useToast } from "@chakra-ui/react";
import axios from "axios";
import { constants } from "constants/constants";

const SummaryTable = ({ orderLines, customerData, dateOrdered, dateExpected }) => {

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const calculateSummary = () => {
    const totalItems = orderLines.reduce((total, line) => total + line.qty, 0);
    const orderTotalExGST = orderLines.reduce(
      (total, line) => total + parseFloat(line.sellPrice * line.qty),
      0
    );
    const totalTax = orderLines.reduce(
      (total, line) => total + parseFloat(line.totalTax),
      0
    );
    const orderTotalIncGST = orderTotalExGST + totalTax;

    return {
      totalItems,
      orderTotalExGST,
      totalTax,
      orderTotalIncGST,
    };
  };

  const handleCancelOrder = () => {
    // You can simply reload the page to cancel the order.
    window.location.reload();
  };

  let freight = parseFloat(customerData.postageCost);
  let taxRate = parseFloat(0.1); // 10% tax rate

  let freightTax = parseFloat(freight * taxRate).toFixed(2);
  let freightTotal = parseFloat(freight + parseFloat(freightTax)).toFixed(2);

  const summaryData = calculateSummary();

  if (summaryData.orderTotalExGST.toFixed(2) >= customerData.freePostageAbove) {
    freight = parseFloat(0.00);
    taxRate = parseFloat(0.00); // 10% tax rate

    freightTax = parseFloat(0.00);
    freightTotal = parseFloat(0.00);
  }

  // Calculate the Invoice Total
  const invoiceTotalExGST =
    parseFloat(summaryData.orderTotalExGST) + parseFloat(freight);
  const invoiceTotalTax =
    parseFloat(summaryData.totalTax) + parseFloat(freightTax);
  const invoiceTotalIncGST = invoiceTotalExGST + invoiceTotalTax;

  const handleAddOrder = () => {
    setIsLoading(true);
    // Create an order object containing all the required data, including orderLines, customerData, and Freight.


  // Convert Date objects to ISO string format
  // const isoDateOrdered = new Date(dateOrdered);
  // const isoDateExpected = new Date(dateExpected);

  // Create an order object containing all the required data
  const order = {
    orderLines: orderLines,
    customer: customerData,
    freight: freight.toFixed(2),
    freightTax,
    freightTotal,
    dateOrdered, // Use ISO string format for dates
    dateExpected,
  };

    const token = localStorage.getItem("accessToken");

    // Set the headers with the Authorization token
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(`${constants.apiUrl}/create-order-wc`, order, {
        headers: headers, // Pass headers in the request config
        withCredentials: true,
      })
      .then((response) => {
        toast({
          position: 'bottom-right',
          title: 'Order Created.',
          description: "Order is Created",
          status: "success",
          duration: 3000, // Duration in milliseconds
          isClosable: true, // Whether the toast can be closed by the user
        });
        console.log(response.data);
      })
      .catch((error) => {
        toast({
          position: 'bottom-right',
          title: "There was an error while creating Order",
          status: "error",
          duration: 3000, // Duration in milliseconds
          isClosable: true, // Whether the toast can be closed by the user
        });
        console.error("Error creating Order:", error);
      }).finally(() => {
        setIsLoading(false);
        window.location.reload();
      });
  };


  return (
    <VStack align="stretch">
      <Box>
        <Table variant="simple" float={"right"} w={"50%"}>
          <Thead>
            <Tr>
              <Th textAlign={"right"}># Items</Th>
              <Th textAlign={"right"}>Order Total Ex. GST</Th>
              <Th textAlign={"right"}>GST</Th>
              <Th textAlign={"right"}>Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td textAlign={"right"} fontWeight="bold">{summaryData.totalItems}</Td>
              <Td textAlign={"right"} fontWeight="bold">{summaryData.orderTotalExGST.toFixed(2)}</Td>
              <Td textAlign={"right"} fontWeight="bold">{summaryData.totalTax.toFixed(2)}</Td>
              <Td  textAlign={"right"} fontWeight="bold">{summaryData.orderTotalIncGST.toFixed(2)}</Td>
            </Tr>
            <Tr>
              <Td textAlign={"right"} fontWeight="bold">Freight</Td>
              <Td textAlign={"right"} fontWeight="bold">{freight.toFixed(2)}</Td>
              <Td textAlign={"right"} fontWeight="bold">{freightTax}</Td>
              <Td textAlign={"right"} fontWeight="bold">{freightTotal}</Td>
            </Tr>
            <Tr>
              <Td textAlign={"right"} fontWeight="bold">Invoice Total</Td>
              <Td textAlign={"right"} fontWeight="bold">{invoiceTotalExGST.toFixed(2)}</Td>
              <Td textAlign={"right"} fontWeight="bold">{invoiceTotalTax.toFixed(2)}</Td>
              <Td textAlign={"right"} fontWeight="bold">{invoiceTotalIncGST.toFixed(2)}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <HStack spacing={4} mt={4} justifyContent="flex-end">
        <Button colorScheme="teal" onClick={handleAddOrder} isLoading={isLoading}>
          Add Order
        </Button>
        <Button colorScheme="red" onClick={handleCancelOrder}>
          Cancel Order
        </Button>
      </HStack>
    </VStack>
  );
};

export default SummaryTable;
