import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Flex,
  Input,
  Text,
  Button,
  List,
  Divider,
  ListItem,
  VStack,
  Textarea,
  Switch,
  StackDivider,// Import Divider to add separators between suggestions
  useToast,
} from "@chakra-ui/react";

import { constants } from "constants/constants";
import Card from "components/card/Card";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
export default function ProductUpdate() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [tagsData, setTagsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [existingCategories, SetExistingCategoriesData] = useState([]);
  const [existingTags, SetExistingTagsData] = useState([]);
  const [existingCountryOrigin, SetExistingCountryOrigin] = useState("");
  const [countryOrigin, SetCountryOrigin] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isAddCategoryOpen, setAddCategoryOpen] = useState(false);
    
  const toast = useToast();
  const [suggestions, setSuggestions] = useState([]);
  const [productData, setProductData] = useState({
    partNo: "",
    description: "",
    barcode: "",
    binLocation: "",
    sellPrice: "",
    rrp: ""
  });

  const CategoryDropdown = ({ categories, onSelectCategory }) => {
    console.log(categories);


    const handleSelectChange = (selectedOptions) => {
      SetExistingCategoriesData(selectedOptions);
      onSelectCategory(selectedOptions);
    };

    return (
      <Box>
        <Text fontWeight="bold">Product Categories:</Text>
        <Select
          value={categories}
          onChange={handleSelectChange}
          options={categories.map((category) => ({ value: category, label: category }))}
          isMulti
          isClearable
        />
      </Box>
    );
  };

  const AddCategoryDropdown = ({ parentCategories, onSelectParentCategory, onAddCategories }) => {
    const [selectedParentCategory, setSelectedParentCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedSubSubCategory, setSelectedSubSubCategory] = useState(null);
    const [newCategory, setNewCategory] = useState("");

    const handleParentCategoryChange = (selectedOption) => {
      setSelectedParentCategory(selectedOption);
      onSelectParentCategory(selectedOption);
      setSelectedSubCategory(null);
      setSelectedSubSubCategory(null); // Clear sub-sub category when parent category changes
    };

    const handleSubCategoryChange = (selectedOption) => {
      setSelectedSubCategory(selectedOption);
      setSelectedSubSubCategory(null);
    };

    const handleSubSubCategoryChange = (selectedOption) => {
      setSelectedSubSubCategory(selectedOption);
    };

    const handleCreateCategory = (inputValue) => {
      setNewCategory(inputValue);
      setSelectedParentCategory({ value: inputValue, label: inputValue });
      onSelectParentCategory(null);
      setSelectedSubCategory(null);
      setSelectedSubSubCategory(null);
    };

    const handleSubCreateCategory = (inputValue) => {
      setNewCategory(inputValue);
      setSelectedSubCategory({ value: inputValue, label: inputValue });
      setSelectedSubSubCategory(null);
    };

    const handleSubSubCreateCategory = (inputValue) => {
      setNewCategory(inputValue);
      setSelectedSubSubCategory({ value: inputValue, label: inputValue });
    };

    const handleAddCategories = () => {
      // Combine selected categories into a string
      const combinedCategories = [selectedParentCategory, selectedSubCategory, selectedSubSubCategory]
        .filter((category) => category)
        .map((category) => category.label)
        .join('>');

      // Add the combined string to existingCategories
      onAddCategories(combinedCategories);
    };

    const subCategories = parentCategories.filter(
      (category) => category.parentCategoryId === selectedParentCategory?.value
    );

    const subSubCategories = parentCategories.filter(
      (category) => category.parentCategoryId === selectedSubCategory?.value
    );

    return (
      <Flex justify={"space-between"} marginRight={4}>

        <CreatableSelect
          value={selectedParentCategory}
          onChange={handleParentCategoryChange}
          options={parentCategories
            .filter((category) => category.parentCategoryId === 0)
            .map((category) => ({
              value: category.categoryId,
              label: category.category,
            }))}
          isClearable
          onCreateOption={handleCreateCategory}

        />
        <Box width="4" /> {/* Adjust the width to set the space */}
        <CreatableSelect
          placeholder="Sub Category"
          value={selectedSubCategory}
          onChange={handleSubCategoryChange}
          options={subCategories.map((subCategory) => ({
            value: subCategory.categoryId,
            label: subCategory.category,
          }))}
          isClearable
          onCreateOption={handleSubCreateCategory}
        />
        <Box width="4" /> {/* Adjust the width to set the space */}
        <CreatableSelect
          placeholder="Sub Sub Category"
          value={selectedSubSubCategory}
          onChange={handleSubSubCategoryChange}
          options={subSubCategories.map((subSubCategory) => ({
            value: subSubCategory.categoryId,
            label: subSubCategory.category,
          }))}
          isClearable
          onCreateOption={handleSubSubCreateCategory}
        />
        <Box width="4" /> {/* Adjust the width to set the space */}
        <Button colorScheme="teal" onClick={handleAddCategories}>Add</Button>
      </Flex>
    );
  };





  const [changedData, setChangedData] = useState({});
  const handleChange = (key, value) => {
    setChangedData((prevData) => {
      const updatedData = {
        ...prevData,
        [key]: value,
      };

     
      // Calculate Cubic if Length, Width, or Height is changed
      if (key === "Length" || key === "Width" || key === "Height") {
        const { Length, Width, Height } = updatedData;
       
        const cubic = (Width * Length * Height) / 1000000;
    
        updatedData.Cubic = cubic.toFixed(6); // Adjust the precision as needed
      }

      // if (key === "AZDF" || key === "AZDC" || key === "MyDeal" || key === "EDLP" || key === "WHS" || key === "Revlon" || key === "KoganEDI") {
      //  updatedData[key] = value ? 1 : 0;
      // }

      // If the value is empty, remove it from updatedData
    if (value === "" && key === "LongDescription") {
      delete updatedData[key];
    }
  
      return updatedData;
    });
  };
  

  const [selectedCategories, setSelectedCategories] = useState([responseData.ProductCategories]);

  const handleSelectCategory = (selectedOptions) => {
    const nonEmptyCategories = selectedOptions.filter(option => option.value.trim() !== "");
    SetExistingCategoriesData(nonEmptyCategories.map(option => option.value));
    handleChange("ProductCategories", nonEmptyCategories.map(option => option.value).join(","));
  };
  




  const handleSelectTagChange = (selectedOptions) => {
    SetExistingTagsData(selectedOptions.map(option => option.value));
    handleChange("ProductTags", selectedOptions.map((option) => option.value).join(","));
  };

  const handleSelectCountryOfOriginChange = (selectedOptions) => {
    SetExistingCountryOrigin(selectedOptions ? selectedOptions.value : null);
    handleChange("countryofOrigin", selectedOptions ? selectedOptions.value : null);
  };


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length >= 3) {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${constants.apiUrl}/fetch-product-details?searchText=${value}`, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          setSuggestions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);

          setSuggestions([]);
        });
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setProductData(suggestion);
    setSuggestions([]);
    setSearchText(suggestion.partNo);
    handleSearch(suggestion)
    // setResponseData({});
  };

  const handleSearch = (suggestion) => {
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");

    if (!token) {

      setIsLoading(false);
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${constants.apiUrl}/product-update-details`, {
        headers,
        withCredentials: true,
        params: {
          partNo: suggestion.partNo,
        },
      })
      .then((response) => {
        const responseData = response.data.records;
        // console.log('responseData', responseData[0]);
        setResponseData(responseData);
        setChangedData(responseData);
        setCategoryData(response.data.categories);
        if(responseData.ProductCategories !== ""){
          const categoriesString = responseData.ProductCategories;
          const catArr = categoriesString.split(",").map((category) => category);
          SetExistingCategoriesData(catArr);
        }
        
        const TagString = responseData.ProductTags;
        const tagsArr = TagString.split(",").map((tag) => tag);
        SetExistingTagsData(tagsArr);
        setTagsData(response.data.tags);
        SetExistingCountryOrigin(responseData.countryofOrigin);
        SetCountryOrigin(response.data.countyOfOrigin);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);

        setIsLoading(false);
        setResponseData([]);
      });
  };

  const handleAddCategories = (combinedCategories) => {
    // Check if combinedCategories is not empty
    if (combinedCategories.trim() !== "") {
      // Add the combined string to existingCategories
      SetExistingCategoriesData((prevCategories) => [...prevCategories, combinedCategories]);
      // Use the updated state directly to get the combined list
      const selectedCategoriesString = [...existingCategories, combinedCategories].join(",");
      handleChange("ProductCategories", selectedCategoriesString);
    }
  };
  
  

  const submitProductdata = () => {
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");

    if (!token) {

      setIsLoading(false);
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    changedData.partNo = productData.partNo;
    changedData.Barcode = productData.barcode;
    axios
      .post(`${constants.apiUrl}/submit-product-update`, changedData, {
        headers: headers, // Pass headers in the request config
        withCredentials: true,
      })
      .then((response) => {
        const responseData = response.data;

        if(responseData.success){
          toast({
            position: "bottom-right",
            title: "Product updated successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error submiting Product Update:", error);
        toast({
          position: "bottom-right",
          title: "Error submiting Product Update",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  return (
    <Card mt={4} p={4} backgroundColor="white">
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Box>
          <List spacing={2}>
            <ListItem>
              <Flex justify="center" alignItems="center">
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchText}
                  onChange={handleSearchChange}
                  mr={2}
                />
              </Flex>
            </ListItem>
          </List>
          <Box mb={6}>
            <List spacing={0}>
              {suggestions.length > 0 && (
                <VStack spacing={0} borderRadius={10} align="start" width={"92%"} border="1px solid #e2e8f0" divider={<StackDivider borderColor='gray.200' />}>
                  {suggestions.map((suggestion, index) => (
                    <Box key={suggestion.partNo}>
                      <ListItem
                        onClick={() => handleSuggestionSelect(suggestion)}
                        cursor="pointer"
                        // _hover={{ backgroundColor: "gray.200" }}
                        ml={2}
                        py={2} // Add padding to each suggestion
                      >
                        {suggestion.sku} - {" "} {suggestion.partNo} - {suggestion.description} -{" "}
                        {suggestion.barcode}
                      </ListItem>
                      {index < suggestions.length - 1 && (
                        <StackDivider borderColor="gray.200" />
                      )}
                    </Box>
                  ))}
                </VStack>
              )}
            </List>
          </Box>
        </Box>

        <Flex justify="space-between">
        <Box>
            <Text fontWeight="bold">Inventory ID:</Text>
            <Text>{productData.sku}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Part No:</Text>
            <Text>{productData.partNo}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">Description:</Text>
            <Text>{productData.description}</Text>
          </Box>
          <Box>
            <Text fontWeight="bold">UPC/Barcode:</Text>
            <Text>{productData.barcode}</Text>
          </Box>

        </Flex>

        {/* Prices Section */}
        {Object.keys(responseData).length !== 0 && (
          <Box>
            {/* Prices Section */}
            <Flex justify="space-between" mt={4} mb={4}>
              <Box style={{ marginRight: "8px" }}>
                <Text fontWeight="bold">Regular Price:</Text>
                <Input
                  type="number"
                  value={changedData.DefaultPrice || responseData.DefaultPrice}
                  onChange={(e) => handleChange("DefaultPrice", e.target.value)}
                />
              </Box>
              <Box style={{ marginRight: "8px" }}>
                <Text fontWeight="bold">Kogan Default Price:</Text>
                <Input
                  type="number"
                  value={changedData.KoganDefaultPrice || responseData.KoganDefaultPrice}
                  onChange={(e) => handleChange("KoganDefaultPrice", e.target.value)}
                />
              </Box>
              <Box style={{ marginRight: "8px" }}>
                <Text fontWeight="bold">RRP:</Text>
                <Input
                  type="number"
                  value={changedData.RRPPrice || responseData.RRPPrice}
                  onChange={(e) => handleChange("RRPPrice", e.target.value)}
                />
              </Box>
              <Box style={{ marginRight: "8px" }}>
                <Text fontWeight="bold">Beauty Mania Price:</Text>
                <Input
                  type="number"
                  value={changedData.BeautyManiaDefaultPrice || responseData.BeautyManiaDefaultPrice}
                  onChange={(e) => handleChange("BeautyManiaDefaultPrice", e.target.value)}
                />
              </Box>
              <Box style={{ marginRight: "8px" }}>
                <Text fontWeight="bold">P1:</Text>
                <Input
                  type="number"
                  value={changedData.P1 || responseData.P1}
                  onChange={(e) => handleChange("P1", e.target.value)}
                />
              </Box>
              <Box style={{ marginRight: "8px" }}>
                <Text fontWeight="bold">Landing Cost:</Text>
                <Input
                  type="number"
                  value={changedData.LCost || responseData.LCost}
                  onChange={(e) => handleChange("LCost", e.target.value)}
                />
              </Box>
              <Box>
                <Text fontWeight="bold">MyDeal Price:</Text>
                <Input
                  type="number"
                  value={changedData.MyDealPrice || responseData.MyDealPrice}
                  onChange={(e) => handleChange("MyDealPrice", e.target.value)}
                />
              </Box>
            </Flex>
            <Divider orientation="horizontal" borderColor="gray.300" h="80%" mb={4} />
            {/* Dimensions Section */}

            <Flex justify="space-between" mb={4}>
              <Box>
                <Text fontWeight="bold">Length:</Text>
                <Input
                  type="number"
                  value={changedData.Length || responseData.Length}
                  onChange={(e) => handleChange("Length", e.target.value)}
                />
              </Box>
              <Box>
                <Text fontWeight="bold">Width:</Text>
                <Input
                  type="number"
                  value={changedData.Width || responseData.Width}
                  onChange={(e) => handleChange("Width", e.target.value)}
                />
              </Box>
              <Box>
                <Text fontWeight="bold">Height:</Text>
                <Input
                  type="number"
                  value={changedData.Height || responseData.Height}
                  onChange={(e) => handleChange("Height", e.target.value)}
                />
              </Box>
              <Box>
                <Text fontWeight="bold">Cubic:</Text>
                <Input
                  type="text"
                  value={changedData.Cubic || responseData.Cubic}
                  onChange={(e) => handleChange("Cubic", e.target.value)}
                  readOnly
                />
              </Box>
            </Flex>
            <Divider orientation="horizontal" borderColor="gray.300" h="80%" mb={4} />
            <Flex justify="space-between" mb={4}>
              <Box>
                <Text fontWeight="bold">Brands:</Text>
                <Text>{responseData.BrandName}</Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Tags:</Text>
                <Select
                  value={existingTags.map((tag) => ({
                    value: tag,
                    label: tag,
                  }))}
                  onChange={handleSelectTagChange}
                  options={tagsData.map((tag) => ({ value: tag, label: tag }))}
                  isMulti
                  isClearable
                />
              </Box>
              <Box>
                <Text fontWeight="bold">Kogan Cagegory:</Text>
                <Input
                  type="text"
                  value={changedData.KoganCategory || responseData.KoganCategory}
                  onChange={(e) => handleChange("KoganCategory", e.target.value)}
                />
              </Box>
              <Box>
                <Text fontWeight="bold">MyDeal Category:</Text>
                <Input
                  type="text"
                  value={changedData.MyDealCategory || responseData.MyDealCategory}
                  onChange={(e) => handleChange("MyDealCategory", e.target.value)}
                />
              </Box>
            </Flex>
            <Divider orientation="horizontal" borderColor="gray.300" h="80%" mb={4} />
            <Text fontWeight="bold" mb={2}>Enabled For:</Text>
            <Flex justify="space-between" mb={4}>
          
            <Box>
                <Text fontWeight="semibold">Web:</Text>
                <Switch
                  colorScheme="teal"
                  isChecked={responseData.WebEnabled}
                  disabled={true}
                />
              </Box>
              <Box>
                <Text fontWeight="semibold">Amazon DC:</Text>
                <Switch
                  colorScheme="teal"
                  isChecked={changedData.AZDC !== undefined ? changedData.AZDC : responseData.AZDC}
                  onChange={e => handleChange("AZDC", e.target.checked)}
                />
              </Box>
              <Box>
                <Text fontWeight="semibold">Amazon DF:</Text>
                <Switch
                  colorScheme="teal"
                  isChecked={changedData.AZDF !== undefined ? changedData.AZDF : responseData.AZDF}
                  onChange={e => handleChange("AZDF", e.target.checked)}
                />
              </Box>
            
              <Box>
                <Text fontWeight="semibold">Kogan:</Text>
                <Switch
                  colorScheme="teal"
                  isChecked={changedData.KoganEDI !== undefined ? changedData.KoganEDI : responseData.KoganEDI}
                  onChange={e => handleChange("KoganEDI", e.target.checked)}
                />
              </Box>
              <Box>
                <Text fontWeight="semibold">MyDeal:</Text>
                <Switch
                  colorScheme="teal"
                  isChecked={changedData.MyDeal !== undefined ? changedData.MyDeal : responseData.MyDeal}
                  onChange={e => handleChange("MyDeal", e.target.checked)}
                />
              </Box>
              <Box>
                <Text fontWeight="semibold">WHS:</Text>
                <Switch
                  colorScheme="teal"
                  isChecked={changedData.WHS !== undefined ? changedData.WHS : responseData.WHS}
                  onChange={e => handleChange("WHS", e.target.checked)}
                />
              </Box>
              <Box>
                <Text fontWeight="semibold">Revlon:</Text>
                <Switch
                  colorScheme="teal"
                  isChecked={changedData.Revlon !== undefined ? changedData.Revlon : responseData.Revlon}
                  onChange={e => handleChange("Revlon", e.target.checked)}
                />
              </Box>
            </Flex>
            <Divider orientation="horizontal" borderColor="gray.300" h="80%" mb={4} />
            <Flex justify="space-between" mb={4}>
              <Box>
                <Text fontWeight="bold">Size:</Text>
                <Input
                  type="text"
                  value={changedData.Size || responseData.Size}
                  onChange={(e) => handleChange("Size", e.target.value)}
                />
              </Box>
              <Box>
                <Text fontWeight="bold">Weight:</Text>
                <Input
                  type="text"
                  value={changedData.Weight || responseData.Weight}
                  onChange={(e) => handleChange("Weight", e.target.value)}
                />
              </Box>
              <Box>
                <Text fontWeight="bold">Product Country Origin:</Text>
                <Select
                  value={existingCountryOrigin ? { value: existingCountryOrigin, label: existingCountryOrigin } : null}
                  onChange={handleSelectCountryOfOriginChange}
                  options={countryOrigin.map((origin) => ({ value: origin.name, label: origin.name }))}
                  isClearable
                />
              </Box>

              <Box>
                <Text fontWeight="bold">EveryDay Low Price:</Text>
                <Switch
                  colorScheme="teal"
                  isChecked={changedData.EDLP !== undefined ? changedData.EDLP : responseData.EDLP}
                  onChange={e => handleChange("EDLP", e.target.checked)}
                />
              </Box>
            </Flex>
            <Divider orientation="horizontal" borderColor="gray.300" h="80%" mb={4} />
            <Flex justify="space-between" mb={4}>
              <Box>
                <Text fontWeight="bold">Product Categories:</Text>
                <Select
                  value={existingCategories.map((category) => ({ value: category, label: category }))}
                  onChange={handleSelectCategory}
                  options={existingCategories.map((category) => ({ value: category, label: category }))}
                  isMulti
                  isClearable

                />
              </Box>
            </Flex>

            <Divider orientation="horizontal" borderColor="gray.300" h="80%" mb={4} />
            <Flex mb={4}>
              <Flex alignItems="center">
                <Text fontWeight="bold">Add new Category:</Text>
              </Flex>

              <Flex ml={6}> {/* Add zIndex here */}
                {/* Your additional content, e.g., AddCategoryDropdown */}
                <AddCategoryDropdown
                  parentCategories={categoryData}
                  onSelectParentCategory={(selectedOption) => {
                    console.log("Selected Parent Category:", selectedOption);
                  }}
                  onAddCategories={handleAddCategories}
                />
              </Flex>

            </Flex>
            <Divider orientation="horizontal" borderColor="gray.300" h="80%" mb={4} />
            <Box mt={6} mb={4}>
              <Text fontWeight="bold">Long Description:</Text>
              <Textarea
                value={changedData.LongDescription || responseData.LongDescription}
                onChange={(e) => handleChange("LongDescription", e.target.value)}
                size="sm"
                height="100px"
                width="100%"
              />
            </Box>

            <Flex justifyContent={"flex-end"}>
              <Button colorScheme="teal" onClick={() => submitProductdata()} >
                Update
              </Button>
            </Flex>
          </Box>
        )}

      </Box>
    </Card>
  );
}
