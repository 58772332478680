// Chakra imports
import {
    Box,
    SimpleGrid,
} from "@chakra-ui/react";
// Assets
import React from "react";
import ComplexTable from "views/admin/davkaBeautyReviewChanges/components/ComplexTable";


export default function DavkaBeautyReviewChanges() {
    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid gap='20px' mb='20px'>
                <ComplexTable />
            </SimpleGrid>
        </Box>
    );
}
