import React, { useState, useEffect } from "react";
import {
    Box,
    Text,
    Input,
    Select,
    Button,
    Flex,
    SimpleGrid,
    Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { constants } from "constants/constants";
import ComplexTable from "./components/ComplexTable";

const CustomerLogs = () => {
    const [formData, setFormData] = useState({
        AccountNo: "",
        DebtorName: "",
        LoginID: "",
        status: "",
        RecordCount: 200, // Default value
    });

    const statusOptions = {
        "": "Default",
        MissingData: "Missing Data",
        skipped: "Skipped",
        MultipleEmails: "Multiple Emails",
    };

    const handleChange = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const handleFormSubmit = (e) => {
    // e.preventDefault(); // Prevent the default form submission behavior
    setIsLoading(true);
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    // Call the API with the provided data
    axios
      .post(`${constants.apiUrl}/davka-customer-logs`, formData, {
        headers,
        withCredentials: true,
      })
      .then((response) => {
        const responseData = response.data.records;
        setResponseData(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching claims:", error);
        setIsLoading(false);
        setResponseData(null);
      });
  };
  
  useEffect(() =>{
    handleFormSubmit()
  },[formData]);

    return (
        <>

            <form onSubmit={handleFormSubmit}>
                <Flex direction="row" justifyContent="space-between" mt={20}>
                    <Box>
                        <Text fontWeight="bold">Customer Account No:</Text>
                        <Input
                            type="text"
                            value={formData.AccountNo}
                            onChange={(e) => handleChange("AccountNo", e.target.value)}
                        />
                    </Box>

                    <Box>
                        <Text fontWeight="bold">Debtor Name:</Text>
                        <Input
                            type="text"
                            value={formData.DebtorName}
                            onChange={(e) => handleChange("DebtorName", e.target.value)}
                        />
                    </Box>

                    <Box>
                        <Text fontWeight="bold">Login ID:</Text>
                        <Input
                            type="text"
                            value={formData.LoginID}
                            onChange={(e) => handleChange("LoginID", e.target.value)}
                        />
                    </Box>

                    <Box>
                        <Text fontWeight="bold">Status:</Text>
                        <Select
                            value={formData.status}
                            onChange={(e) => handleChange("status", e.target.value)}
                        >
                            {Object.entries(statusOptions).map(([key, status]) => (
                                <option key={key} value={key}>
                                    {status}
                                </option>
                            ))}
                        </Select>
                    </Box>

                    <Box>
                        <Text fontWeight="bold">Record Count:</Text>
                        <Input
                            type="number"
                            value={formData.RecordCount}
                            onChange={(e) => handleChange("RecordCount", e.target.value)}
                        />
                    </Box>

                    <Box>
                        <Button type="submit" colorScheme="teal" mt={6}>
                            Check Logs
                        </Button>
                    </Box>
                </Flex>
            </form>


            {isLoading ? (
        <Flex mt={8} alignItems="center" justifyContent="center">
          <Spinner size="xl" color="green.500" />
        </Flex>
      ) : null}

{responseData !== null && (
  <Box mt={8}>
    <Box borderWidth="1px" borderRadius="md" p={4}>
      <ComplexTable reportData={responseData} setFormData={setFormData} />
    </Box>
  </Box>
)}

        </>
    );
};

export default CustomerLogs;
