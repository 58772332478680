import React from "react";
import { Box, Button, Heading } from "@chakra-ui/react"; // Import Chakra UI components

// Define the sales order dashboard component
export default function SalesOrderDashboard() {
  return (
    <Box p="4">
      <Heading as="h2" mt="55px" mb="4">
        Welcome to Davka Portal
      </Heading>
      {/* Buttons for different functionalities */}
      <Button variant="brand" colorScheme="teal" size="md" mr="2" mt="2" as="a" href="/#/sales-order-queue">
        Sales Order Queue
      </Button>
      <Button variant="brand" colorScheme="teal" size="md" mr="2" mt="2" as="a" href="/#/search-sales-order">
        Search Sales Orders
      </Button>
      <Button variant="brand" colorScheme="teal" size="md" mr="2" mt="2" as="a" href="/#/new-sales-order">
        New Sales Order
      </Button>
      <Button variant="brand" colorScheme="teal" size="md" mr="2" mt="2" as="a" href="/#/customer-list">
       Web Customers
      </Button>
      <Button variant="brand" colorScheme="teal" size="md" mr="2" mt="2" as="a" href="/#/stock-status">
        Stock Status
      </Button>
      <Button variant="brand" colorScheme="teal" size="md" mr="2" mt="2" as="a" href="/#/open-web-orders">
        Open Web Orders
      </Button>
    </Box>
  );
}
