import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Spinner,
  Flex,
  SimpleGrid,
  Input,
  Text,
  Button,
  List,
  ListItem,
  VStack,
  StackDivider,
  Textarea,
  Table,
  Td,
  Th,
  Tbody,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Tooltip
} from "@chakra-ui/react";
import { FaEdit, FaTimes } from 'react-icons/fa';
import { constants } from "constants/constants";
import Card from "components/card/Card";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import SummaryTable from "./summaryTable";
export default function NewSalesOrder() {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [customerAccountNo, setCustomerAccountNo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [LogonCode, setLogonCode] = useState("");
  const [emailTo, setEmailTo] = useState("");
  const [creditLimit, setCreditLimit] = useState("");
  const [customerPO, setCustomerPO] = useState("");
  const [shipVia, setShipVia] = useState("");
  const [shipTo, setShipTo] = useState("");
  const [billTo, setBillTo] = useState("");
  const [postageCost, setPostageCost] = useState("");
  const [freePostageAbove, setFreePostageAbove] = useState("");
  const [debtorClassificiationID, setDebtorClassificiationID] = useState("");
  const [dateOrdered, setDateOrdered] = useState(new Date());
  const [dateExpected, setDateExpected] = useState(new Date());
  const [SearchProductText, setSearchProductText] = useState("");
  const [orderLines, setOrderLines] = useState([]);
  const [productSuggestions, setProductSuggestions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();


  const EditShipToModal = ({ isOpen, onClose, initialAddress, onSubmit }) => {

    const shippingAddressParts = initialAddress.split(",").map(part => part.trim());
    const [editedShipTo, setEditedShipTo] = useState({
      addressLine1: shippingAddressParts[0],
      addressLine2: shippingAddressParts[1],
      city: shippingAddressParts[2],
      postcode: shippingAddressParts[3], // Add postcode
      state: shippingAddressParts[4],    // Add state
      country: shippingAddressParts[5],  // Add country
    });

    const [addressChangeFlag, setAddressChangeFlag] = useState(false);

    const handleInputChange = (field, value) => {
      setEditedShipTo({ ...editedShipTo, [field]: value });
      setAddressChangeFlag(true);
    };

    const handleShipToSubmit = () => {
      // Check if there were any changes
      if (addressChangeFlag) {
        // Call your submit function with the edited data
        onSubmit(editedShipTo);
        // Reset the flag
        setAddressChangeFlag(false);
      }

      // Close the modal
      onClose();
    };


    return (
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Ship To</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Address line 1"
              value={editedShipTo.addressLine1}
              onChange={(e) => handleInputChange("addressLine1", e.target.value)}
              mb={2}
            />
            <Textarea
              placeholder="Address line 2"
              value={editedShipTo.addressLine2}
              onChange={(e) => handleInputChange("addressLine2", e.target.value)}
              mb={2}
            />
            <Input
              placeholder="City"
              value={editedShipTo.city}
              onChange={(e) => handleInputChange("city", e.target.value)}
              mb={2}
            />
            <Input
              placeholder="Postcode/Zip"
              value={editedShipTo.postcode}
              onChange={(e) => handleInputChange("postcode", e.target.value)}
              mb={2}
            />
            <Input
              placeholder="State"
              value={editedShipTo.state}
              onChange={(e) => handleInputChange("state", e.target.value)}
              mb={2}
            />
            <Input
              placeholder="Country"
              value={editedShipTo.country}
              onChange={(e) => handleInputChange("country", e.target.value)}
              mb={2}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleShipToSubmit}>
              Submit
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const onSubmitShipTo = (updatedShipTo) => {
    setShipTo(
      `${updatedShipTo.addressLine1}, ${updatedShipTo.addressLine2}, ${updatedShipTo.city}, ${updatedShipTo.postcode}, ${updatedShipTo.state}, ${updatedShipTo.country}`
    );
  };

  const handleProductSuggestions = (suggestion) => {
    // Add the selected product to the order lines if it's not already present
    if (suggestion) {
      const partNoExists = orderLines.some((line) => line.partNo === suggestion.partNo);

      if (!partNoExists) {
        const newOrderLine = {
          productId: suggestion.DBProductID,
          partNo: suggestion.partNo,
          productName: suggestion.productName,
          barcode: suggestion.barcode,
          qty: 1,
          disc: 0.00,
          discPercentage: 0.00,
          sellPrice: suggestion.sellPrice,
          unitPrice: 0.00,
          totalTax: parseFloat(suggestion.sellPrice * 0.1).toFixed(2), // Assuming totalTax is 10% of sellPrice
          lineTotal: (
            parseFloat(suggestion.sellPrice) +
            parseFloat(suggestion.sellPrice) * 0.1
          ).toFixed(2),
          DebtorClassificationPrices: suggestion.DebtorClassificationPrices,
          originalSellPrice: suggestion.sellPrice,
          firstSellPrice: suggestion.sellPrice,
          priceFlag: 1,
          testerAllowed: suggestion.testerAllowed
        };

        setOrderLines([...orderLines, newOrderLine]);

        // Clear the selected product
        setSelectedProduct(null);
        // Clear the product search input
        setSearchProductText("");
        // Clear product suggestions
        setProductSuggestions([]);
      } else {
        // If the partNo already exists, you may display an error message or take appropriate action
        console.log("PartNo already exists in orderLines.");
      }
    }
  }


  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length >= 3) {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${constants.apiUrl}/fetch-customer-details?searchText=${value}&details=all&newOrder=1`, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          setSuggestions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);

          setSuggestions([]);
        });
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (suggestion) => {
    setCustomerAccountNo(suggestion.accountNo);
    setCustomerName(suggestion.customerName);
    setCompanyName(suggestion.companyName);
    setEmailTo(suggestion.CustomerEmail);
    setShipTo(suggestion.shippingAddress);
    setBillTo(suggestion.billingAddress);
    setCreditLimit(suggestion.creditLimit);
    setLogonCode(suggestion.LogonCode);
    setDebtorClassificiationID(suggestion.debtorClassificationID);
    setPostageCost(suggestion.postageCost);
    setFreePostageAbove(suggestion.freePostageAbove);
    setSuggestions([]);
    setSearchText(suggestion.customerName);
    setResponseData([]);
  };

  const handleProductSearchChange = (e) => {
    const value = e.target.value;
    setSearchProductText(value);

    if (value.length >= 3) {
      const token = localStorage.getItem("accessToken");

      if (!token) {
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${constants.apiUrl}/fetch-product-details?searchText=${value}&details=all`, {
          headers,
          withCredentials: true,
        })
        .then((response) => {
          setProductSuggestions(response.data);
        })
        .catch((error) => {
          console.error("Error fetching suggestions:", error);

          setProductSuggestions([]);
        });
    } else {
      setProductSuggestions([]);
    }
  };
  // Effect to log updated order lines
  useEffect(() => {
    console.log("Updated order lines:", orderLines);
  }, [orderLines]); // Runs whenever orderLines changes

  const handleQtyChange = (index, value) => {
    const updatedOrderLines = [...orderLines];

    const originalSellPrice = updatedOrderLines[index].originalSellPrice; // Keep a reference to the original sellPrice

    updatedOrderLines[index].qty = value;
    updatedOrderLines[index].lineTotal = parseFloat(
      originalSellPrice * value
    ).toFixed(2);
    updatedOrderLines[index].totalTax = parseFloat(
      (originalSellPrice * 0.1) * value // Assuming totalTax is 10% of originalSellPrice
    ).toFixed(2);

    // Apply debtor classification prices
    const debtorClassificationPrices = updatedOrderLines[index].DebtorClassificationPrices.split('|');
    const matchingClassification = debtorClassificationPrices.find((classification) => {
      const [, classificationID, , discountQty] = classification.split(',');
      return classificationID === debtorClassificiationID && parseInt(discountQty) <= value;
    });

    if (matchingClassification) {
      const [, , rawDiscountPercentage] = matchingClassification.split(',');

      // Convert rawDiscountPercentage to a positive number
      const discountPercentage = Math.abs(parseFloat(rawDiscountPercentage));

      const discountedSellPrice = originalSellPrice * (1 - discountPercentage / 100);

      // Update discount, discPercentage, and sellPrice
      updatedOrderLines[index].disc = parseFloat(originalSellPrice - discountedSellPrice).toFixed(2);
      updatedOrderLines[index].discPercentage = discountPercentage;
      updatedOrderLines[index].sellPrice = parseFloat(discountedSellPrice).toFixed(2);
      updatedOrderLines[index].priceFlag = 0;
      updatedOrderLines[index].totalTax = parseFloat(
        (discountedSellPrice * 0.1) * value // Assuming totalTax is 10% of originalSellPrice
      ).toFixed(2);
      updatedOrderLines[index].lineTotal = (
        parseFloat(discountedSellPrice) * parseFloat(value) +
        parseFloat(updatedOrderLines[index].totalTax)
      ).toFixed(2);


    } else {
      // If no matching classification, reset sellPrice to the originalSellPrice
      updatedOrderLines[index].sellPrice = parseFloat(originalSellPrice).toFixed(2);
      updatedOrderLines[index].disc = 0; // Reset discount
      updatedOrderLines[index].discPercentage = 0; // Reset discount percentage
      updatedOrderLines[index].totalTax = parseFloat(
        (originalSellPrice * 0.1) * value // Assuming totalTax is 10% of originalSellPrice
      ).toFixed(2);
      updatedOrderLines[index].lineTotal = (
        parseFloat(originalSellPrice) * parseFloat(value) +
        parseFloat(updatedOrderLines[index].totalTax)
      ).toFixed(2);

    }

    // Update tester details if the current line is a parent product
    if (!updatedOrderLines[index].productName.includes("Tester")) {
      // Find the index of the corresponding tester line
      const testerIndex = updatedOrderLines.findIndex(
        (line) =>
          line.partNo === updatedOrderLines[index].partNo &&
          line.productName.includes("Tester")
      );

      if (testerIndex !== -1) {

        const calculateDiscount = (unitPrice, discPercentage) => {
          return unitPrice * (discPercentage / 100);
        };

        // Function to calculate the sell price after discount
        const calculateSellPrice = (sellPrice, disc) => {
          return sellPrice * (1 - disc / 100);
        };

        // Function to calculate the unit price after discount
        const calculateUnitPrice = (unitPrice, disc) => {
          return unitPrice * (1 - disc / 100);
        };

        // Function to calculate the total tax after discount
        // Function to calculate the total tax after discount
        const calculateTotalTax = (sellPrice, disc) => {
          // Assuming total tax is a percentage of the sell price
          return sellPrice * 0.1; // Assuming totalTax is 10% of the sell price
        };
        // Function to calculate the line total after discount
        const calculateLineTotal = (sellPrice, totalTax, disc) => {
          const priceAfterDiscount = sellPrice + calculateTotalTax(sellPrice);

          return priceAfterDiscount;
        };

        const currentLine = updatedOrderLines[index];
        const testerLine = updatedOrderLines[testerIndex];

        let disc = 10;
        if (currentLine.qty >= 12) {
          disc = 50;
        }


        // Update tester's sell price, total tax, and line total
        testerLine.sellPrice = calculateSellPrice(currentLine.sellPrice, disc);
        testerLine.totalTax = calculateTotalTax(calculateSellPrice(currentLine.sellPrice, disc), disc);
        testerLine.lineTotal = calculateLineTotal(calculateSellPrice(currentLine.sellPrice, disc), currentLine.totalTax, disc);
        testerLine.disc = calculateDiscount(calculateSellPrice(currentLine.sellPrice, disc), disc);
      }
    }

    setOrderLines(updatedOrderLines);
  };
  const [parentPartNumbers, setParentPartNumbers] = useState([]); // Array to store unique parent part numbers



  // Render the selected order lines
  const renderOrderLines = () => {

    const handleEditSellPrice = (index, newPrice) => {
      const updatedLines = [...orderLines];
      const currentLine = updatedLines[index];

      // Check if the new price is different from the current price
      if (newPrice === currentLine.firstSellPrice) {
        updatedLines[index].isPriceChanged = false; // Set the flag if price has changed
      } else {
        updatedLines[index].isPriceChanged = true; // Reset the flag if price is the same
      }

      // Update the price and recalculate related fields
      updatedLines[index].sellPrice = newPrice;
      updatedLines[index].originalSellPrice = newPrice;
      updatedLines[index].disc = calculateDiscount(newPrice, updatedLines[index].discPercentage);
      updatedLines[index].totalTax = calculateTotalTax(newPrice, updatedLines[index].discPercentage);
      updatedLines[index].lineTotal = calculateLineTotal(newPrice, updatedLines[index].totalTax, updatedLines[index].discPercentage);

      setOrderLines(updatedLines);

    };

    const handleRemove = (indexToRemove) => {
      const lineToRemove = orderLines[indexToRemove];
      if (lineToRemove.productName.includes("Tester")) {
        // If the line is a tester, remove only the tester
        const updatedOrderLines = orderLines.filter((_, index) => index !== indexToRemove);
        setOrderLines(updatedOrderLines);
      } else {
        // If the line is a parent product
        const partNoToRemove = lineToRemove.partNo;
        const filteredLines = orderLines.filter((line) => {
          if (line.partNo === partNoToRemove) {
            if (line.productName.includes("Tester")) {
              // If it's a parent product of a tester, remove both parent product and tester(s)
              setParentPartNumbers(parentPartNumbers.filter((partNo) => partNo !== partNoToRemove));
              return false;
            } else {
              // If it's only a parent product, remove it
              return false;
            }
          }
          return true;
        });
        setOrderLines(filteredLines);
      }
    };





    const handleAddTester = (index) => {
      const currentLine = orderLines[index];
      const testerExists = orderLines.some(line =>
        line.partNo === currentLine.partNo && line.productName.includes("Tester")
      );

      if (!currentLine.productName.includes("Tester") && currentLine.testerAllowed && !testerExists) {
        let disc = 10;
        if (currentLine.qty >= 12) {
          disc = 50;
        }

        const newLine = {
          ...currentLine,
          qty: 1,
          productName: `Tester - ${currentLine.productName}`,
          discPercentage: disc, // Apply the correct discount
          disc: calculateDiscount(currentLine.sellPrice, disc), // Calculate the discount amount
          sellPrice: calculateSellPrice(currentLine.sellPrice, disc), // Apply the correct sell price
          unitPrice: calculateUnitPrice(currentLine.unitPrice, disc), // Apply the correct unit price
          totalTax: calculateTotalTax(calculateSellPrice(currentLine.sellPrice, disc), disc), // Apply the correct total tax
          lineTotal: calculateLineTotal(currentLine.sellPrice, currentLine.totalTax, disc), // Apply the correct line total
        };
        setOrderLines([...orderLines, newLine]);
      }
    };


    const calculateDiscount = (unitPrice, discPercentage) => {
      return unitPrice * (discPercentage / 100);
    };

    // Function to calculate the sell price after discount
    const calculateSellPrice = (sellPrice, disc) => {
      return sellPrice * (1 - disc / 100);
    };

    // Function to calculate the unit price after discount
    const calculateUnitPrice = (unitPrice, disc) => {
      return unitPrice * (1 - disc / 100);
    };

    // Function to calculate the total tax after discount
    const calculateTotalTax = (sellPrice, disc) => {
      // Assuming total tax is a percentage of the sell price
      return sellPrice * 0.1; // Assuming totalTax is 10% of the sell price
    };
    // Function to calculate the line total after discount
    const calculateLineTotal = (sellPrice, totalTax, disc) => {
      const priceAfterDiscount = calculateSellPrice(sellPrice, disc) + calculateTotalTax(calculateSellPrice(sellPrice, disc), disc);
      return priceAfterDiscount;
    };



    return (
      <div style={{ overflowX: 'auto' }}>
        <Table variant="simple" ps={0}>
          <Thead>
            <Tr>
              <Th></Th>
              <Th style={{ paddingInlineStart: 0 }}>Part No</Th>
              <Th style={{ paddingInlineStart: 0 }}>Description</Th>
              <Th style={{ paddingInlineStart: 0 }}>Barcode</Th>
              <Th textAlign={"right"} style={{ paddingInlineStart: 0 }}>Qty</Th>
              <Th textAlign={"right"} style={{ paddingInlineStart: 0 }}>Disc</Th>
              <Th textAlign={"right"} style={{ paddingInlineStart: 0 }}>Disc Percentage</Th>
              <Th textAlign={"right"} style={{ paddingInlineStart: 0 }}>Sell Price</Th>
              {/* <Th textAlign={"right"} style={{ paddingInlineStart: 0 }}>Unit Price</Th> */}
              <Th textAlign={"right"} style={{ paddingInlineStart: 0 }}>Total Tax</Th>
              <Th textAlign={"right"} style={{ paddingInlineStart: 0 }}>Line Total</Th>
              <Th style={{ paddingInlineStart: 0 }}>Tester</Th>
            </Tr>
          </Thead>
          <Tbody>
            {orderLines.map((line, index) => (
              <Tr key={index}>
                <Td style={{ paddingInlineStart: 0 }}>
                  <FaTimes onClick={() => handleRemove(index)} style={{ cursor: 'pointer' }} />
                </Td>
                <Td style={{ paddingInlineStart: 0 }}>{line.partNo}</Td>
                <Td style={{ paddingInlineStart: 0 }}>{line.productName}</Td>
                <Td style={{ paddingInlineStart: 0 }}>{line.barcode}</Td>
                <Td textAlign={"right"} style={{ paddingInlineStart: 0 }}>
                  {line.productName.includes("Tester") ? (
                    // If product name includes "Tester", render as text
                    <span>{line.qty}</span>
                  ) : (
                    // If product name does not include "Tester", render as input
                    <Input
                      style={{ border: "1px solid grey", width: "100%" }}
                      type="number"
                      value={line.qty}
                      textAlign={"right"}
                      onChange={(e) =>
                        handleQtyChange(index, parseFloat(e.target.value))
                      }
                    />
                  )}
                </Td>

                <Td textAlign={"right"} style={{ paddingInlineStart: 0 }}>{parseFloat(line.disc).toFixed(2)}</Td>
                <Td textAlign={"right"} style={{ paddingInlineStart: 0 }}>{parseFloat(line.discPercentage).toFixed(2)}</Td>
              
                  {!line.productName.includes("Tester") ? (
                      <Td textAlign={"right"} style={{ paddingInlineStart: 0, display: "flex", alignItems: "baseline" }}>
                  {parseFloat(line.sellPrice).toFixed(2)}
                    <Tooltip label="Enter discounted price" aria-label="Enter discounted price">
                      <IconButton
                        icon={<FaEdit />}
                        onClick={() => {
                          const newPrice = prompt('Enter new discounted price:', line.sellPrice);
                          if (newPrice) {
                            handleEditSellPrice(index, parseFloat(newPrice));
                          }
                        }}
                        size="sm"
                        variant="outline"
                      />
                    </Tooltip>
                    </Td>
                  ): (
                    <Td textAlign={"right"} style={{ paddingInlineStart: 0 }}>{parseFloat(line.sellPrice).toFixed(2)}</Td>
                  )}



                  {/* Indicate price change */}
                  {/* {line.isPriceChanged && <span style={{ color: 'red' }}> *</span>}  */}
                {/* <Td textAlign={"right"} style={{ paddingInlineStart: 0 }}>{parseFloat(line.unitPrice).toFixed(2)}</Td> */}
                <Td textAlign={"right"} style={{ paddingInlineStart: 0 }}>{parseFloat(line.totalTax).toFixed(2)}</Td>
                <Td textAlign={"right"} style={{ paddingInlineStart: 0 }}>{parseFloat(line.lineTotal).toFixed(2)}</Td>
                <Td style={{ paddingInlineStart: 0 }}>
                  {!line.productName.includes("Tester") && line.testerAllowed && !parentPartNumbers.includes(line.partNo) && ( // Button only for parent products
                    <Button h={"28px"} fontSize={"13px"} variant={"brand"} onClick={() => handleAddTester(index)} disabled={line.productName.includes("Tester")}>
                      Add Tester
                    </Button>
                  )}
                </Td>


              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    );
  };

  const customerData = {
    shipTo,
    billTo,
    customerAccountNo,
    companyName,
    creditLimit,
    LogonCode,
    postageCost,
    freePostageAbove
  };
  return (
    <Card mt={4} p={4} backgroundColor="white">
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Box>
          <List spacing={2}>
            <ListItem>
              <Flex justify="center" alignItems="center">
                <Input
                  type="text"
                  placeholder="Search Customer..."
                  value={searchText}
                  onChange={handleSearchChange}
                  mr={2}
                />
              </Flex>
            </ListItem>
          </List>
          <Box mb={6}>
            <List spacing={0}>
              {suggestions.length > 0 && (
                <VStack
                  spacing={0}
                  borderRadius={10}
                  align="start"
                  width={"92%"}
                  border="1px solid #e2e8f0"
                  divider={<StackDivider borderColor="gray.200" />}
                >
                  {suggestions.map((suggestion, index) => (
                    <Box key={suggestion.accountNo}>
                      <ListItem
                        onClick={() => handleSuggestionSelect(suggestion)}
                        cursor="pointer"
                        ml={2}
                        py={2}
                      >
                        {suggestion.accountNo} - {suggestion.customerName} -{" "}
                        {suggestion.companyName}
                      </ListItem>
                    </Box>
                  ))}
                </VStack>
              )}
            </List>
          </Box>
        </Box>

        <SimpleGrid columns={3} gap={4} mt={4}>
          <Box>
            <Text fontWeight="bold">Customer Account No:</Text>
            <Input
              placeholder="Customer Account No"
              value={customerAccountNo}
              onChange={(e) => setCustomerAccountNo(e.target.value)}
            />
          </Box>
          <Box>
            <Text fontWeight="bold">Customer Name:</Text>
            <Input
              placeholder="Customer Name"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </Box>
          <Box>
            <Text fontWeight="bold">Company Name:</Text>
            <Input
              placeholder="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Box>
          <Box>
            <Text fontWeight="bold">Email:</Text>
            <Input
              placeholder="Email"
              value={emailTo}
              disabled={true}
            />
          </Box>
          <Box>
            <Text fontWeight="bold">Credit Limit:</Text>
            <Input
              placeholder="Credit Limit"
              value={creditLimit}
              onChange={(e) => setCreditLimit(e.target.value)}
              disabled={true}
            />
          </Box>
          <Box>
            <Text fontWeight="bold">Customer PO No:</Text>
            <Input
              placeholder="Customer PO No"
              value={customerPO}
              onChange={(e) => setCustomerPO(e.target.value)}
            />
          </Box>
          <Box>
            <Text fontWeight="bold">Ship Via:</Text>
            <Input
              placeholder="Ship Via"
              value={shipVia}
              onChange={(e) => setShipVia(e.target.value)}
              disabled={true}
            />
          </Box>
          <Box>
            <Text fontWeight="bold">Ship To:</Text>
            <Flex>
              <Textarea
                value={shipTo}
                isReadOnly
              />
              <IconButton
                icon={<FaEdit />}
                aria-label="Edit Ship To"
                ml={2}
                onClick={onOpen}
              />
            </Flex>
          </Box>
          <Box>
            <Text fontWeight="bold">Bill To:</Text>
            <Textarea
              placeholder="Bill To"
              value={billTo}
              onChange={(e) => setBillTo(e.target.value)}
            />
          </Box>
          <Box>
            <Text fontWeight="bold">Date Ordered:</Text>
            <DatePicker
              placeholderText="Select Date"
              selected={dateOrdered}
              onChange={(date) => setDateOrdered(date)}
              dateFormat="dd/MM/yyyy"
              customInput={
                <input
                  style={{
                    padding: "8px",
                    fontSize: "14px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              }
            />
          </Box>
          <Box>
            <Text fontWeight="bold">Date Expected:</Text>
            <DatePicker
              placeholderText="Select Date"
              selected={dateExpected}
              onChange={(date) => setDateExpected(date)}
              dateFormat="dd/MM/yyyy"
              customInput={
                <input
                  style={{
                    padding: "8px",
                    fontSize: "14px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              }
            />
          </Box>
        </SimpleGrid>
      </Box>

      <Box mt={4}>
        <List spacing={2}>
          <ListItem>
            <Flex justify="center" alignItems="center">
              <Input
                type="text"
                placeholder="Search Product..."
                value={SearchProductText}
                onChange={handleProductSearchChange}
                mr={2}
                disabled={!debtorClassificiationID}
              />
            </Flex>
          </ListItem>
        </List>
        <Box mb={6}>
          <List spacing={0}>
            {productSuggestions.length > 0 && (
          <VStack
          spacing={0}
          borderRadius={10}
          align="start"
          width={"92%"}
          border="1px solid #e2e8f0"
          divider={<StackDivider borderColor="gray.200" />}
        >
          {productSuggestions.map((suggestion) => (
            <Box key={suggestion.partNo}>
              <ListItem
                onClick={() => {
                  if (suggestion.stockQuantity > 0) {
                    handleProductSuggestions(suggestion);
                  }
                }}
                cursor={suggestion.stockQuantity > 0 ? "pointer" : "not-allowed"}
                ml={2}
                py={2}
                color={suggestion.stockQuantity > 0 ? "green.500" : "red.500"}
                opacity={suggestion.stockQuantity === 0 ? 0.6 : 1} // Optional: reduce opacity for out-of-stock
                pointerEvents={suggestion.stockQuantity === 0 ? "none" : "auto"} // Disable pointer events if out of stock
              >
                {suggestion.partNo} - {suggestion.productName} - {suggestion.barcode} -{" "}
                {suggestion.stockQuantity > 0 ? "In Stock" : "Out of Stock"}
              </ListItem>
            </Box>
          ))}
        </VStack>
        
          
            )}
          </List>
        </Box>

        {orderLines.length > 0 && (
          <Box>
            {renderOrderLines()}
            <SummaryTable orderLines={orderLines} customerData={customerData} dateOrdered={dateOrdered} dateExpected={dateExpected} /> {/* Include the SummaryTable component here */}
          </Box>



        )}


      </Box>

      <EditShipToModal isOpen={isOpen} onClose={onClose} initialAddress={shipTo} onSubmit={onSubmitShipTo} />

    </Card>
  );
}
