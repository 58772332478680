import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  useColorModeValue,
  SimpleGrid,
  Button,
  Icon,
  FormControl,
  FormLabel,
  Switch,
  Input,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import ReactSelect from "react-select"; // Import React Select
import { FaEdit } from "react-icons/fa";
import DatePicker from "react-datepicker"; // Make sure to import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker CSS
import { format, parseISO } from 'date-fns';
import { constants } from "constants/constants";
import { useHistory } from "react-router-dom";
const fields = [
  { name: "Report ID", key: "reportId", editable: true, type: "text" },
  { name: "Report Name", key: "reportName", editable: true, type: "text" },
  { name: "Role Required", key: "roleRequired", editable: true, type: "select" },
  { name: "Web Enabled", key: "webEnabled", editable: true, type: "switch" },
  { name: "Email Enabled", key: "emailEnabled", editable: true, type: "switch" },
];

function AddReport() {
  const [recordDetails, setRecordDetails] = useState({});
  const [editableFields, setEditableFields] = useState({
    reportId: "", // Initialize with an empty string
    reportName: "", // Initialize with an empty string
    roleRequired: [], // Initialize as an empty array
    webEnabled: "off", // Initialize with a default value ("off" or "on")
    emailEnabled: "off", // Initialize with a default value ("off" or "on")
  });
  
  const [editableField, setEditableField] = useState(null);
  const [invoicedDate, setInvoicedDate] = useState(new Date()); // State for DatePicker
  const [isBillable, setIsBillable] = useState(0); // State for Switch
  
  const toast = useToast();
  const history = useHistory();
  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }
    
    const paramsArray = queryString.split("&");
    const params = {};
    
    paramsArray.forEach(param => {
      const [key, value] = param.split("=");
      params[key] = decodeURIComponent(value);
    });
    
    return params;
  };
  
  const windowUrl = window.location.href;
  const queryParams = getQueryParams(windowUrl);
  const id = queryParams["id"];


  const handleEdit = (key, value) => {
    // If the field is "roleRequired" and the value is an array, use it directly
    if (key === "roleRequired" && Array.isArray(value)) {
      setEditableFields((prevEditableFields) => ({
        ...prevEditableFields,
        [key]: value,
      }));
    } else {
      // For other fields, set the value as is
      setEditableFields((prevEditableFields) => ({
        ...prevEditableFields,
        [key]: value,
      }));
    }
  };
  

  const handleUpdate = async () => {
    try {
      const updatedFields = {};

      fields.forEach((field) => {
        if (field.type === "switch") {
          updatedFields[field.key] = editableFields[field.key] === "on" ? "on" : "off";
        } else if (field.type === "text" || field.type === "number") {
          updatedFields[field.key] = editableFields[field.key] || recordDetails[field.key];
        } else if (field.type === "select") {
          // Handle multi-select (comma-delimited values)
          if (Array.isArray(editableFields[field.key])) {
            updatedFields[field.key] = editableFields[field.key].join(",");
          } else {
            updatedFields[field.key] = editableFields[field.key] || recordDetails[field.key];
          }
        }
      });
  
  
      const response = await axios.put(
        `${constants.apiUrl}/add-report`,
        {
          fields: updatedFields,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 201) {
        toast({
          position: "bottom-right",
          title: "Report added successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push('/view-report?id='+ response.data.id)
      } else {
        toast({
          position: "bottom-right",
          title: "Error updating Report",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating record:", error);
      toast({
        position: "bottom-right",
        title: "Error updating record",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  return (
    <Box mt={24} p={4} borderWidth="1px" borderRadius="lg" shadow="md" backgroundColor={"white"}>
      <SimpleGrid columns={1} spacing={4}>
        {/* Editable fields */}
        {fields
          .filter((field) => field.editable)
          .map((field) => (
            <Box key={field.key}>
              <Text fontWeight="bold">{field.name}:</Text>
              {field.type === "switch" ? (
               <Switch
               isChecked={editableFields[field.key] === "on" || (recordDetails[field.key] === "on" && editableFields[field.key] === undefined)}
               onChange={() => {
                 const newEditableFields = { ...editableFields };
                 newEditableFields[field.key] = editableFields[field.key] === "on" ? "off" : "on";
                 setEditableFields(newEditableFields);
               }}
               size="lg"
               ml={2}
               transform="scale(1.2)"
             />
             
              ) : field.type === "select" ? (
                <ReactSelect
                isMulti
                options={[
                  { value: "GlobalAdmin", label: "Global Admin" },
                  { value: "SalesAdmin", label: "Sales Admin" },
                  { value: "SalesRep", label: "Sales Rep" },
                  { value: "SalesMarketing", label: "Sales Marketing" },
                  { value: "SpecialsAdmin", label: "Specials Admin" },
                  { value: "WarehouseSupport", label: "Warehouse Support" },
                  { value: "Owner", label: "Owner" },
                  { value: "Accounts", label: "Accounts" },
                ]}
                // Set the selected option based on editableFields.roleRequired or recordDetails.roleRequired
                value={editableFields.roleRequired.map((value) => ({ value, label: value })) || []}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map((option) => option.value); // Store values
                  handleEdit("roleRequired", selectedValues);
                }}
              />
              
              
              
              ) : (
                <Input
                  type={field.type}
                  value={editableFields[field.key] !== undefined ? editableFields[field.key] : recordDetails[field.key]}
                  onChange={(e) => handleEdit(field.key, e.target.value)}
                />
              )}
            </Box>
          ))}
      </SimpleGrid>
      <Button mt={4} colorScheme="green" onClick={handleUpdate}>
        Add
      </Button>
    </Box>
  );

}

export default AddReport;
