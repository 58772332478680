import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  Select,
  Input,
  Button,
  Box
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import axios from "axios";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";

export default function ComplexTable() {
  const columnsData = [
    {
      Header: "ContactName ID",
      accessor: "ContactNameID",
    },
    {
      Header: "Logon Code",
      accessor: "LogonCode",
    },
     {
      Header: "Name",
      accessor: "customerName",
    },
    {
      Header: "Customer Email",
      accessor: "CustomerEmail",
    },
    {
      Header: "Account No",
      accessor: "accountNo",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
   
    {
      Header: "Password",
      accessor: "Password",
    },
  
    {
      Header: "Status",
      accessor: "customerStatus", // Assuming the accessor contains unique identifiers
    },
    {
      Header: "Sync Status",
      accessor: "SyncStatus", // Assuming the accessor contains unique identifiers
    },
    {
      Header: "Notes",
      accessor: "Notes",
    },
  
  ];


  const [isDataLoading, setIsDataLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    gotoPage(newPage - 1); // Manually go to the new page (subtract 1 because react-table uses 0-based indexing)
  };
  
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
    gotoPage(0); // Manually go to the first page (0-indexed)
  };
  
  const handleSearchChange = (e) => {
    const value = e.target.value;
      setSearchQuery(value);
  };
  

  const fetchDataForPage = async (page, pageSize, searchQuery) => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/davka-customers`,
        {
          headers,
          withCredentials: true,
          params: {
            page: page,
            pageSize: pageSize,
            search: searchQuery, // Add the search query to the params
          },
        }
      );

      const data = response.data.users;
      setTableData(data);
      setTotalCount(response.data.totalCount);
      setTotalPages(response.data.totalPages);
      // setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      // Handle error if necessary
    }finally{
      setIsDataLoading(false);
    }
  };

  const fetchDataForSearch = async (page, pageSize, searchQuery) => {
    try {
      // setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/davka-customers`,
        {
          headers,
          withCredentials: true,
          params: {
            page: page,
            pageSize: pageSize,
            search: searchQuery, // Add the search query to the params
          },
        }
      );

      const data = response.data.users;
      setTableData(data);
      setTotalCount(response.data.totalCount);
      setTotalPages(response.data.totalPages);
      // setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      // Handle error if necessary
    }finally{
      // setIsDataLoading(false);
    }
  };

   // Fetch data for the initial page
  
  useEffect(() => {
    if (searchQuery.length >= 2) {
      fetchDataForSearch(currentPage, pageSize, searchQuery);
    } else if (searchQuery.length === 0) {
      // Reset the search when searchQuery is empty
      fetchDataForPage(currentPage, pageSize);
    }
  }, [currentPage, pageSize, searchQuery]);


 
  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
      },
      // Add this option to make pagination work
    manualPagination: true,
    pageCount: totalPages, // Pass the total number of pages
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );



  const handleApprove = async (user) => {
    try {
      // Call your API to approve the user with the given userId
      const response = await axios.put(
        `${constants.apiUrl}/approve-user/${user.id}`,
        { is_approve: 1 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 200) {
        // Update table data after approval
        updateTableData(user.id, true); // Pass 'true' to indicate approval
      }
    } catch (error) {
      console.error("Error approving user:", error);
      // Handle error if necessary
    }
  };
  
  const handleDisapprove = async (user) => {
    try {
      // Call your API to disapprove the user with the given userId
      const response = await axios.put(
        `${constants.apiUrl}/approve-user/${user.id}`,
        { is_approve: 0 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 200) {
            // Update the data array directly after disapproval
            const updatedData = tableData.map((item) => {
              if (item.id === user.id) {
                return { ...item, is_approve: 0 };
              }
              return item;
            });
            setTableData(updatedData);
      }
    } catch (error) {
      console.error("Error disapproving user:", error);
      // Handle error if necessary
    }
  };
  
  
  const updateTableData = (userId, isApprove) => {
    setTableData((prevTableData) =>
      prevTableData.map((user) => {
        if (user.id === userId) {
          return { ...user, is_approve: isApprove ? 1 : 0 };
        }
        return user;
      })
    );
  };


  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Web Customers
            </Text>
            {/* Search Input */}
            <Input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="200px"
            />
          </Flex>
          <Box overflowX="auto" maxWidth="100%">
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            style={{ overflowX: "auto" }}
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                      minWidth={column.width || "150px"} // Use column.width instead of column.minWidth
                      whiteSpace="normal"
                      wordBreak="break-word"
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td colSpan={columnsData.length + 1} textAlign="center">
                    No data available.
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, cellIndex) => {
                        let data;

                        if (columns[cellIndex].Header === "ACTIONS") {
                          data = row.original.is_approve === true || row.original.is_approve === 1 ? (
                            <Button colorScheme="green" size="sm" disabled>
                              Approved
                            </Button>
                          ) : row.original.is_approve === false || row.original.is_approve === 0 ? (
                            <Button colorScheme="red" size="sm" disabled>
                              Disapproved
                            </Button>
                          ) : (
                            <Flex>
                              <Button
                                colorScheme="green"
                                size="sm"
                                onClick={() => handleApprove(row.original)}
                                mr={2}
                              >
                                Approve
                              </Button>
                              <Button
                                colorScheme="red"
                                size="sm"
                                onClick={() => handleDisapprove(row.original)}
                              >
                                Disapprove
                              </Button>
                            </Flex>
                          );
                        } else {
                          data = (
                            <Text color={textColor} fontSize="sm" fontWeight="700">
                              {cell.value}
                            </Text>
                          );
                        }
                        

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={cellIndex}
                            fontSize={{ sm: "14px" }}
                            maxH="30px !important"
                            py="8px"
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>


          </Table>
          </Box>
          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
            <Flex>
              <Button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1} // Disable the button when on the first page
                variant="link"
                color={textColor}
              >
                {"<<"}
              </Button>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1} // Disable the button when there are no previous pages or on the first page
                variant="link"
                color={textColor}
              >
                {"<"}
              </Button>

              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages} // Disable the button when there are no next pages or on the last page
                variant="link"
                color={textColor}
              >
                {">"}
              </Button>
              <Button
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages} // Disable the button when on the last page
                variant="link"
                color={textColor}
              >
                {">>"}
              </Button>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                Page{" "}
                <Text as="span" color={textColor}>
                  {currentPage} of {totalPages}
                </Text>{" "}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
