import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    SimpleGrid,
    Heading,
    VStack,
    Table,
    Th,
    Td,
    Tr,
    Thead,
    Tbody,
    Flex,
    Spinner
} from "@chakra-ui/react";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import { constants } from "constants/constants";
import "./table.css";

function ViewOrder() {
    const [orderData, setOrderData] = useState({});
    const [loading, setLoading] = useState(true);
    const toast = useToast();

    const getQueryParams = (url) => {
        const queryString = url.split("?")[1];
        if (!queryString) {
            return {};
        }

        const paramsArray = queryString.split("&");
        const params = {};

        paramsArray.forEach((param) => {
            const [key, value] = param.split("=");
            params[key] = decodeURIComponent(value);
        });

        return params;
    };

    const windowUrl = window.location.href;
    const queryParams = getQueryParams(windowUrl);
    const JiwaInvoiceNo = queryParams["JiwaInvoiceNo"];

    useEffect(() => {
        // Fetch order data using axios or your preferred method
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${constants.apiUrl}/view-order`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
                withCredentials: true,
                params: {
                  JiwaInvoiceNo,
                },
              }
            );
            setOrderData(response.data);
          } catch (error) {
            console.error("Error fetching order data:", error.message);
            toast({
              title: "Error",
              description: "Failed to fetch order data",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } finally {
            setLoading(false); // Set loading to false regardless of success or failure
          }
        };
    
        fetchData();
      }, []); // Empty dependency array to fetch data only on mount


    return (
        <Box mt={20} p={4} borderWidth="1px" borderRadius="lg" shadow="md" backgroundColor={"white"}>
            { loading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <>
      
            <Heading mb={4}>
                JIWA Order No - {orderData.InvoiceNo} | Order Status - {orderData.OrderStatus}
            </Heading>

            
            <SimpleGrid columns={2} spacing={4}>
                {/* Delivery Details */}
                <Box>
                    <VStack align="start">
                        <Text fontWeight="bold">Delivery Details</Text>
                        <Text>{`Addressee: ${orderData.DeliveryAddressee}`}</Text>
                        <Text>{`Suburb: ${orderData.DelSuburb}`}</Text>
                        <Text>{`State: ${orderData.DelState}`}</Text>
                        <Text>{`Post Code: ${orderData.DelPostCode}`}</Text>
                    </VStack>
                </Box>
                {/* Billing Details */}
                <Box>
                    <VStack align="start">
                        <Text fontWeight="bold">Billing Details</Text>
                        <Text>{`Addressee: ${orderData.DeliveryAddressee}`}</Text>
                        <Text>{`State: ${orderData.BillState}`}</Text>
                        <Text>{`Post Code: ${orderData.BillPostCode}`}</Text>
                    </VStack>
                </Box>
            </SimpleGrid>

            {/* Additional Information */}
            <Box mt={8} p={0}>
      <Flex>
        {/* First Table */}
        <Table variant="unstyled" flex={1} mr={2} className="no-padding">
          <Tbody>
            <Tr>
              <Td>
                <Text fontWeight="bold">Account No:</Text>
              </Td>
              <Td>{orderData.AccountNo}</Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontWeight="bold">Customer Name:</Text>
              </Td>
              <Td>{orderData.CustomerName}</Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontWeight="bold">Credit Limit:</Text>
              </Td>
              <Td>{orderData.CreditLimit}</Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontWeight="bold">Current Balance:</Text>
              </Td>
              <Td>{orderData.CurrentBalance}</Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontWeight="bold">Account On Hold:</Text>
              </Td>
              <Td>{orderData.AccountOnHold ? "Yes" : "No"}</Td>
            </Tr>
          </Tbody>
        </Table>

        {/* Second Table */}
        <Table variant="unstyled" flex={1} ml={2} className="no-padding">
          <Tbody>
            {/* Additional Fields */}
            <Tr>
              <Td>
                <Text fontWeight="bold">Customer PO No:</Text>
              </Td>
              <Td>{orderData.CustomerPO}</Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontWeight="bold">Date Ordered:</Text>
              </Td>
              <Td>{orderData.OrderDate}</Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontWeight="bold">Trading Status:</Text>
              </Td>
              <Td>{orderData.TradingStatus}</Td>
            </Tr>
            <Tr>
              <Td>
                <Text fontWeight="bold">Term Days:</Text>
              </Td>
              <Td>{orderData.TermsDays}</Td>
            </Tr>
            
            {/* Additional Fields */}
            {orderData.ShipVia && (
              <Tr>
                <Td>
                  <Text fontWeight="bold">Ship Via:</Text>
                </Td>
                <Td>{orderData.ShipVia}</Td>
              </Tr>
            )}
            <Tr>
              <Td>
                <Text fontWeight="bold">Date Expected:</Text>
              </Td>
              <Td>{orderData.OrderExpectedDate}</Td>
            </Tr>
          </Tbody>
        </Table>
      </Flex>
    </Box>

            {/* Items Table */}
            <Box mt={8}>
                <Table variant="simple" borderWidth="1px" borderRadius="md">
                    <Thead>
                        <Tr>
                            <Th fontWeight="bold">Description</Th>
                            <Th fontWeight="bold">Part No</Th>
                            <Th fontWeight="bold">Quantity Ordered</Th>
                            <Th fontWeight="bold">Sell Price</Th>
                            <Th fontWeight="bold">Discount Percent</Th>
                            <Th fontWeight="bold">Discount Given</Th>
                            <Th fontWeight="bold">Unit Price</Th>
                            <Th fontWeight="bold">Total Tax</Th>
                            <Th fontWeight="bold">Current Line Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {orderData.Items &&
                            orderData.Items.map((item, index) => (
                                <Tr key={index}  border="1px" borderColor="gray.300">
                                    <Td fontWeight="medium">{item.Description}</Td>
                                    <Td>{item.Partno}</Td>
                                    <Td>{item.QuantityOrdered}</Td>
                                    <Td>{item.SellPrice}</Td>
                                    <Td>{item.DiscPercent}</Td>
                                    <Td>{item.DiscGiven}</Td>
                                    <Td>{item.UnitPrice}</Td>
                                    <Td>{item.TotalTax}</Td>
                                    <Td>{item.CurrentLineTotal}</Td>
                                </Tr>
                            ))}
                    </Tbody>
                </Table>
            </Box>

            {/* Order Summary Table */}
            <Box mt={8}>
                <Table variant="simple" w={"50%"} ml={"auto"} borderWidth="1px" borderRadius="md">
                    <Thead>
                        <Tr>
                            <Th fontWeight="bold"># Items</Th>
                            <Th fontWeight="bold">Order Total Ex. GST</Th>
                            <Th fontWeight="bold">GST</Th>
                            <Th fontWeight="bold">Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>{orderData.TotalItems}</Td>
                            <Td>{`$${orderData.TotalExGST}`}</Td>
                            <Td>{`$${orderData.TotalGST}`}</Td>
                            <Td>{`$${orderData.InvoiceTotal}`}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Box>
            </>
      )}
        </Box>
    );
}

export default ViewOrder;
