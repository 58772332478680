import React, { useState, useMemo, useEffect } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import {
  Flex,
  Select,
  Text,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Button,
  Switch,
  Box,
  useToast,
  IconButton
} from "@chakra-ui/react";
import { FiArrowDown, FiArrowUp, FiTrash2 } from "react-icons/fi";
import Card from "components/card/Card";
import axios from "axios";
import { constants } from "constants/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from "react-select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ItemTable({batchStatus}) {
  const [tableData, setTableData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [customerCriteria, setCustomerCriteria] = useState([]);
  const [specialsName, setSpecialsName] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [minQuantity, setMinQuantity] = useState("");
  const [debtorClassifications, setDebtorClassifications] = useState([]);
  const toast = useToast();
  const textColor = "gray.900";
  const borderColor = "gray.200";
  const history = useHistory();
  const columnsData = [
    {
      Header: "NAME",
      accessor: "name",
    },
    {
      Header: "PART NO",
      accessor: "partno",
    },
    {
      Header: "LANDING COST",
      accessor: "landing_cost",
      textAlign: "right"
    },
    {
      Header: "REGULAR PRICE",
      accessor: "regular_price",
      textAlign: "right"
    },
    {
      Header: "SPECIAL PRICE ExGST",
      accessor: "sale_price", // Assuming the accessor contains unique identifiers
      textAlign: "right"
    },
    {
      Header: "PERCENT REDUCTION",
      accessor: "percent_reduction", // Assuming the accessor contains unique identifiers
      textAlign: "right"
    },
    {
      Header: "STOCK QUANTITY",
      accessor: "stockQuantity", // Assuming the accessor contains unique identifiers
      textAlign: "right"
    },
    {
      Header: "REMOVE",
      textAlign: "center"
      // accessor: "offer",
    },
  ];

  const getQueryParams = (url) => {
    const queryString = url.split("?")[1];
    if (!queryString) {
      return {};
    }

    const paramsArray = queryString.split("&");
    const params = {};

    paramsArray.forEach((param) => {
      const [key, value] = param.split("=");
      params[key] = decodeURIComponent(value);
    });

    return params;
  };

  const windowUrl = window.location.href;
  const queryParams = getQueryParams(windowUrl);
  const id = queryParams["id"];

  const fetchDataForPage = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/update-specials-batch`,
        {
          headers,
          withCredentials: true,
          params: {
            id: id,
          },
        }
      );

      return response.data.records;
    } catch (error) {
      console.error("Error fetching reports data:", error);
      // Handle error if necessary
      return [];
    }
  };

  const fetchDebtorForPage = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/debtor-classifications`,
        {
          headers,
          withCredentials: true,
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching debtor data:", error);
      // Handle error if necessary
      return [];
    }
  };

  const insertionToDB = async (product) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${constants.apiUrl}/update-save-specials-batch`,
        product,
        {
          headers,
          withCredentials: true,
        }
      );

    
    } catch (error) {

      toast({
        position: "bottom-right",
        title: "There was an error creating specials",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      
      console.error("Error fetching reports data:", error);
      // Handle error if necessary
      return [];
    }
  };

  const handleRemoveProduct = async (productId) => {
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/remove-specials-product`,
        {
          headers,
          withCredentials: true,
          params: {
            id: productId,
          },
        }
      );

      if (response.status === 200) {
        console.log("Removed Product ID:", productId);

        toast({
          position: "bottom-right",
          title: "Product Removed Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchDataForPage();
      } else {
        toast({
          position: "bottom-right",
          title: "There was an error deleting product",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

    } catch (error) {
      console.error("Error removing product:", error);
      toast({
        position: "bottom-right",
        title: "There was an error deleting Product",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      // Handle error if necessary
    } finally{
      window.location.reload();
    }
  };
  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => tableData, [tableData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    // Access global filter state and functions
    setGlobalFilter,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageSize: 100,
      },
      autoResetPage: false,
      autoResetGlobalFilter: false,
      // autoResetSelectedRows: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDataForPage(id);
      setTableData(data);
      // const debtorData = await fetchDebtorForPage();
      // setDebtorClassifications(debtorData);
    };

    fetchData();
  }, []);

  const handleSwitchToggle = async (productId) => {
    setTableData((prevData) =>
      prevData.map((product) =>
        product.id === productId
          ? { ...product, offer: !product.offer }
          : product
      )
    );

    // Find the product with the updated selected state
    let updatedProduct = tableData.find(
      (product) => product.product_id === productId
    );
    updatedProduct.offer = !updatedProduct.offer;

    // Call insertionToDB with the updated product data
    await insertionToDB(updatedProduct);

    // Fetch updated data after insertion
    const updatedData = await fetchDataForPage();
    setTableData(updatedData);
  };

  const handleSpecialPriceEdit = (productId, newSpecialPrice) => {
    setTableData((prevData) =>
      prevData.map((product) =>
        product.product_id === productId
          ? {
              ...product,
              sale_price: newSpecialPrice,
              percent_reduction: (
                ((product.regular_price - newSpecialPrice) * 100) /
                product.regular_price
              ).toFixed(2),
            }
          : product
      )
    );

    // Find the product with the updated special price
    let updatedProduct = tableData.find(
      (product) => product.product_id === productId
    );
    updatedProduct.sale_price = newSpecialPrice;
    updatedProduct.percent_reduction = (
      ((updatedProduct.regular_price - newSpecialPrice) * 100) /
      updatedProduct.regular_price
    ).toFixed(2);
    // Call insertionToDB with the updated product data
    insertionToDB(updatedProduct);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    // Update the global filter with the new search query
    setGlobalFilter(value);
  };

 

  const handleSpecialsCommit = async (id) => {
    // Call your API with the necessary data (specialsName, dateRange, minQuantity)
    try {
        // Check if specialsName or dateRange is missing
        if (tableData.length <= 0) {
          toast({
              position: "bottom-right",
              title: "Please select atleast one Product",
              status: "error",
              duration: 3000,
              isClosable: true,
          });
          return;
      }

  // Check if any sale_price in tableData is 0 or 0.00
  if (tableData.some(item => item.sale_price === '0' || item.sale_price === '0.00')) {
    toast({
        position: "bottom-right",
        title: "Special price cannot be 0",
        status: "error",
        duration: 3000,
        isClosable: true,
    });
    return;
}
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${constants.apiUrl}/update-davka-specials`,
        {
  id: id
        },
        {
          headers,
          withCredentials: true,
        }
      );

      if(response.status === 200){
        toast({
          position: "bottom-right",
          title: "Specials Updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        history.push('/specials-history');
      }else{
        toast({
          position: "bottom-right",
          title: "There was an error updating specials",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      console.log("Commit Specials Response:", response.data);
    } catch (error) {
      console.error("Error committing specials:", error);
      toast({
        position: "bottom-right",
        title: "There was an error updating specials",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      // Handle error if necessary
    }
  };



  const handleGoBack = () => {
    // Use history to navigate back
    // You can use react-router-dom's useHistory hook here
     history.goBack();
  };

  const pageCount =
    page.length === 0 ? 0 : Math.ceil(tableData.length / pageSize);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      borderRadius="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
      
        {/* <Flex px="25px" justify="space-between" mb="10px" align="center">
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            maxWidth="80px"
            variant="outline"
            color={textColor}
          >
            {[5, 10, 25, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>

          <Input
            type="text"
            placeholder="Search"
            onChange={handleSearchChange}
            value={searchQuery}
            maxWidth="200px"
          />
        </Flex> */}

        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                    style={{ textAlign: column.textAlign }}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon as={FiArrowDown} />
                          ) : (
                            <Icon as={FiArrowUp} />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell, index) => {
                    let cellContent = cell.value;
                    if (columns[index].Header === "SELECTED") {
                      cellContent = (
                        <Switch
                          colorScheme="teal"
                          size="md"
                          isChecked={cell.row.original.offer === "Yes"}
                          onChange={() =>
                            handleSwitchToggle(cell.row.original.product_id)
                          }
                        />
                      );
                    } else if (columns[index].Header === "SPECIAL PRICE ExGST") {
                      cellContent = (
                        <Input
                          type="number"
                          borderColor="1px solid gray"
                          value={cell.value}
                          onChange={(e) =>
                            handleSpecialPriceEdit(
                              cell.row.original.product_id,
                              e.target.value
                            )
                          }
                        />
                      );
                    }else if (columns[index].Header === "PERCENT REDUCTION") {
                      // Check if the cell value is "0.00"
                      const textColor = cell.value === "0.00" ? "red" : "inherit";
                    
                      cellContent = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }else if (columns[index].Header === "REMOVE") {
                      cellContent = (
                        <IconButton
                        icon={<FiTrash2 />}
                        colorScheme="green"
                        size="sm"
                        onClick={() => handleRemoveProduct(row.original.id)}
                      />
                      );
                    } else {
                      cellContent = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }

                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        maxH="30px !important"
                        py="8px"
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                        textAlign={cell.column.textAlign} // Set textAlign based on tableFormat
                      >
                        {cellContent}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Button alignSelf={"flex-end"} w={"30%"} onClick={() => handleSpecialsCommit(id)} colorScheme="teal" ml="2" isDisabled={batchStatus !== "Completed"}>
              Commit Specials
            </Button>
      </Flex>
    </Card>
  );
}
