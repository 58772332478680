import React, { useEffect, useState, useMemo } from "react";
import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner,
  Select,
  Input,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast
} from "@chakra-ui/react";
import {
  useSortBy,
  usePagination,
  useGlobalFilter,
  useTable,
} from "react-table";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import axios from "axios";
import { constants } from "constants/constants";
// Custom components
import Card from "components/card/Card";
import { Link } from "react-router-dom";
export default function ComplexTable() {
  const columnsData = [
    {
      Header: "Order Date",
      accessor: "OrderDate",
    },
    {
      Header: "Source",
      accessor: "Source",
    },
    {
      Header: "Order ID",
      accessor: "OrderID",
        // textAlign: "right"
    },
    {
      Header: "Invoice No.",
      accessor: "InvoiceNo",
        // textAlign: "right"
    },
    {
      Header: "Status",
      accessor: "Status",
    },
    {
      Header: "Jiwa Total",
      accessor: "JiwaTotal",
      // textAlign: "right"
    },
    {
      Header: "Web Total",
      accessor: "WebTotal",
        // textAlign: "right"
    },
    {
      Header: "Consignment Note",
      accessor: "ConsignmentNote",
    },
    {
      Header: "WareHouse Status",
      accessor: "WareHouseStatus",
    },
 
  ];


  const [isDataLoading, setIsDataLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState("Australia Post");
  const [consignmentNoteInput, setConsignmentNoteInput] = useState("");
  const [selectedInvoiceNo, setSelectedInvoiceNo] = useState(null);
  const toast = useToast();


  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    gotoPage(newPage - 1); // Manually go to the new page (subtract 1 because react-table uses 0-based indexing)
  };
  
  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to the first page when page size changes
    gotoPage(0); // Manually go to the first page (0-indexed)
  };
  
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    // Update the global filter with the new search query
    setGlobalFilter(value);
  };
  

  const handleConsignmentNoteClick = (invoiceNo) => {
    setSelectedInvoiceNo(invoiceNo); // Store the selected InvoiceNo
    setSelectedCarrier("Australia Post"); // Set default carrier
    setConsignmentNoteInput(""); // Clear consignment note input
      handleOpenModal();
  };
  
  const handleModalSubmit = async () => {
    try {
      const trimmedNote = consignmentNoteInput.trim();
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
  
      // Make API call to save consignment note
      await axios.post(
        `${constants.apiUrl}/save-consignment-note`,
        {
          invoiceNo: selectedInvoiceNo,
          carrier: selectedCarrier,
          consignmentNote: trimmedNote,
        },
        {
          headers,
          withCredentials: true,
        }
      );
  
      // Show success toast
      toast({
        title: "Consignment Note Saved",
        description: "Consignment note has been saved successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
  
      // Close modal and refresh table data
      handleCloseModal();
      fetchDataForPage(currentPage, pageSize);
    } catch (error) {
      console.error("Error saving consignment note:", error);
  
      // Show error toast
      toast({
        title: "Error",
        description: "Failed to save consignment note. Please try again later. ",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      });
  
      // Handle error if necessary
    }
  };
  
  


 
  const textColor = "gray.900";
  const borderColor = "gray.200";
  const columns = useMemo(() => columnsData, []); // Memoize columnsData, it won't change during the component's lifetime
  const data = useMemo(() => tableData, [tableData]); // Memoize tableData, it will update when tableData changes
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
    // Access global filter state and functions
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 25,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const fetchDataForPage = async (page, pageSize, searchQuery) => {
    try {
      setIsDataLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(
        `${constants.apiUrl}/open-web-orders`,
        {
          headers,
          withCredentials: true,
        }
      );

      const data = response.data.records;
      setTableData(data);
     
      // setIsDataLoading(false);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      // Handle error if necessary
    }finally{
      setIsDataLoading(false);
    }
  };

   // Fetch data for the initial page
  
  useEffect(() => {
   
      // Reset the search when searchQuery is empty
      fetchDataForPage(currentPage, pageSize);
    
  }, [currentPage, pageSize]);

  const pageCount = page.length === 0 ? 0 : Math.ceil(data.length / pageSize);

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {isDataLoading ? (
        <Flex height="100vh" alignItems="center" justifyContent="center">
          <Spinner size="xl" thickness="4px" color="green.500" />
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex px="25px" justify="space-between" mb="10px" align="center">
            {/* Page Size Select Dropdown */}
            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              maxWidth="80px"
              variant="outline"
              color={textColor}
            >
              {[5, 10, 25, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>

            <Text
              color={textColor}
              fontSize="22px"
              fontWeight="700"
              lineHeight="100%"
            >
              Open Web Orders
            </Text>
            {/* Search Input */}
            <Input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              value={searchQuery}
              maxWidth="200px"
            />
          </Flex>
          <Box overflowX="auto" maxWidth="100%">
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            style={{ overflowX: "auto" }}
          >
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="10px"
                      key={index}
                      borderColor={borderColor}
                      minWidth={column.width || "150px"} // Use column.width instead of column.minWidth
                      whiteSpace="normal"
                      wordBreak="break-word"
                      style={{ textAlign: column.textAlign }}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <Icon as={FiArrowDown} />
                            ) : (
                              <Icon as={FiArrowUp} />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td colSpan={columnsData.length + 1} textAlign="center">
                    No data available.
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, cellIndex) => {
                        let data;

                        if (columns[cellIndex].Header === "Consignment Note" && !cell.value) {
                          data = (
                            <Button variant="brand" fontSize={"sm"} w={"90%"} onClick={() => handleConsignmentNoteClick(row.original.InvoiceNo)}>
                              Add Connnote No
                            </Button>
                          );
                        } else if (columns[cellIndex].Header === "Invoice No.") {
                          data = (
                            <Link
                            to={`/view-order?JiwaInvoiceNo=${cell.value}`}
                           
                          >
                              {cell.value}
                            </Link>
                          );
                        }else{
                          data = (
                            <Text color={textColor} fontSize="sm" fontWeight="700">
                              {cell.value}
                            </Text>
                          );
                      
                        }
                       
                        

                        return (
                          <Td
                            {...cell.getCellProps()}
                            key={cellIndex}
                            fontSize={{ sm: "14px" }}
                            maxH="30px !important"
                            py="8px"
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                            textAlign={cell.column.textAlign} 
                          >
                            {data}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              )}
            </Tbody>


          </Table>
          {/* Modal for adding consignment note */}
  <Modal isOpen={showModal} onClose={handleCloseModal}>
    <ModalOverlay />
    <ModalContent>
    <ModalHeader>Add Consignment Note for Invoice No: {selectedInvoiceNo}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Select
          value={selectedCarrier}
          onChange={(e) => setSelectedCarrier(e.target.value)}
          // maxWidth="200px"
          mb="4"
        >
          <option value="Australia Post">Australia Post</option>
          <option value="Direct FRT">Direct FRT</option>
        </Select>
        <Input
          type="text"
          placeholder="Enter Consignment Note"
          onChange={(e) => setConsignmentNoteInput(e.target.value.replace(/[^\w\s]/gi, ""))}
          value={consignmentNoteInput}
          // maxWidth="200px"
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleModalSubmit} colorScheme="blue">
          Submit
        </Button>
        <Button onClick={handleCloseModal} ml="4">
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
          </Box>
          {/* Pagination */}
          <Flex
            justifyContent="space-between"
            alignItems="center"
            px="25px"
            mb="10px"
          >
           <Flex justify="space-between" align="center" px="25px">
          <Button
            onClick={() => gotoPage(0)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {'<<'}
          </Button>
          <Button
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={pageIndex === 0}
            variant="outline"
          >
            {'<'}
          </Button>
          <Text color={textColor} fontSize="16px">
            Page {pageIndex + 1} of {pageCount}
          </Text>
          <Button
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {'>'}
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={pageIndex >= pageCount - 1}
            variant="outline"
          >
            {'>>'}
          </Button>
        </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
