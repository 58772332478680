import React from "react";
import { Box, Button, Heading } from "@chakra-ui/react"; // Import Chakra UI components

// Define the sales order dashboard component
export default function WarehouseDashboard() {
  return (
    <Box p="4">
      <Heading as="h2" mt="55px" mb="4">
        Welcome to Davka Portal
      </Heading>
      {/* Buttons for different functionalities */}
      <Button variant="brand" colorScheme="teal" size="md" mr="2" mt="2" as="a" href="/#/open-web-orders">
        Open Web Orders
      </Button>
    </Box>
  );
}
