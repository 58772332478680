import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdAssignment,
  MdPeople,
  MdHeadsetMic,
  MdShoppingCart, MdStorage, MdAdminPanelSettings , MdEdit 
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";
import CustomerList from "views/admin/customerList";
import SupportList from "views/admin/supportList";
import AddSupportMember from "views/admin/addSupportMember";
import AddReport from "views/admin/addReport";
import ReportList from "views/admin/reportList";
import ViewReport from "views/admin/viewReport";
import GenerateReport from "views/admin/generateReport";
import OrderQueue from "views/admin/orderQueue";
//Customer Imports
import Claims from "views/customer/claims";

//Support Imports
import CreateTask from "views/support/createTask";
import TaskList from "views/support/taskList";
import ViewJob from "views/support/viewJob";
import JobInvoice from "views/support/jobInvoice";
import ProfitablityReport from "views/support/profitabilityReport";
import ViewJobProfit from "views/support/viewJobProfit";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import Register from "views/auth/register";
import ForgotPassword from "views/auth/forgotPassword";
import ResetPassword from "views/auth/resetPassword";
import CustomerOrder from "views/admin/customerOrders";
import InventoryEnquiry from "views/admin/inventoryEnquiry";
import NewSalesOrder from "views/admin/newSalesOrder";
import ProductUpdate from "views/admin/productUpdate";
import ViewOrder from "views/admin/viewOrder";
import CustomerLogs from "views/admin/customerLogs";
import OrderLogs from "views/admin/orderLogs";
import InventoryLogs from "views/admin/inventoryLogs";
import PUAccessLogs from "views/admin/puaccessLogs";
import EngineLogs from "views/admin/engineLogs";
import SpecialsHistory from "views/admin/specialsHistory";
import ViewSpecials from "views/admin/viewSpecials";
import DavkaBeautySpecials from "views/admin/davkabeautySpecials";
import BeautyManiaSpecials from "views/admin/beautyManiaSpecials";
import BeautyManiaReviewChanges from "views/admin/beautyManiaReviewChanges";
import DavkaBeautyReviewChanges from "views/admin/davkaBeautyReviewChanges";
import GenericDashBoard from "views/generic/dashboard";
import EditMember from "views/admin/editMember";
import DebtorList from "views/admin/debtorList";
import EditProfile from "views/generic/editProfile";
import SupportCustomerOrder from "views/support/customerOrders";
import SupportOrderQueue from "views/support/orderQueue";
import OpenWebOrders from "views/admin/openWebOrders";
import SupportOpenWebOrders from "views/support/openWebOrders";
import WarehouseDashboard from "views/generic/warehouseDashboard";
import AccessManagement from "views/admin/accessManagement";
import ImageStatus from "views/admin/imageStatus";
import SyncImages from "views/admin/syncImages";
import SpecialsBatchUpdate from "views/admin/updateSpecialsBatch";

const routes = [
  {
    name: "Dashboard",
    layout: "/generic",
    path: "/dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: GenericDashBoard,
    accessRole: "GlobalAdmin,SalesAdmin,SalesMarketing,SpecialsAdmin,SalesRep",
  },
  {
    name: "Dashboard",
    layout: "/generic",
    path: "/warehouse-dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: WarehouseDashboard,
    accessRole: "WarehouseSupport",
  },
  {
    name: "Update Profile",
    layout: "/generic",
    path: "/edit-profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: EditProfile,
    hideInMenu: true,
    accessRole: "GlobalAdmin,SalesAdmin,SalesMarketing,SpecialsAdmin,SalesRep",
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hideInMenu: true,
  },
  {
    name: "Register",
    layout: "/auth",
    path: "/register",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Register,
    hideInMenu: true,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
    hideInMenu: true,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
    hideInMenu: true,
  },
  {
    name: "Web Customers",
    layout: "/generic",
    path: "/customer-list",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: CustomerList,
    accessRole: "GlobalAdmin,SalesAdmin,SalesMarketing,SpecialsAdmin,SalesRep",
  },
  {
    name: "Debtors",
    layout: "/generic",
    path: "/debtor-list",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: DebtorList,
    accessRole: "GlobalAdmin,SalesMarketing,SpecialsAdmin",
  },
  {
    name: "Support Team",
    layout: "/admin",
    path: "/support-team-list",
    icon: <Icon as={MdHeadsetMic} width="20px" height="20px" color="inherit" />,
    component: SupportList,
    accessRole: "GlobalAdmin",
  },
  {
    name: "Add Support Member",
    layout: "/admin",
    path: "/add-support-member",
    icon: <Icon as={MdHeadsetMic} width="20px" height="20px" color="inherit" />,
    component: AddSupportMember,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Edit Member",
    layout: "/admin",
    path: "/edit-member",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: EditMember,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Add Report",
    layout: "/admin",
    path: "/add-report",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: AddReport,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Reports List",
    layout: "/admin",
    path: "/report-list",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: ReportList,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "View Report",
    layout: "/admin",
    path: "/view-report",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: ViewReport,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Generate Report",
    layout: "/admin",
    path: "/generate-report",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: GenerateReport,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Sales Order Queue",
    layout: "/admin",
    path: "/sales-order-queue",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: OrderQueue,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Search Sales Orders",
    layout: "/admin",
    path: "/search-sales-order",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: CustomerOrder,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "View Order",
    layout: "/admin",
    path: "/view-order",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    component: ViewOrder,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Stock Status",
    layout: "/admin",
    path: "/stock-status",
    component: InventoryEnquiry,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "New Sales Order",
    layout: "/admin",
    path: "/new-sales-order",
    component: NewSalesOrder,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Open Web Orders",
    layout: "/admin",
    path: "/open-web-orders",
    component: OpenWebOrders,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  
  
  {
    name: "Product Update",
    layout: "/admin",
    path: "/product-update",
    component: ProductUpdate,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Customer Logs",
    layout: "/admin",
    path: "/customer-logs",
    component: CustomerLogs,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Order Logs",
    layout: "/admin",
    path: "/order-logs",
    component: OrderLogs,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Inventory Logs",
    layout: "/admin",
    path: "/inventory-logs",
    component: InventoryLogs,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "PU Access Logs",
    layout: "/admin",
    path: "/puaccess-logs",
    component: PUAccessLogs,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Engine Logs",
    layout: "/admin",
    path: "/engine-logs",
    component: EngineLogs,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Specials History",
    layout: "/admin",
    path: "/specials-history",
    component: SpecialsHistory,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Specials Batch",
    layout: "/admin",
    path: "/view-specials-batch",
    component: ViewSpecials,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "New DavkaBeauty Special",
    layout: "/admin",
    path: "/new-davkabeauty-special",
    component: DavkaBeautySpecials,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "New DebtorSpecific Special",
    layout: "/admin",
    path: "/new-debtorspecific-special",
    component: BeautyManiaSpecials,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Special Review Changes",
    layout: "/admin",
    path: "/special-review",
    component: BeautyManiaReviewChanges,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "DavkaBeauty Special Review Changes",
    layout: "/admin",
    path: "/davkaBeauty-special-review",
    component: DavkaBeautyReviewChanges,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Update Image",
    layout: "/admin",
    icon: <Icon as={MdEdit} width="20px" height="20px" color="inherit" />,
    path: "/update-image",
    // hideInMenu: true,
    component: SyncImages,
    accessRole: "GlobalAdmin",
  },
  {
    name: "Update Specials",
    layout: "/admin",
    icon: <Icon as={MdEdit} width="20px" height="20px" color="inherit" />,
    path: "/update-specials",
    // hideInMenu: true,
    component: SpecialsBatchUpdate,
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
  {
    name: "Reports",
    layout: "/admin",
    path: "/reports",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Add Report",
        layout: "/admin",
        path: "/add-report",
        component: AddReport,
        accessRole: "GlobalAdmin",
      },
      {
        name: "Reports List",
        layout: "/admin",
        path: "/report-list",

        component: ReportList,
        accessRole: "GlobalAdmin",
      },
      {
        name: "Generate Report",
        layout: "/admin",
        path: "/generate-report",

        component: GenerateReport,
        accessRole: "GlobalAdmin",
      },
      {
        name: "View Report",
        layout: "/admin",
        path: "/view-report",
        component: ViewReport,
        accessRole: "GlobalAdmin",
        hideInMenu: true,
      },
    ],
    accessRole: "GlobalAdmin",
  },
  {
    name: "Sales Order",
    layout: "/admin",
    path: "/order-management",
    icon: <Icon as={MdShoppingCart} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Sales Order Queue",
        layout: "/admin",
        path: "/sales-order-queue",
        component: OrderQueue,
        accessRole: "GlobalAdmin",
      },
      {
        name: "Search Sales Orders",
        layout: "/admin",
        path: "/search-sales-order",
        component: CustomerOrder,
        accessRole: "GlobalAdmin",
      },
      {
        name: "New Sales Order",
        layout: "/admin",
        path: "/new-sales-order",
        component: NewSalesOrder,
        accessRole: "GlobalAdmin",
      },
      {
        name: "Open Web Orders",
        layout: "/admin",
        path: "/open-web-orders",
        component: OpenWebOrders,
        accessRole: "GlobalAdmin",
      },
      {
        name: "View Order",
        layout: "/admin",
        path: "/view-order",
        component: ViewOrder,
        accessRole: "GlobalAdmin",
        hideInMenu: true,
      },
    ],
    accessRole: "GlobalAdmin",
  },
  {
    name: "Inventory",
    layout: "/admin",
    path: "/inventory-management",
    icon: <Icon as={MdStorage} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Stock Status",
        layout: "/admin",
        path: "/stock-status",
      
        component: InventoryEnquiry,
        accessRole: "GlobalAdmin",
     
      },
      {
        name: "Product Update",
        layout: "/admin",
        path: "/product-update",
        component: ProductUpdate,
        accessRole: "GlobalAdmin",
      },
    ],
    accessRole: "GlobalAdmin",
  },
  {
    name: "Admin Logs",
    layout: "/admin",
    path: "/admin-logs",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Customer Logs",
        layout: "/admin",
        path: "/customer-logs",
        component: CustomerLogs,
        accessRole: "GlobalAdmin",
      },
      {
        name: "Order Logs",
        layout: "/admin",
        path: "/order-logs",
        component: OrderLogs,
        accessRole: "GlobalAdmin",
      },
      {
        name: "Inventory Logs",
        layout: "/admin",
        path: "/inventory-logs",
        component: InventoryLogs,
        accessRole: "GlobalAdmin",
      },
      {
        name: "PU Access Logs",
        layout: "/admin",
        path: "/puaccess-logs",
        component: PUAccessLogs,
        accessRole: "GlobalAdmin",
      },
      {
        name: "Engine Logs",
        layout: "/admin",
        path: "/engine-logs",
        component: EngineLogs,
        accessRole: "GlobalAdmin",
      },
    ],
    accessRole: "GlobalAdmin",
  },
  {
    name: "Specials",
    layout: "/admin",
    path: "/offers",
    icon: <Icon as={MdAssignment} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "New DavkaBeauty Special",
        layout: "/admin",
        path: "/new-davkabeauty-special",
        component: DavkaBeautySpecials,
        accessRole: "GlobalAdmin",
      },
      {
        name: "New DebtorSpecific Special",
        layout: "/admin",
        path: "/new-debtorspecific-special",
        component: BeautyManiaSpecials,
        accessRole: "GlobalAdmin",
      },
      {
        name: "Specials History",
        layout: "/admin",
        path: "/specials-history",
        component: SpecialsHistory,
        accessRole: "GlobalAdmin",
      },
      {
        name: "Special Review Changes",
        layout: "/admin",
        path: "/special-review",
        component: BeautyManiaReviewChanges,
        accessRole: "GlobalAdmin",
        hideInMenu: true,
      },
      {
        name: "DavkaBeauty Special Review Changes",
        layout: "/admin",
        path: "/davkaBeauty-special-review",
        component: DavkaBeautyReviewChanges,
        accessRole: "GlobalAdmin",
        hideInMenu: true,
      },
      {
        name: "View Specials Batch",
        layout: "/support",
        path: "/view-specials-batch",
        component: ViewSpecials,
        accessRole: "SalesMarketing,SpecialsAdmin,Accounts,Owner,GlobalAdmin",
        hideInMenu: true,
      },
      {
        name: "Update Specials",
        layout: "/admin",
        path: "/update-specials",
        component: SpecialsBatchUpdate,
        accessRole: "GlobalAdmin",
        hideInMenu: true,
      },
    ],
    accessRole: "GlobalAdmin",
  },
  {
    name: "Others",
    layout: "/admin",
    path: "/others",
    icon: <Icon as={MdStorage} width="20px" height="20px" color="inherit" />,
    category: true,
    items: [
      {
        name: "Dashboard",
        layout: "/generic",
        path: "/dashboard",
        component: GenericDashBoard,
        accessRole: "GlobalAdmin,SalesAdmin,SalesMarketing,SpecialsAdmin,SalesRep",
      },
      {
        name: "Warehouse Dashboard",
        layout: "/generic",
        path: "/warehouse-dashboard",
        component: WarehouseDashboard,
        accessRole: "WarehouseSupport",
      },
      {
        name: "Update Profile",
        layout: "/generic",
        path: "/edit-profile",
        component: EditProfile,
        hideInMenu: true,
        accessRole: "GlobalAdmin,SalesAdmin,SalesMarketing,SpecialsAdmin,SalesRep",
      },
      {
        name: "Update Image",
        layout: "/admin",
        path: "/update-image",
        hideInMenu: true,
        component: SyncImages,
        accessRole: "GlobalAdmin",
      },
      {
        name: "Web Customers",
        layout: "/generic",
        path: "/customer-list",
       
        component: CustomerList,
        accessRole: "GlobalAdmin,SalesAdmin,SalesMarketing,SpecialsAdmin,SalesRep",
      },
      {
        name: "Debtors",
        layout: "/generic",
        path: "/debtor-list",
        component: DebtorList,
        accessRole: "GlobalAdmin,SalesMarketing,SpecialsAdmin",
      },
     
    ],
    accessRole: "GlobalAdmin",
    hideInMenu: true,
  },
 
  {
    name: "Access Management",
    layout: "/admin",
    icon: <Icon as={MdAdminPanelSettings} width="20px" height="20px" color="inherit" />,
    path: "/access-management",
    component: AccessManagement,
    accessRole: "GlobalAdmin",
    // hideInMenu: true,
  }
];

export default routes;
