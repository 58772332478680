import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ChakraProvider, Flex, Spinner } from '@chakra-ui/react';
import theme from 'theme/theme';
import axios from 'axios';
import PrivateRoute from 'routes/privateRoute';
import { constants } from 'constants/constants';
import AuthLayout from "layouts/auth";
import routes from './routes'; // Assuming you have a separate routes.js file
import NotFoundPage from 'views/auth/notFound';
const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userPermissions, setUserPermissions] = useState([]);
  
  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    axios
      .post(`${constants.apiUrl}/validate-token`, { token }, { withCredentials: true })
      .then((response) => {
        if (response.status === 200) {
          const permissions = JSON.parse(response.data.permissions); // Assuming permissions are returned as an array of strings
          setUserPermissions(permissions.permissions);
          setIsAuthenticated(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('accessToken');
          setIsAuthenticated(false);
        }
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" thickness="4px" color="green.500" />
      </Flex>
    );
  }
console.log('index',isAuthenticated);
  return (
    <ChakraProvider theme={theme}>
      <HashRouter>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <Route
            exact
            path="/"
            render={() => (
              isAuthenticated ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/auth/sign-in" />
              )
            )}
          />
          {routes.map((route, index) => (
           
            <PrivateRoute
              key={index}
              path={route.path}
              component={route.component}
              permissions={userPermissions}
              isAuthenticated={isAuthenticated}
            />
          ))}
          {/* <Redirect from="/" to="/dashboard" /> */}
          <Route component={NotFoundPage} /> {/* 404 page should be last */}
        </Switch>
      </HashRouter>
    </ChakraProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
